import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer'

import User from '../../../../services/User'
import Chat from '../../../../services/Chat'
import Spinner from '../../../../components/Spinner'
import ChatConversation from './ChatConversation'
import { toggleMode, visualModeEnum } from './ChatCommon'

const ChatService = new Chat();
const UserService = new User();

export default class ChatList extends Component {

    constructor(props) {
        super(props)
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.checkInTimer = null

        console.log("Set handler")
        window.WSService.sub("chatNotification", (id) => {
            console.log("Handle", id)
            id = id.split(':')[1]
            this.handleNotification(id)
        })
    }

    handleNotification = (id) => {
        console.log(id)
        var found = false
        for (var item of this.state.activeChats) {
            if (item.id === id) {
                found = true
            }
        }
        if (found === false) {
            UserService.get(id).then((value) => {

                const item = {
                    id: id,
                    name: value.name
                }

                this.newConversation(item)
            })
        } else {
            const chat = this.state.chatMaps[id]
            chat.loadMessages()
        }
    }

    state = {
        people: [],
        loading: true,
        visualMode: visualModeEnum.COLLAPSED,
        activeChats: [],
        searchString: '',
        chatMaps: {}
    }

    onWindowClick = () => {
        this.setState({ visualMode: toggleMode(this.state.visualMode) })
    }

    loadPeople = () => {
        var searchString = ''
        if (this.state.searchString.length > 3) {
            searchString = '/' + this.state.searchString
        }
        ChatService.listPeople(searchString).then((data) => {
            this.setState({ people: data['people'], loading: false })
        })
    }


    onChangeSearchString = (event) => {
        var searchString = event.target.value

        if (searchString && searchString.length > 3) {
            this.setState({ searchString: searchString }, () => {
                this.loadPeople()
                this.lastSearchLength = searchString.length
            })
        } else {
            if (this.lastSearchLength === 4 && searchString.length === 3) {
                this.lastSearchLength = 0;
                this.setState({ searchString: searchString }, () => {
                    this.loadPeople()
                })
            } else {
                this.setState({ searchString: searchString })
            }

        }
    }

    resetCheckInTimer() {
        if (this.checkInTimer !== null) {
            clearTimeout(this.checkInTimer)
        }
        this.checkInTimer = setTimeout(() => {
            ChatService.checkIn()
        }, 2 * 60 * 1000)
    }

    _onIdle() {

    }
    _onAction(e) {
        this.resetCheckInTimer()
    }

    _onActive(e) {

        this.resetCheckInTimer()
    }

    componentDidMount = () => {
        this.lastSearchLength = 0
        this.loadPeople()
    }

    onChildExit = (idx) => {
        var activeChats = this.state.activeChats
        activeChats.splice(idx, 1)
        var i = 0
        for (var conversation of activeChats) {
            conversation.idx = i++
        }
        this.setState({ activeChats: activeChats })
    }

    newConversation = (item) => {
        if (!item) { return }
        for (var conversation of this.state.activeChats) {
            console.log("Omama-", item, conversation.item)
            if (conversation.id === item.id) {
                return
            }
        }
        var activeChats = this.state.activeChats
        item.idx = activeChats.length;
        activeChats.push(item)
        this.setState({ activeChats: activeChats })

        console.log(item)
    }

    render() {
        return (
            <div className="chat-list">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 15} />
                {this.state.activeChats.map((item, index) => {
                    return (<ChatConversation key={index} chatMaps={this.state.chatMaps} value={item} onChildExit={this.onChildExit} />)
                })}
                <div className={`chat-list-window ${this.state.visualMode}`}>
                    <div onClick={this.onWindowClick.bind(this)} className="chat-title-box">Obrolan</div>
                    <div className="chat-search-box">
                        <input onChange={this.onChangeSearchString.bind(this)} type="text" value={this.state.searchString} placeholder="Cari..."></input>
                    </div>

                    <div className="chat-list-entries">
                        {this.state.loading === true && (<div className="data-loading"><Spinner /></div>)}
                        {this.state.people.map((item, pIndex) => {
                            return (
                                <li key={pIndex} onClick={() => { this.newConversation(item) }}><span className="chat-item">&#x25c9;</span> {item.name}</li>
                            )
                        })}
                        {this.state.searchString === '' && (
                            <span className="chat-item-hint">Jika Anda tidak menemukan nama orang yang Anda ingin ajak bicara, silakan ketikkan namanya kotak pencarian di atas.</span>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
