import React, { Component } from 'react';
import swal from 'sweetalert';
import qs from 'qs';
import * as Fa from 'react-icons/lib/fa';
import ChangePassword from './UserProfileChangePassword';
import ChangePIN from './UserProfileChangePIN';
import ChangeAvatar from './UserProfileChangeAvatar';
import Blob from '../../../services/Blob';
import Misc from '../../../services/Misc';
import Spinner from '../../../components/Spinner';
import Config from '../../../config';
import Auth from '../../../services/Auth';
import User from '../../../services/User';
import ImageCroper from './ImageCroper';

const BlobService = new Blob();
const MiscService = new Misc();
const AuthService = new Auth();
const UserService = new User();

export default class UserProfile extends Component {
  state = {
    currentUser: {},

    mode: 'profile', // enums : profile, changePassword, changePIN, changeAvatar, changeSignatureScan
    currentAvatar: '',
    currentScannedSignature: '',
    loading: true,
    newScannedSignature: '',
    PINChangedCallback: '',
  }

  componentDidMount = () => {
    let anchor = window.location.href.split("#")[1] || '';
    if (anchor.indexOf('forceChangePIN') > -1) {
      const queryStr = window.location.href.split("?")[1] || '';
      const query = qs.parse(queryStr);
      this.setState({ mode: 'forceChangePIN', PINChangedCallback: query.callback })
      setTimeout(() => {
        swal({
          title: '',
          type: 'warning',
          text: 'PIN Anda sudah kadaluarsa (lebih dari 3 bulan). Silakan ganti PIN Anda.'
        });
      }, 500);
    } else {
      this.reload();
    }
  }

  reload = () => {
    MiscService.getCurrentUser()
    .then((currentUser) => {
      this.setState({
        currentUser: currentUser,
        currentAvatar: Config.apiHost + "/api/v1/blob/unrestricted/" + currentUser.picture,
        currentScannedSignature: Config.apiHost + "/api/v1/blob/unrestricted/" + currentUser.scannedSignature,
      });
      this.setState({ loading: false });
    })
    .catch((err) => {
      this.setState({ loading: false });
    });
  }

  back = () => {
    this.setState({ mode: 'profile' });
  }

  changePassword = (cred, cb) => {
    cred.username = JSON.parse(localStorage.getItem('current_user')).username;
    AuthService.changePassword(cred)
      .then(() => {
        if (cb) cb();
        swal({
          title: '',
          type: 'warning',
          text: 'Sandi berhasil diganti.',
        });
        this.back();
      })
      .catch((err) => {
        if (cb) cb();
        if (err === 'weak-password') {
          swal({
            title: '',
            type: 'warning',
            text: 'Sandi baru terlalu lemah. Silakan periksa kembali sandi Anda.',
          });
        } else if (err === 'unmatch-repeat-password') {
          swal({
            title: '',
            type: 'warning',
            text: 'Konfirmasi sandi baru tidak sama. Silakan periksa kembali sandi Anda.',
          });
        }
        return;
      });
  }

  changePIN = (old, pin, repeat, cb) => {
    let cred = {
      pin: old,
      newPin: pin,
      newPinRepeat: repeat
    }
    AuthService.changePin(cred)
      .then(() => {
        if (cb) cb();
        swal({
          title: '',
          text: 'PIN berhasil diganti.'
        });
        let currentUser = JSON.parse(window.localStorage.getItem('current_user'))
        currentUser.PINLastChanged = (new Date()).toISOString();
        window.localStorage.setItem('current_user', JSON.stringify(currentUser));
        if (this.state.PINChangedCallback.length > 0) {
          window.browserHistory.push(this.state.PINChangedCallback);
          this.setState({ PINChangedCallback: '' })
          return;
        }
        this.back();
      })
      .catch((err) => {
        if (cb) cb();
        if (err === 'weak-password') {
          swal({
            title: '',
            type: 'warning',
            text: 'PIN baru terlalu lemah. Silakan periksa kembali sandi Anda.'
          });
        } else if (err === 'unmatch-repeat-password') {
          swal({
            title: '',
            type: 'warning',
            text: 'Konfirmasi PIN baru tidak sama. Silakan periksa kembali sandi Anda.'
          });
        }
        if (this.state.PINChangedCallback && this.state.PINChangedCallback.length > 0) {
          this.setState({ mode: 'forceChangePIN' })
        }
      });
  }

  changeAvatar = (cropped, cb) => {
    MiscService.getCurrentUser()
    this.setState({ currentAvatar: cropped }, () => {
      if (cb) cb();
    });
  }

  setSignature = () => {
    this.setState({ loading: true });
    let currentUser = JSON.parse(JSON.stringify(this.state.currentUser));
    currentUser.scannedSignature = this.state.newScannedSignature;
    // Exclude some required field
    currentUser.excludeValidate = ['PhoneNumber', 'EmailAddress'];
    UserService.update(currentUser.id, currentUser)
      .then(() => {
        swal({
          title: '',
          text: 'Pindaian tanda tangan baru telah berhasil diunggah dan disimpan.'
        });
        this.setState({ saveSignatureButton: false, loading: false });
      })
      .catch((err) => {
        this.setState({ saveSignatureButton: false, loading: false });
        console.log(err);
      });
  }

  resetPINRequest = () => {
    const emailAddress = JSON.parse(localStorage.getItem('current_user')).emailAddress
    if (!emailAddress) {
      swal({
        title: '',
        type: 'warning',
        text: 'Tidak ada alamat email terdaftar pada akun Anda. Silakan menghubungi Administrator.'
      });
      return;
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menyetel ulang PIN?',
    }, (ok) => {
      if (ok) {
        this.setState({ loading: true });
        AuthService.requestPINReset()
          .then((result) => {
            this.setState({ loading: false });
            swal({
              title: '',
              type: 'warning',
              text: 'Silakan periksa kotak masuk email Anda.',
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  }

  handleUpload = (file) => {
    this.setState({ loading: true });
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      BlobService.uploadUnrestricted(file)
        .then((blobUuid) => {
          this.setState({ 
            currentScannedSignature: reader.result, 
            saveSignatureButton: true, loading: false, newScannedSignature: blobUuid });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
    reader.readAsDataURL(file);
  }

  render() {
    return (
      <div>
        {this.state.mode === 'profile' &&
          <div>
            <h4>Profil Pengguna</h4>
            <div className="box box-success">
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2" style={{ textAlign: 'center' }}>
                    <img src={this.state.currentAvatar} alt="" style={{ background: 'grey', width: 100, height: 100, borderRadius: 50 }} onError={(e) => { e.target.src = "/images/default-avatar.png" }} />
                    <div>
                      <br />
                      <a href="#changeAvatar" onClick={() => { this.setState({ mode: 'changeAvatar' }) }}>Ganti foto profil</a>
                      <br />
                      <a href="#changePassword" onClick={() => { this.setState({ mode: 'changePassword' }) }}>Ganti sandi</a>
                      <br />
                      <a href="#changePIN" onClick={() => { this.setState({ mode: 'changePIN' }) }}>Ganti PIN</a>
                      <br />
                      <a href="#resetPINRequest" onClick={this.resetPINRequest}>Lupa PIN?</a>
                    </div>
                  </div>
                  <div className="col-md-10">
                    {!this.state.loading && <div>
                      <h4>
                        {this.state.currentUser.name}
                      </h4>
                      <Fa.FaBriefcase /> {this.state.currentUser.organization.name}<br />
                      <br />
                      <h4 style={{ fontSize: 14 }}>
                        Tanda Tangan
                    </h4>
                      <img
                        src={this.state.currentScannedSignature}
                        alt=""
                        style={{ background: 'white', height: 100 }} 
                        onError={(e) => { e.target.src = '/images/blank.png' }}
                      />
                    </div>}

                    <div style={{ marginTop: 15 }}>
                      {this.state.loading && <Spinner inline={true} />}
                      {!this.state.loading && <div>
                      <ImageCroper handleUpload={this.handleUpload}/>&nbsp;
                      <button className="btn btn-primary" onClick={this.setSignature} disabled={!this.state.saveSignatureButton}>Simpan</button>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {this.state.mode === 'changeAvatar' &&
          <div>
            <h4> <Fa.FaChevronLeft style={{ cursor: 'pointer' }} onClick={this.back} /> Foto Profil</h4>
            <ChangeAvatar back={this.back} apply={this.changeAvatar} currentUser={this.state.currentUser} />
          </div>
        }

        {this.state.mode === 'changePassword' &&
          <div>
            <h4> <Fa.FaChevronLeft style={{ cursor: 'pointer' }} onClick={this.back} /> Ubah Sandi</h4>
            <ChangePassword back={this.back} apply={this.changePassword} />
          </div>
        }

        {(this.state.mode === 'changePIN' || this.state.mode === 'forceChangePIN') &&
          <div>
            <h4> {this.state.mode === 'changePIN' && <Fa.FaChevronLeft style={{ cursor: 'pointer' }} onClick={this.back} />} Ubah PIN</h4>
            <ChangePIN back={this.back} force={this.state.mode === 'forceChangePIN' ? true : false} reset={this.resetPINRequest} apply={this.changePIN} />
          </div>
        }
      </div>
    );
  }
}
