import axios from "axios";
import Config from "../config";
import Error from './Error';
import Misc from './Misc';
import LetterHeader from './LetterHeader';

const ErrorService = new Error();
const MiscService = new Misc();
const LetterHeaderService = new LetterHeader();

class Cache {

  constructor() {
    this.prefix = "cache-";
  }

  getCacheVersions = () => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/cache/versions", 
      {
        headers: MiscService.generateHeaders()
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  cachingData = (cache) => {
    cache.forEach(v => {
      this.loadData(v.cache_type, v.timestamp)
    })
  }

  cachingByType = (cache_type, cache_version) => {
    this.loadData(cache_type, cache_version)
  }

  loadData = (type, version) => {
    switch (type) {
      case "addressbook":
        MiscService.getAddressBook()
          .then((data) => {
              Object.keys(data).forEach(val => {
                if (data.oa && data.oa.length > 0 && val === 'oa') {
                  this.setCacheToStorage('oa', Date.now(), data.oa)
                } 
                delete data.oa

                this.clearCacheStorage([type])
                this.setCacheToStorage(type, version, data)
            })
          })
          .catch((err) => console.log("Cache: "+ err))
        break;
      case "classification":
          this.clearCacheStorage([type])
        MiscService.getClassification()
          .then((data) => this.setCacheToStorage(type, version, data))
          .catch((err) => console.log("Cache: "+ err))
        break;
      case "letter-header":
          this.clearCacheStorage([type])
        LetterHeaderService.getAll({ limit: 999 })
          .then((data) => this.setCacheToStorage(type, version, data.data))
          .catch((err) => console.log("Cache: "+ err))
        break;
      default:
        break;
    }
  }

  setCacheToStorage = (type, version, data) => { 
    const obj = { "cache_type": type,"cache_version": version, "data": data };
    window.localStorage.setItem('cache-'+type, JSON.stringify(obj))
  };

  getCacheDataFromStorage = (key) => {
    return new Promise((reslove, reject) => {
      const cache = JSON.parse(window.localStorage.getItem(this.prefix + key))
      if (key === 'addressbook') {
        const oa = JSON.parse(window.localStorage.getItem(this.prefix + 'oa'));
        cache.data.oa = (oa && oa.data.length > 0)? oa.data : [];
      }
      if (cache !== undefined || cache !== null) reslove(cache.data)
      else reject("No Caches found")
    })
  }

  getCacheVersionFromStorage = (key) => {
    return new Promise((reslove, reject) => {
      const cache = JSON.parse(window.localStorage.getItem(this.prefix + key))

      if (cache !== undefined || cache !== null) reslove(cache.cache_version)
      else reject("No Caches found")
    })
  }

  clearCacheStorage = (key) => {
    if (key && key.length > 0) {
      key.forEach(val => window.localStorage.removeItem(this.prefix + val));
    }
  }
}

export default Cache;
