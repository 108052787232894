import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Config from '../config';
import Error from './Error';
import Cache from './Cache';
import Misc from './Misc';

const ErrorService = new Error();
const CacheService = new Cache();
const MiscService = new Misc();

class Auth {

  login(username, password) {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + '/api/v1/login',
        { username: username, password: password },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            return reject('invalidCredential');
          }
          const accessToken = response.headers.authorization;
          resolve({ accessToken: accessToken, currentUser: response.data });
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  logout() {
    axios.get(Config.apiHost + '/api/v1/logout',{
      headers: MiscService.generateHeaders()
    })
    .then(()=>{
      window.location = "/login";
    })
    .catch(ErrorService.handle)
    .finally(()=>{
      window.store.dispatch(window.actions.loggedOut({}));
      // Clear cache
      CacheService.clearCacheStorage(['classification', 'addressbook', 'letter-header','access_token']);
    })
  }

  changePassword(cred) {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + '/api/v1/change-password',
        { username: cred.username, password: cred.password, newPassword: cred.newPassword, newPasswordRepeat: cred.newPasswordRepeat },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          if (response.status !== 200) {
            return reject('invalidCredential');
          }
          setTimeout(() => {
            resolve();
          }, 2000);
        })
        .catch((err) => {
          ErrorService.handle(err);
          if (err && err.response && err.response.data && err.response.data.message) {
            reject(err.response.data.message);
            return;
          }
          reject(err);
        })
    });
  }

  changePin(cred) {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + '/api/v1/change-pin',
        { pin: cred.pin, newPin: cred.newPin, newPinRepeat: cred.newPinRepeat },
        {
          headers: MiscService.generateHeaders()
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          return reject('invalidCredential');
        }
        setTimeout(() => {
          resolve();
        }, 2000);
      })
      .catch((err) => {
        ErrorService.handle(err);
        if (err && err.response && err.response.data && err.response.data.message) {
          reject(err.response.data.message);
          return;
        }
        reject(err);
      })
    });
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  getRoles() {
    try {
      const parsed = jwtDecode(localStorage.getItem('access_token').split(' ')[1]);
      return { role: parsed.role, orgRole: parsed.functionaryRole }
    } catch (err) {
      return { role: null, orgRole: null }
    }
  }

  isLoggedIn() {
    // Test the token against backend
    if (localStorage.getItem('access_token') && localStorage.getItem('access_token').length > 0) { // &&
      //localStorage.getItem('current_user') && localStorage.getItem('current_user').length > 0) {
      return true;
    }
    return false;
  }

  requestPasswordReset = (emailAddress) => {
    return new Promise((resolve, reject) => {
      let obj = {}
      obj['email-address'] = emailAddress
      axios.post(Config.apiHost + '/api/v1/request-password-reset',
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  resetPassword = (uuid) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + '/api/v1/reset-password/' + uuid,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  newPassword = (id, key, password) => {
    return new Promise((resolve, reject) => {
      let obj = {}
      obj['request-id'] = id;
      obj['request-secret'] = key;
      obj['new-password'] = password;
      axios.post(Config.apiHost + '/api/v1/reset-password',
        obj,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  requestPINReset = (uuid) => {
    return new Promise((resolve, reject) => {
      var url = '/api/v1/request-pin-reset'
      if ( uuid && uuid.length > 0 ) {
        url+= '?uuid='+uuid
      }
      axios.get(Config.apiHost + url,
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  resetPIN = (uuid) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + '/api/v1/reset-pin/' + uuid,
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  newPIN = (id, key, pin) => {
    return new Promise((resolve, reject) => {
      let obj = {}
      obj['request-id'] = id;
      obj['request-secret'] = key;
      obj['new-pin'] = pin;
      axios.post(Config.apiHost + '/api/v1/reset-pin',
        obj,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }
}

export default Auth;
