import React, { Component } from 'react';
import debounce from 'debounce';
import Switch from 'react-switch';
import LetterFormTextArea from './LetterFormTextArea';
import LetterFormTextInput from './LetterFormTextInput';
import ClassificationModal from '../../../components/ClassificationModal';
import AddressBookModal from '../../AddressBookModal';
import AddressBookSortererModal from '../../AddressBookSortererModal';
import DateTimePicker from '../../../components/DateTimePicker';
import Editor from '../../../../../components/Editor';
import config from '../../../../../config';


export default class component extends Component {
  state = {
    currentUser: {},
    viaType: 'EXTERNAL',
  }

  componentDidMount = () => {
    const currentUser = JSON.parse(localStorage.getItem('current_user'));
    this.setState({ currentUser: currentUser });
  }

  externRecipientOnBlur = debounce(() => {
    setTimeout(() => {
      let e = {
        target: {
          name: 'recipients',
          raw: this.props.parentState.recipients.raw,
        }
      }
      this.props.inputEventHandler(e);
    }, 100);
  }, 500);

  externCcRecipientOnBlur = debounce(() => {
    setTimeout(() => {
      let e = {
        target: {
          name: 'ccRecipients',
          raw: this.props.parentState.ccRecipients.raw,
        }
      }
      this.props.inputEventHandler(e);
    }, 100);
  }, 500);

  externForAttentionOnBlur = debounce(() => {
    setTimeout(() => {
      let e = {
        target: {
          name: 'recipients',
          raw: this.props.parentState.recipients.raw,
        }
      }
      this.props.inputEventHandler(e);
    }, 100);
  }, 500);

  onChange = (e) => {
    this.props.inputEventHandler(e);
    if (e.target.name === 'externRecipients') {
      this.externRecipientOnBlur();
    }
    if (e.target.name === 'externCcRecipients') {
      this.externCcRecipientOnBlur();
    }
    if (e.target.name === 'forAttentionExternRecipient') {
      this.externCcRecipientOnBlur();
    }
  }

  viaTypeSwitched = (checked) => {
    if (checked) {
      this.setState({ viaType: 'INTERNAL' });
      let e = {
        target: {
          name: 'externVia',
          raw: [],
        }
      }
      this.props.inputEventHandler(e);
    } else {
      this.setState({ viaType: 'EXTERNAL' });
      let e = {
        target: {
          name: 'externVia',
          raw: [],
        }
      }
      this.props.inputEventHandler(e);
    }
  }
  bundleStateSwitched = (checked) => {
    if (checked) {
      this.setState({ bundleState: 'WAIT' });
      // Clean all the chain role except signer
      this.props.inputEventHandler({
        target: {
          name: 'via',
          str: '',
          raw: [],
          value: '',
        }
      });
      this.props.inputEventHandler({
        target: {
          name: 'approvers',
          str: '',
          raw: [],
          value: '',
        }
      });
      this.props.inputEventHandler({
        target: {
          name: 'externVia',
          str: '',
          raw: [],
          value: '',
        }
      });
    } else {
      this.setState({ bundleState: 'NONE' });
    }
    let e = {
      target: {
        name: 'bundleState',
        value: checked ? 'WAIT' : 'NONE',
      }
    }
    this.props.inputEventHandler(e);
  }

  render() {
    const { inputEventHandler } = this.props;
    return (
      <div>
        <form className="form-horizontal" style={{ paddingTop: 10 }}>
          {!config.letterBundleDisabled && this.props.letterType !== 'TNDE_MEMO' &&
            <div className="form-group row" style={{ marginBottom: 0 }}>
              <div className="col-md-1 control-label" style={{ textAlign: 'right' }}>
                <label>Paket surat</label>
              </div>
              <div className="col-md-11" >
                <Switch
                  disabled={this.props.parentState.isBundlerProcChainHolder}
                  onChange={this.bundleStateSwitched}
                  checked={this.props.parentState.bundleState === 'WAIT'}
                />
              </div>
            </div>
          }
          <AddressBookModal
            name="recipients"
            value={this.props.parentState.recipients.str}
            raw={this.props.parentState.recipients.raw}
            label="Kepada Internal"
            placeholder="Klik di sini untuk membuka Buku Alamat"
            onChange={inputEventHandler}
            row={4}
            oaVisible={true}
            required={(this.props.letterType === 'TNDE_MEMO' || this.props.letterType === 'TNDE_SURAT_TUGAS') ? true : false}
            single={false}
          />
          {(this.props.letterType === 'TNDE_NOTA_DINAS' ||
            this.props.letterType === 'TNDE_UNDANGAN' ||
            this.props.letterType === 'TNDE_SURAT') &&
            <LetterFormTextArea
              name="externRecipients"
              value={this.props.parentState.externRecipients.str}
              label="Kepada Eksternal"
              onChange={this.onChange}
              row={2}
              maxChar={1500}
              additionalLabel={'Bila lebih dari satu, dipisah tanda ;'}
              onBlur={this.externRecipientOnBlur}
            />
          }
          {(this.props.letterType === 'TNDE_NOTA_DINAS' ||
            this.props.letterType === 'TNDE_UNDANGAN' ||
            this.props.letterType === 'TNDE_SURAT') &&
            (this.props.parentState.externRecipients &&
              this.props.parentState.externRecipients.raw &&
              this.props.parentState.externRecipients.raw.length > 0) &&
            <AddressBookSortererModal
              title="Daftar Penerima Surat"
              name="recipientSorted"
              value={this.props.parentState.recipientSorted}
              selected={this.props.parentState.recipients.raw}
              extern={this.props.parentState.externRecipients.raw}
              cc={false}
              label="Gabungan Penerima Surat"
              placeholder="Klik di sini untuk mengatur susunan penerima surat"
              onChange={inputEventHandler}
              row={4}
            />
          }
          {(this.props.letterType === 'TNDE_NOTA_DINAS' ||
            this.props.letterType === 'TNDE_UNDANGAN' ||
            this.props.letterType === 'TNDE_SURAT') &&
            <AddressBookModal
              name="viaRecipients"
              value={this.props.parentState.viaRecipients.str}
              raw={this.props.parentState.viaRecipients.raw}
              label="Melalui Internal"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              required={false}
              single={false}
            />
          }
          {
          /* Leave it here since there is huge probability it'll be used again in the future

          {this.props.letterType === 'TNDE_NOTA_DINAS' && this.state.viaType === 'INTERNAL' &&
            <AddressBookModal
              name="externVia"
              disabled={(this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder)}
              value={this.props.parentState.externVia.str}
              raw={this.props.parentState.externVia.raw}
              label="Melalui Eksternal"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              required={false}
              single={false}
            />
          }
          {
          {this.props.letterType === 'TNDE_NOTA_DINAS' &&
            <div className="form-group row" style={{ marginBottom: 0 }}>
              <div className="col-md-1" >
              </div>
              <div className="col-md-11" >
                <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: 5, paddingRight: 15 }}>Pilih <b>Melalui Eksternal</b> dari buku alamat</div>
                <Switch
                  onChange={this.viaTypeSwitched}
                  checked={this.state.viaType === 'INTERNAL'}
                />
              </div>
            </div>
          }

          */
          }
          {
          this.props.letterType === 'TNDE_NOTA_DINAS' && this.state.viaType === 'EXTERNAL' &&
            <LetterFormTextArea
              name="externVia"
              disabled={(this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder)}
              value={this.props.parentState.externVia.str}
              label="Melalui Eksternal"
              onChange={this.onChange}
              row={2}
              maxChar={1500}
              additionalLabel={'Bila lebih dari satu, dipisah tanda ;'}
            />
          }
          {this.props.letterType !== 'TNDE_MEMO' &&
            <div className="form-group row" style={{ marginTop: 10 }}>
              <label className={`col-md-1`}>
              </label>
              <div className={`col-md-10`}>
                <div style={{ float: 'right' }}>
                {(!this.state.showForAttention && !this.props.parentState.showForAttention) && (this.props.letterType === 'TNDE_SURAT' || this.props.letterType === 'TNDE_NOTA_DINAS') &&
                    <label
                      className="checkbox-inline pointer label-font-weight-normal"
                    >
                      <input
                        type="checkbox"
                        value=""
                        onClick={() => {
                          this.setState({ showForAttention: true });
                          this.props.setParentState({ showForAttention: true });
                        }}
                      />
                      &nbsp;Untuk Perhatian
                    </label>
                  }
                  &nbsp;&nbsp;&nbsp;
                  {(!this.state.showCc && !this.props.parentState.showCc) &&
                    <label
                      className="checkbox-inline pointer label-font-weight-normal"
                    >
                      <input
                        type="checkbox"
                        value=""
                        onClick={() => {
                          this.setState({ showCc: true });
                          this.props.setParentState({ showCc: true });
                        }}
                      />
                      &nbsp;Tembusan
                    </label>
                  }
                  &nbsp;&nbsp;&nbsp;
                  {(!this.state.showOrderer && !this.props.parentState.showOrderer) &&
                    <label className="checkbox-inline pointer label-font-weight-normal">
                      <input
                        type="checkbox"
                        value=""
                        onClick={() => {
                          this.setState({ showOrderer: true });
                          this.props.setParentState({ showOrderer: true });
                        }}
                      />
                      &nbsp;Pemesan
                    </label>
                  }
                </div>
              </div>
            </div>}
            {(this.state.showForAttention || this.props.parentState.showForAttention) && (this.props.letterType === 'TNDE_SURAT' || this.props.letterType === 'TNDE_NOTA_DINAS') &&
            <div>
              <AddressBookModal
              name="forAttentionInternRecipient"
              value={this.props.parentState.forAttentionInternRecipient.str}
              raw={this.props.parentState.forAttentionInternRecipient.raw}
              label="u.p. Internal"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              disabled={(this.props.parentState.recipients.raw.length === 0 || this.props.parentState.recipients.raw.length > 1 || this.props.parentState.forAttentionExternRecipient.str.length > 0 )}
              single={true}
            />
            <LetterFormTextArea
              name="forAttentionExternRecipient"
              onBlur={this.externForAttentionOnBlur}
              value={this.props.parentState.forAttentionExternRecipient.str}
              label="u.p. Eksternal"
              onChange={this.onChange}
              row={2}
              maxChar={1500}
              additionalLabel={'Harus menulis satu penerima tidak boleh lebih'}
              disabled={(this.props.parentState.externRecipients.raw.length === 0 || this.props.parentState.externRecipients.raw.length > 1 || this.props.parentState.forAttentionInternRecipient.raw.length > 0)}
            />
            </div>
          }
          {(this.state.showCc || this.props.parentState.showCc) && this.props.letterType !== 'TNDE_MEMO' &&
            <AddressBookModal
              name="ccRecipients"
              value={this.props.parentState.ccRecipients.str}
              raw={this.props.parentState.ccRecipients.raw}
              label={this.props.letterType === 'TNDE_SURAT_TUGAS' ? 'Tembusan' : 'Tembusan Internal'}
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={2}
            />}
          {(this.state.showCc || this.props.parentState.showCc) && (
            this.props.letterType === 'TNDE_NOTA_DINAS' ||
            this.props.letterType === 'TNDE_UNDANGAN' ||
            this.props.letterType === 'TNDE_SURAT'
          ) &&
            <LetterFormTextArea
              name="externCcRecipients"
              value={this.props.parentState.externCcRecipients.str}
              label="Tembusan Eksternal"
              onChange={this.onChange}
              row={2}
              maxChar={1500}
              additionalLabel={'Bila lebih dari satu, dipisah tanda ;'}
              onBlur={this.externCcRecipientOnBlur}
            />}
          {(this.state.showCc || this.props.parentState.showCc) && this.props.letterType !== 'TNDE_MEMO' &&
            <AddressBookModal
              name="bccSecretRecipients"
              value={this.props.parentState.bccSecretRecipients.str}
              raw={this.props.parentState.bccSecretRecipients.raw}
              label={'Tembusan Rahasia (bcc)'}
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={2}
            />}
          {(this.state.showCc || this.props.parentState.showCc) && (
            this.props.letterType === 'TNDE_NOTA_DINAS' ||
            this.props.letterType === 'TNDE_UNDANGAN'
          ) &&
            <AddressBookSortererModal
              title="Daftar Tembusan Surat"
              name="ccRecipientSorted"
              value={this.props.parentState.ccRecipientSorted}
              selected={this.props.parentState.ccRecipients.raw}
              extern={this.props.parentState.externCcRecipients.raw}
              cc={true}
              label="Gabungan Tembusan Surat"
              placeholder="Klik di sini untuk mengatur susunan tembusan surat"
              onChange={inputEventHandler}
              row={4}
            />}

          {(this.state.showOrderer || this.props.parentState.showOrderer) &&
            this.props.letterType !== 'TNDE_MEMO' &&
            <AddressBookModal
              name="orderedBy"
              disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              value={this.props.parentState.orderedBy.str}
              raw={this.props.parentState.orderedBy.raw}
              label="Pemesan"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={1}
              single={true}
            />}
          {(this.state.showOrderer || this.props.parentState.showOrderer) &&
            this.props.letterType !== 'TNDE_MEMO' &&
            <LetterFormTextArea
              name="ordererNotes"
              value={this.props.parentState.ordererNotes}
              label="Catatan Pemesan"
              onChange={inputEventHandler}
              row={3}
              maxChar={500}
            />}

          {this.props.letterType === 'TNDE_UNDANGAN' &&
            <div>
              <DateTimePicker
                label="Dari Tanggal"
                name="invitationStartDate"
                value={this.props.parentState.invitationStartDate}
                required={true}
                onChange={inputEventHandler}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Sampai Tanggal"
                name="invitationEndDate"
                value={this.props.parentState.invitationEndDate}
                onChange={inputEventHandler}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Waktu Mulai"
                name="invitationStartTime"
                value={this.props.parentState.invitationStartTime}
                required={true}
                onChange={inputEventHandler}
                timeOnly={true}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Waktu Selesai"
                name="invitationEndTime"
                value={this.props.parentState.invitationEndTime}
                onChange={inputEventHandler}
                timeOnly={true}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <LetterFormTextArea
                label="Tempat Undangan"
                name="invitationVenue"
                value={this.props.parentState.invitationVenue}
                onChange={inputEventHandler}
                maxChar={500}
                row={1}
                require={true}
                additionalLabel={'Tempat undangan jangan salin dari Ms. Word atau situs web'}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              {!config.noRapat && 
                <div className="form-group row" style={{ marginTop: 10 }}>
                <label className={`col-md-1`}>
                </label>
                <div className={`col-md-10`}>

                  <label
                    className="checkbox-inline pointer label-font-weight-normal"
                  >
                    <input type="checkbox"

                      checked={this.props.parentState.invitationRapatRoom !== '' &&
                        this.props.parentState.invitationRapatRoom != null
                        ? true : false}
                      onChange={(event) => {
                        const target = event.target
                        if (target.checked) {
                          this.props.setParentState({ invitationRapatRoom: 'booked' });
                        } else {
                          this.props.setParentState({ invitationRapatRoom: '' });
                        }

                      }}
                    /> &nbsp; Siapkan juga ruang rapat daring</label>
                </div></div>
              }
              
            </div>
          }
          {(this.props.letterType === 'TNDE_SURAT' || this.props.letterType === 'TNDE_UNDANGAN') &&
            <LetterFormTextArea
              name="destination"
              value={this.props.parentState.destination}
              label="Alamat & Kota Tujuan"
              required={true}
              row={4}
              onChange={inputEventHandler}
              disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
            />}

          <hr/>

          {this.props.letterType === 'TNDE_NOTA_DINAS' &&
            <AddressBookModal
              name="via"
              disabled={(this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder) || this.props.parentState.bundleState !== 'NONE'}
              value={this.props.parentState.via.str}
              raw={this.props.parentState.via.raw}
              label="Melalui"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              required={false}
              single={false}
            />
          }
          {this.props.letterType !== 'TNDE_MEMO' &&
            <AddressBookModal
              name="approvers"
              disabled={(this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder) || this.props.parentState.bundleState !== 'NONE'}
              value={this.props.parentState.approvers.str}
              raw={this.props.parentState.approvers.raw}
              label="Pemeriksa"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              single={false}
            />
          }
          {this.props.letterType !== 'TNDE_MEMO' &&
            <AddressBookModal
              name="signers"
              disabled={(this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder) || this.props.parentState.isBundlerProcChainHolder}
              value={this.props.parentState.signers.str}
              raw={this.props.parentState.signers.raw}
              label="Penanda tangan"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={4}
              required={true}
              single={false}
            />
          }
          {this.props.letterType === 'TNDE_MEMO' && this.state.currentUser.name &&
            <LetterFormTextInput
              value={this.state.currentUser.name}
              label="Penanda tangan"
              row={1}
              disabled={true}
            />
          }


          <hr/>

          {/* Beyond from this point, these fields are showed on all letterType*/}
          <Editor
            name="subject"
            label="Isi Perihal"
            required={true}
            type="simple-styling"
            maxContents="500"
            content={this.props.parentState.subject}
            onChange={inputEventHandler}
            warningText={'Hindari menyalin teks dari Microsoft Word atau aplikasi lain'}
          />
          <ClassificationModal
            name="classification"
            value={this.props.parentState.classification.str}
            label="Pola Klasifikasi"
            placeholder="Klik di sini untuk membuka Daftar Klasifikasi"
            onChange={inputEventHandler}
            required={true}
          />
        </form>
      </div>
    );
  }
}
