import axios from "axios";
import Config from "../config";
import Error from './Error';

const ErrorService = new Error();

class Misc {

  getCurrentUser = () => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/user/" + JSON.parse(localStorage.getItem('current_user')).id,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          localStorage.setItem('current_user', JSON.stringify(response.data));
          window.store.dispatch(window.actions.loggedIn({ accessToken: localStorage.getItem('access_token'), currentUser: response.data }));
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getUser = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/user/" + id,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getGerbangOrgsChild = (id) => {
    if (!Config.gerbang) {
			// Terminate it silently
      return
    }
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/gerbang-organizations?parent=" + id,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.organizations);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setAvatar = (blobUuid) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/user/" + JSON.parse(localStorage.getItem('current_user')).id,
        {
          picture: blobUuid
        },
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setScannedSignature = (blobUuid) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/user/" + JSON.parse(localStorage.getItem('current_user')).id,
        {
          scannedSignature: blobUuid
        },
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getAddressBook = () => {
    return new Promise((resolve, reject) => {
      var obj = {}
      let isInternalAddressBookLoaded = false;
      axios.get(Config.apiHost + "/api/v1/users?limit=999",
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          obj = {
            personal: response.data.data,
          }
          return axios.get(Config.apiHost + "/api/v1/organizations?limit=999",
            {
              headers: this.generateHeaders()
            }
          );
        })
        .then((response) => {
          obj.jabatan = response.data.data;
          return axios.get(Config.apiHost + "/api/v1/user-groups?limit=999",
            {
              headers:this.generateHeaders()
            }
          );
        })
        .then((response) => {
          isInternalAddressBookLoaded = true;
          obj.group = response.data.data;
          if (!Config.gerbang) {
            resolve(obj);
            return;
          }
          return axios.get(Config.apiHost + "/api/v1/gerbang-organizations",
            {
              headers: this.generateHeaders()
            }
          );

        })
        .then((response) => {
          if (response) {
            obj.oa = response.data.organizations;
          }
          resolve(obj);
        })
        .catch((err) => {
          if (Config.gerbang && isInternalAddressBookLoaded) {
            obj.oa = [];
            resolve(obj)
          } else {
            ErrorService.handle(err);
            reject(err);
          }
        })
    });
  }

  getClassification = () => {
    return new Promise((resolve, reject) => {
      let url = Config.apiHost + "/api/v1/classifications?limit=0"
      if (Config.noDefaultClassifications) {
        url += '&noDefault=true'
      }
      axios.get(url,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setAssistant = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/assistant",
        payload,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setDelegation = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/delegation",
        payload,
        {
          headers: this.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  generateHeaders = () => {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    }
    if (localStorage.getItem('impersonator_token_id') !== null &&
         localStorage.getItem('impersonator_token_id').length > 0
         ){
           headers['X-Impersonator-Token-Id'] = localStorage.getItem('impersonator_token_id')
    }
    return headers
  }

}

export default Misc;
