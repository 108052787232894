import axios from "axios";
import Config from "../config";
import Error from './Error';
import Misc from './Misc';


const MiscService = new Misc()
const ErrorService = new Error();

class Classification {
  getAll = () => {
    return new Promise((resolve, reject) => {
      let url = Config.apiHost + "/api/v1/classifications?limit=9999"
      if (Config.noDefaultClassifications) {
        url += '&noDefault=true'
      }
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/classification/" + id,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  create = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/classifications",
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/classification/" + id,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  delete = (id) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/classification/" + id, 
      {
        headers: MiscService.generateHeaders()
      }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }
}

export default Classification;
