import React, { Component } from 'react';
import DragList from 'react-drag-list';
import * as Fa from 'react-icons/lib/fa';
import Misc from '../../../services/Misc';
import Spinner from '../../../components/Spinner';
import './styles.css';

const MiscService = new Misc();

export default class AddressBookModal extends Component {
  state = {
    showModal: false,
    loading: false,
    data: {},
    currentTab: 'Personal',
    tabNav: {
      Personal: {
        name: 'Personal',
        default: true,
      }
    },
    selected: [],
    filterValue: '',
  }
  componentDidMount = () => {
  }

  filter = (event) => {
    var val = event.target.value.toLowerCase();
    this.setState({ filterValue: val }, () => {
      this.setState((prev) => {
        if (val && val.length > 0) {
          for (var i in prev.data[prev.currentTab]) {
            if (!prev.data[prev.currentTab][i].hasChild && prev.data[prev.currentTab][i].name && prev.data[prev.currentTab][i].name.toLowerCase().indexOf(val) > -1) {
              prev.data[prev.currentTab][i].visibility = true;
              prev.data[prev.currentTab][i].filtered = true;
            } else {
              prev.data[prev.currentTab][i].visibility = false;
              prev.data[prev.currentTab][i].filtered = false;
            }
          }
          return { data: prev.data }
        } else {
          let tab = Object.keys(prev.data);
          for (var k in tab) {
            for (var j in prev.data[tab[k]]) {
              prev.data[tab[k]][j].filtered = false;
              prev.data[tab[k]][j].collapse = true;
              if (prev.data[tab[k]][j].level === 0) {
                prev.data[tab[k]][j].visibility = true;
              } else {
                prev.data[tab[k]][j].visibility = false;
              }
              if (prev.data[tab[k]][j].hasChild) {
                this.collapseAllChild(prev.data, prev.data[tab[k]][j].id);
              }
            }
          }
        }
        return { data: prev.data }
      });
    });
  }

  showModal = () => {
    if (this.props.candidates) {
      this.setState({ showModal: true, loading: true, selected: [], filterValue: '', currentTab: 'Personal' }, () => {
        this.setState({ data: { Personal: this.props.candidates }, loading: false });
      });

    } else {
      this.setState({ showModal: true, loading: true, selected: [], filterValue: '', currentTab: 'Personal' });
      let e = {
        target: {
          name: '',
          value: '',
          raw: ''
        }
      }
      this.props.onChange(e);
      MiscService.getAddressBook()
        .then((data) => {
          let personal = [];
          for (var pItem in data.personal) {
            if (!this.props.candidates && this.props.titleless && data.personal[pItem].organization && data.personal[pItem].organization.id !== '') {
              continue;
            }
            if (!data.personal[pItem].parent) {
              data.personal[pItem].level = 0;
              data.personal[pItem].visibility = true;
              data.personal[pItem].collapse = true;
            }
            if (data.personal[pItem].parent) {
              for (var p in personal) {
                if (personal[p].id === data.personal[pItem].parent) {
                  data.personal[pItem].level = personal[p].level + 1;
                }
              }
            }
            personal.push(data.personal[pItem]);
          };
          this.setState({ data: { Personal: personal }, loading: false }, () => {
            if (!(this.props.raw && this.props.raw.length > 0)) {
              return;
            }
          });
        })
        .catch((err) => {
          // handle err
        });
    }
  }
  hideModal = () => {
    this.setState({ showModal: false });
  }

  collapseAllChild = (data, parentId) => {
    for (var i in data) {
      if (data[i].parent === parentId) {
        data[i].collapse = true;
        data[i].visibility = false;
        if (data[i].hasChild) {
          this.collapseAllChild(data, data[i].id);
        }
      }
    }
  }

  handleClick = (index) => {
    this.setState((prev) => {
      let s = {};
      
      if (prev.data[prev.currentTab][index].hasChild) {
        prev.data[prev.currentTab][index].current = true;
        if (index === 0 && !prev.data[prev.currentTab][index].collapse) {
          s[prev.currentTab] = prev.data[prev.currentTab];
          return s;
        }
        for (var i in prev.data[prev.currentTab]) {
          if (prev.data[prev.currentTab][i].parent === prev.data[prev.currentTab][index].id) {
            prev.data[prev.currentTab][i].visibility = prev.data[prev.currentTab][index].collapse;
            if (prev.data[prev.currentTab][i].hasChild) prev.data[prev.currentTab][i].collapse = true;
            if (!prev.data[prev.currentTab][index].collapse) {
              this.collapseAllChild(prev.data[prev.currentTab], prev.data[prev.currentTab][index].id);
            }
          }
        }
        prev.data[prev.currentTab][index].collapse = !prev.data[prev.currentTab][index].collapse;
        s.data = prev.data
        return s;
      } else {
        prev.data[prev.currentTab][index].selected = !prev.data[prev.currentTab][index].selected;
        let selected = JSON.parse(JSON.stringify(prev.selected));
        let found = -1
        for (var j in selected) {
          if (selected[j].id === prev.data[prev.currentTab][index].id) {
            found = j;
          }
        }
        if (found > -1) {
          console.log('found');
          selected.splice(found, 1);
        } else {
          console.log('not found');
          selected.push(prev.data[prev.currentTab][index]);
          selected[selected.length - 1].type = prev.currentTab;
        }
        s.selected = selected;
        return s;
      }
    }, () => {
      if (this.props.single) {
        this.ok();
      }
    });
  }

  remove = (item, index) => {
    this.setState((prev) => {
      for (var j in prev.data['Personal']) {
        if (prev.data['Personal'][j].id === item.id) {
          prev.data['Personal'][j].selected = false;
        }
      }
      prev.selected.splice(index, 1);
      return { data: prev.data, selected: prev.selected }
    });
  }

  switchTab = (tab) => {
    this.filter({ target: { value: '' } });
    this.setState({ currentTab: tab });
  }

  ok = () => {
    
    let e = {
      target: {
        name: this.props.name,
        value: '',
        orgId: ''
      }
    }
    e.target.raw = ''
    for (var i in this.state.selected) {
      if (i > 0) {
        e.target.value += '\n';
      }
      e.target.value += this.state.selected[i].name;
      e.target.orgId = this.state.selected[i].organization.id;
      if (this.props.orgName){
        e.target.orgName = this.state.selected[i].organization.name
      }
      let item = this.props.item || {};
      item[this.props.name] = this.state.selected[i].id;
      e.target.raw = item;
      
    }
    
    this.props.onChange(e);
    this.hideModal();
  }

  render() {
    const {
      single,
    } = this.props;
    return (
      <span>
        {this.props.label ?
          <button type="button" onClick={this.showModal} className={this.props.primary ? "btn btn-primary" : "btn btn-default"}>
            <Fa.FaUser /> {this.props.label}
          </button> :
          <button type="button" onClick={this.showModal} className="btn btn-default btn-sm btn-sm-extra">
            <Fa.FaUser />
          </button>
        }
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Pilih nama
                </h4>
                <span className="close" onClick={this.hideModal}><Fa.FaClose /></span>
                <hr />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className={"col-md-" + (single ? 12 : 6).toString() + " col-sm-" + (single ? 12 : 6).toString()}>
                    <div style={{ display: this.state.loading ? 'none' : 'inherit' }}>
                      <div className="input-group input-group-sm" style={{ marginTop: 10 }}>
                        <input value={this.state.filterValue} className="form-control" onChange={this.filter} disabled={this.state.loading} placeholder="Cari" />
                        <span className="input-group-btn">
                          {this.state.filterValue.length > 0 ?
                            <span className="btn btn-default" onClick={() => { this.filter({ target: { value: '' } }) }}>
                              <Fa.FaClose />
                            </span>
                            :
                            <span className="btn btn-default">
                              <Fa.FaSearch />
                            </span>
                          }
                        </span>
                      </div>
                    </div>
                    <div className="box box-success" style={{ marginTop: 15 }}>
                      {this.state.loading ?
                        <Spinner />
                        :
                        <ul style={{ padding: 0, height: 250, overflow: 'auto' }}>
                          {this.state.data[this.state.currentTab] && Object.keys(this.state.data[this.state.currentTab]).map((item, index) => {
                            if (this.state.data[this.state.currentTab][item].name && this.state.data[this.state.currentTab][item].name.length > 0) {
                              return <li
                                key={index}
                                style={{
                                  padding: 10,
                                  display: this.state.data[this.state.currentTab][item].visibility ? 'block' : 'none',
                                  paddingLeft: this.state.data[this.state.currentTab][item].level === 0
                                    || this.state.data[this.state.currentTab][item].filtered
                                    ? 15 :
                                    this.state.data[this.state.currentTab][item].level === 1 ? 30 :
                                      this.state.data[this.state.currentTab][item].level === 2 ? 45 :
                                        this.state.data[this.state.currentTab][item].level === 3 ? 60 : 75,
                                  cursor: 'pointer',
                                  background: !this.state.data[this.state.currentTab][item].current ? 'none' : '#F7F7F7',
                                }}
                                onClick={() => { this.handleClick(index) }}
                              >
                                {!this.state.data[this.state.currentTab][item].collapse && this.state.data[this.state.currentTab][item].hasChild ? <Fa.FaCaretDown /> :
                                  this.state.data[this.state.currentTab][item].collapse && this.state.data[this.state.currentTab][item].hasChild && <Fa.FaCaretRight />
                                }
                                &nbsp;
                            	  {this.state.currentTab === 'Personal' &&
                                  <span style={{ fontWeight: this.state.data[this.state.currentTab][item].hasChild ? 'bold' : 'normal' }}>
                                    {this.state.data[this.state.currentTab][item].name}<br />
                                    <span style={{ fontSize: 10 }}>&nbsp;{this.state.data[this.state.currentTab][item].organization.name}</span>
                                  </span>
                                }
                                {!this.state.data[this.state.currentTab][item].hasChild && !this.state.data[this.state.currentTab][item].selected ?
                                  <Fa.FaCircleO style={{ float: 'Right' }} /> :
                                  !this.state.data[this.state.currentTab][item].hasChild && this.state.data[this.state.currentTab][item].selected && <Fa.FaCheck color="green" style={{ float: 'Right' }} />

                                }
                              </li>
                            } else {
                              return <span key={index} />
                            }
                          })}
                        </ul>
                      }
                    </div>
                  </div>
                  {!single &&
                    <div className="col-md-6 col-sm-6">
                      Pejabat terpilih
                    <div className="box box-success" style={{ marginTop: 10 }}>
                        <DragList
                          dataSource={this.state.selected}
                          rowKey="title"
                          row={(record, index) => (
                            <div key={index} style={{ color: record.color }}>
                              <div className="drag-list-item">{record.name}
                                <Fa.FaClose
                                  style={{ cursor: 'pointer', float: 'right', fontSize: 14, marginTop: 2 }}
                                  color="red"
                                  onClick={() => { this.remove(record, index) }}
                                />
                              </div>
                            </div>
                          )}
                          handles={false}
                          className="simple-drag"
                          rowClassName="simple-drag-row"
                          onUpdate={this._handleUpdate}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="modal-footer">
                {!single && <div style={{ float: 'right' }}>
                  <span className="btn btn-primary" style={{ marginRight: 10 }} onClick={this.ok}>OK</span>
                  <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}
