import axios from "axios";
import debounce from 'debounce';
import Config from "../config";
import Dummy from './Dummy';
import Auth from './Auth';
import Error from './Error';
import Misc from "./Misc";
import  * as date from "date-fns";
import dateLocaleId from 'date-fns/locale/id';

const DummyService = new Dummy();
const AuthService = new Auth();
const ErrorService = new Error();
const MiscService = new Misc()

const filterFields = ['order',
  'letterDateStart',
  'letterDateEnd',
  'referenceNumber',
  'subject',
  'signerName',
  'classification',
  'importance',
  'recipientName',
  'ccRecipientName',
]

const getStats = debounce(() => {
  if (!AuthService.getToken()) {
    return;
  }
  axios.get(Config.apiHost + "/api/v1/letter-stats?throughAssistant="+Config.dispositionThroughAssistant, {
    headers: MiscService.generateHeaders()
  })
    .then((result) => {
      if (result && result.data) {
        // Wrap the data
        let wrapped = {
          "INBOX": 0,
          "ALL_UNREADS": 0,
          "TNDE_NOTA_DINAS": 0,
          "TNDE_SURAT_TUGAS": 0,
          "TNDE_UNDANGAN": 0,
          "TNDE_SURAT": 0,
          "TNDE_MEMO": 0,
          "DISPOSITION": 0,
          "COMMENT": 0,

          "CANCELED": 0,

          "PROCESSING_AS_FINALIZER": 0,
          "PROCESSING": 0,
          "FINAL": 0,
          "NEED_APPROVAL": 0,
          "NEED_OPINION": 0,
          "RETURNED": 0,
          "DRAFT": 0,

          // REKAP
          "INBOX_TOTAL": 0,
          "DISPOSITION_TOTAL": 0,
          "DELEGATED_TOTAL": 0,
        }
        let data = result.data.data
        for (let i in data) {
          if (data[i].box === 'MANUAL_SURAT_DINAS') data[i].box = 'TNDE_SURAT';
          wrapped[data[i].box] += data[i].total || 0;
          // INBOX
          if (data[i].contentType && data[i].contentType === 'LETTER' && data[i].box && data[i].box !== 'NEED_APPROVAL' && data[i].box !== 'RETURNED' && data[i].box !== 'NEED_OPINION' && data[i].box !== 'FINAL' && data[i].box !== 'DRAFT') {
            wrapped["ALL_UNREADS"] += data[i].total || 0;
            wrapped["INBOX"] += data[i].total || 0;
          }
          if (data[i].contentType && (data[i].contentType === 'DISPOSITION' || data[i].contentType === 'COMMENT')) {
            wrapped["ALL_UNREADS"] += data[i].total || 0;
          }
          if (data[i].contentType && data[i].contentType === 'OUTBOX_CANCELED') {
            wrapped["CANCELED"]++;
          }
          // PROCESSING
          if (data[i].contentType && data[i].contentType === 'LETTER' && data[i].box && (data[i].box === 'NEED_APPROVAL' || data[i].box === 'RETURNED' || data[i].box === 'NEED_OPINION' || data[i].box === 'FINAL' || data[i].box === 'DRAFT')) {
            if (data[i].box === 'FINAL' && AuthService.getRoles().role === 'FINALIZER') {
              wrapped["PROCESSING"] += data[i].total || 0;
            } else if (data[i].box !== 'FINAL') {
              wrapped["PROCESSING"] += data[i].total || 0;
            }
          }
          // TOTAL
          if (data[i].contentType && data[i].contentType === 'INBOX_TOTAL' && data[i].box && data[i].box === 'INBOX_TOTAL') {
            wrapped["INBOX_TOTAL"] += data[i].total || 0;
          }
          if (data[i].contentType && data[i].contentType === 'DISPOSITION_TOTAL' && data[i].box && data[i].box === 'DISPOSITION_TOTAL') {
            wrapped["DISPOSITION_TOTAL"] += data[i].total || 0;
          }
          if (data[i].contentType && data[i].contentType === 'DELEGATED_TOTAL' && data[i].box && data[i].box === 'DELEGATED_TOTAL') {
            wrapped["DELEGATED_TOTAL"] += data[i].total || 0;
          }
        }
        window.store.dispatch(window.actions.stats(wrapped));
      }
    })
    .catch((err) => {
      // do nothing
    })
}, 1000)


class Letter {

  getLetterReferences = (opt) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(DummyService.getLetterReferences(opt));
      }, 500);
    });
  }

  getList = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      if (!opt.box) return reject(new Error('box type should be defined.'));
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/letters?box=${opt.box}&page=${opt.page}&limit=${opt.limit}`;
      if (opt.letterType && opt.letterType.length > 0 && opt.letterType.toUpperCase() !== 'ALL') {
        url += `&letter_type=${opt.letterType.toUpperCase()}`;
      }
      if (opt.unread) {
        url += `&unread=true`;
      }
      if (opt.invitationDisposition) {
        url += `&invitationDisposition=true`;
      }
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`;
        url += `&signerName=${opt.search}`;
      }
      if (opt.invitationStartDate && opt.invitationEndDate) {
        url += `&invitationStartDate=${opt.invitationStartDate}&invitationEndDate=${opt.invitationEndDate}`
      }
      for (let i in filterFields) {
        if (opt[filterFields[i]]) {
          url += `&${filterFields[i]}=${opt[filterFields[i]]}`
        }
      }
      if (opt.year) {
        url += `&year=${opt.year}`;
      }
      if (opt.markAllAsRead) {
        url += `&markAllAsRead=true`;
      }
      if (opt.bundleSigners && opt.bundleSigners.length > 0) {
        url += `&bundleSigners=${opt.bundleSigners}`;
      }
      if (opt.startDate && opt.endDate) {
        url += `&start_date=${opt.startDate}&end_date=${opt.endDate}`;
      }
      // Advanced filter
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          getStats()
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  createLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letters",
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  updateLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/" + letter.id,
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  deleteLetter = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/letter/" + letterId, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getPDFLetter = (documentID) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/pdf/view/" + documentID,
        {
          responseType: 'blob',
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          resolve(url);
        })
        .catch((err) => {
          console.log(err.response)
          if (err.response && err.response.status !== 404) {
            ErrorService.handle(err);

          }
          reject(err);
        })
    });
  }

  createPDFLetter = (html, documentID) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/pdf/create",
        { html: html, "document-id": documentID },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          if (err.response &&
            err.response.data &&
            err.response.data.message) {
            if (err.response.data.message === "duplicate") {
              // Silently ignore error on duplicates
              resolve();
            } else {
              ErrorService.handle(err);
              reject(err);
            }
          }
        })
    });
  }

  getCSSForPDF = (id) => {
    return new Promise((resolve, reject) => {
      var url = window.location.href;
      var arr = url.split("/");
      var result = arr[0] + "//" + arr[2]
      axios.get(result + "/print.css", {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          return resolve(response)
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }


  readLetter = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/" + id, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          if (response.data.letterState &&
            (
              response.data.letterState === 'SENT' ||
              response.data.letterState === 'CANCELED'
            )
          ) {
            this.setReadStatus(id, 'LETTER');
          }
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  processLetter = (uuid, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/process/" + uuid,
        payload, {
          headers: MiscService.generateHeaders()
        })
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  pullBackLetter = (letter) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/" + letter.id + '?pullBack=true',
        letter,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }


  getHistories = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) return reject(new Error('id should not be null'));
      let url = `${Config.apiHost}/api/v1/letter-histories?letterId=${id}&limit=999`; // Fetch them all
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getComments = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) return reject(new Error('id should not be null'));
      let url = `${Config.apiHost}/api/v1/letter-comments?itemId=${id}&limit=999`; // Fetch them all
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  createDisposition = (disposition) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter-dispositions",
        disposition, {
          headers: MiscService.generateHeaders()
        })
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  updateDisposition = (disposition) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter-disposition/" + disposition.id,
        disposition,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getDispositions = (opt) => {
    return new Promise((resolve, reject) => {
      let url = Config.apiHost + "/api/v1/letter-dispositions";
      let query = '';
      opt = opt || {}
      opt.throughAssistant = Config.dispositionThroughAssistant
      for (let i in Object.keys(opt)) {
        if (opt[Object.keys(opt)[i]] && opt[Object.keys(opt)[i]] !== undefined) {
          if (query.length > 0) {
            query += '&';
          }
          query += Object.keys(opt)[i] + '=' + opt[Object.keys(opt)[i]];
        }
      }
      if (query.length > 0) {
        url += '?' + query;
      }
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setDispositionState = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/disposition-state/" + payload.statusId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data.lastInsertId);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  forward = (letterId, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/forward/" + letterId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setReadStatus = (contentId, contentType) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/read-status",
        {
          id: contentId,
          contentType: contentType
        },
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          getStats()
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  askOpinion = (letterId, payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  cancelAskOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  answerOpinion = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter/ask-opinion/" + letterId,
        null,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  setLetterHeaderForPUA = (puaID, letterHeaderID) => {
    const payload = {
      puaID,
      letterHeaderID
    }
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter/letter-header-for-pua",
        payload,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve();
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getLetterHeaderForPUA = (puaID) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter/letter-header-for-pua/" + puaID,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  getStats = () => {
    getStats();
  }

  getDashboardAdminStats = () => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/dashboard-management-stats",
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((result) => {
          let wrapped = {
            "DISPOSITION_OUT": 0,
            "DISPOSITION_IN_PROCESS": 0,
            "DISPOSITION_CLOSED": 0,
            "LETTER_WILL_BE_APPROVED": 0,

            letterInProcess: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            letterReturned: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            letterInFinalization: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            letterSent: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },

            tndeNotaDinas: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            tndeSuratTugas: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            tndeUndangan: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            tndeSurat: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
            tndeMemo: {
              daily: 0,
              weekly: {
                "Senin": 0,
                "Selasa": 0,
                "Rabu": 0,
                "Kamis": 0,
                "Jumat": 0,
              },
              monthly: {
                "Minggu ke-1": 0,
                "Minggu ke-2": 0,
                "Minggu ke-3": 0,
                "Minggu ke-4": 0,
                "Minggu ke-5": 0,
              },
              yearly: {
                "Januari": 0,
                "Februari": 0,
                "Maret": 0,
                "April": 0,
                "Mei": 0,
                "Juni": 0,
                "Juli": 0,
                "Agustus": 0,
                "September": 0,
                "Oktober": 0,
                "November": 0,
                "Desember": 0,
              },
            },
          }
          let data = result.data.data
          for (let i in data ) {
            if (data[i].contentType && data[i].contentType === 'DISPOSITION_IN_PROCESS' && data[i].box && data[i].box === 'DISPOSITION_IN_PROCESS') {
              wrapped["DISPOSITION_IN_PROCESS"] += data[i].total || 0
            }
            if (data[i].contentType && data[i].contentType === 'DISPOSITION_CLOSED' && data[i].box && data[i].box === 'DISPOSITION_CLOSED') {
              wrapped["DISPOSITION_CLOSED"] += data[i].total || 0
            }
            if (data[i].contentType && data[i].contentType === 'DISPOSITION_OUT' && data[i].box && data[i].box === 'DISPOSITION_OUT') {
              wrapped["DISPOSITION_OUT"] += data[i].total || 0
            }
            if (data[i].contentType && data[i].contentType === 'LETTER_WILL_BE_APPROVED' && data[i].box && data[i].box === 'LETTER_WILL_BE_APPROVED') {
              wrapped["LETTER_WILL_BE_APPROVED"] += data[i].total || 0
            }
            if (data[i].contentType && data[i].contentType === 'LETTER_IN_PROCESS' && data[i].box && data[i].box === 'LETTER_IN_PROCESS') {
              wrapped.letterInProcess.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterInProcess.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.letterInProcess.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.letterInProcess.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.letterInProcess.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.letterInProcess.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.letterInProcess.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterInProcess.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'LETTER_RETURNED' && data[i].box && data[i].box === 'LETTER_RETURNED') {
              wrapped.letterReturned.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterReturned.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.letterReturned.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.letterReturned.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.letterReturned.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.letterReturned.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.letterReturned.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterReturned.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'LETTER_IN_FINAL' && data[i].box && data[i].box === 'LETTER_IN_FINAL') {
              wrapped.letterInFinalization.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterInFinalization.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.letterInFinalization.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.letterInFinalization.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.letterInFinalization.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.letterInFinalization.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.letterInFinalization.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterInFinalization.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'LETTER_SENT' && data[i].box && data[i].box === 'LETTER_SENT') {
              wrapped.letterSent.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterSent.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.letterSent.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.letterSent.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.letterSent.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.letterSent.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.letterSent.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterSent.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'TNDE_NOTA_DINAS' && data[i].box && data[i].box === 'TNDE_NOTA_DINAS') {
              wrapped.tndeNotaDinas.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeNotaDinas.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.tndeNotaDinas.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.tndeNotaDinas.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.tndeNotaDinas.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.tndeNotaDinas.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.tndeNotaDinas.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeNotaDinas.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'TNDE_SURAT_TUGAS' && data[i].box && data[i].box === 'TNDE_SURAT_TUGAS') {
              wrapped.tndeSuratTugas.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.letterInProcess.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.tndeSuratTugas.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.tndeSuratTugas.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.tndeSuratTugas.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.tndeSuratTugas.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.tndeSuratTugas.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeSuratTugas.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'TNDE_SURAT' && data[i].box && data[i].box === 'TNDE_SURAT') {
              wrapped.tndeSurat.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeSurat.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.tndeSurat.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.tndeSurat.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.tndeSurat.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.tndeSurat.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.tndeSurat.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeSurat.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'TNDE_MEMO' && data[i].box && data[i].box === 'TNDE_MEMO') {
              wrapped.tndeMemo.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeMemo.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.tndeMemo.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.tndeMemo.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.tndeMemo.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.tndeMemo.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.tndeMemo.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeMemo.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
            if (data[i].contentType && data[i].contentType === 'TNDE_UNDANGAN' && data[i].box && data[i].box === 'TNDE_UNDANGAN') {
              wrapped.tndeUndangan.daily += data[i].freqDaily || 0
              for (let j in data[i].freqWeekly) {
                let val = date.format(data[i].freqWeekly[j].date, 'dddd', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeUndangan.weekly[val] += data[i].freqWeekly[j].total
                }
              }
              for (let j in data[i].freqMonthly){
                if (data[i].freqMonthly[j].weekOfMonth === 1) {
                  wrapped.tndeUndangan.monthly[`Minggu ke-1`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 2) {
                  wrapped.tndeUndangan.monthly[`Minggu ke-2`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 3) {
                  wrapped.tndeUndangan.monthly[`Minggu ke-3`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 4) {
                  wrapped.tndeUndangan.monthly[`Minggu ke-4`] += data[i].freqMonthly[j].total
                }
                if (data[i].freqMonthly[j].weekOfMonth === 5) {
                  wrapped.tndeUndangan.monthly[`Minggu ke-5`] += data[i].freqMonthly[j].total
                }
              }
              for (let j in data[i].freqYearly) {
                let val = date.format(data[i].freqYearly[j].date, 'MMMM', { locale: dateLocaleId })
                if (val && val.length > 0) {
                  wrapped.tndeUndangan.yearly[val] += data[i].freqYearly[j].total
                }
              }
            }
          }
          resolve(wrapped)
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    }); 
  }

  forwardDisposition = (letterId) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/forward-disposition/" + letterId,
        null,
        {
          headers: MiscService.generateHeaders()
        }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

}

export default Letter;
