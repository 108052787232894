import React, { Component } from 'react';
import LetterFormTextArea from './LetterFormTextArea';
import ClassificationModal from '../../../components/ClassificationModal';
import AddressBookModal from '../../AddressBookModal';
import DateTimePicker from '../../../components/DateTimePicker';
import FileUploader from './FileUploader/';
import LetterFormSelectInput from './LetterFormSelectInput';
import Editor from '../../../../../components/Editor';
import config from '../../../../../config';

import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import Auth from '../../../../../services/Auth';
const AuthService = new Auth();

export default class component extends Component {
  state = {
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'FRONTOFFICE') {
      window.browserHistory.push('/');
      return;
    }
  }

  render() {
    const { inputEventHandler } = this.props;
    return (
      <div>
        <form className="form-horizontal" style={{ paddingTop: 10 }}>
          <h4>Informasi Surat</h4>
          <div className="form-group row">
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Jenis Surat
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>
              <select
                className="form-control"
                name="manualLetterType"
                value={this.props.parentState.manualLetterType}
                onChange={inputEventHandler}
              >
                <option value="TNDE_NOTA_DINAS">Nota Dinas</option>
                <option value="MANUAL_SURAT_DINAS">Surat Dinas</option>
                <option value="TNDE_UNDANGAN">Surat Undangan</option>
                <option value="MANUAL_SURAT_EDARAN">Surat Edaran</option>
                <option value="MANUAL_INSTRUKSI">Instruksi</option>
                <option value="MANUAL_SURAT_KEPUTUSAN">Surat Keputusan</option>
                <option value="MANUAL_SURAT_PERINTAH">Surat Perintah</option>
                <option value="TNDE_SURAT_TUGAS">Surat Tugas</option>
                <option value="MANUAL_SURAT_PERJANJIAN">Surat Perjanjian </option>
                <option value="MANUAL_SURAT_KUASA">Surat Kuasa</option>
                <option value="MANUAL_BERITA_ACARA">Berita Acara</option>
                <option value="MANUAL_SURAT_KETERANGAN">Surat Keterangan</option>
                <option value="MANUAL_SURAT_PENGANTAR">Surat Pengantar</option>
                <option value="MANUAL_PENGUMUMAN">Pengumuman</option>
                <option value="MANUAL_LAPORAN">Laporan</option>
                <option value="MANUAL_TELAAHAN_STAF">Telaahan Staf</option>
                <option value="MANUAL_NOTULA">Notula</option>
                <option value="MANUAL_LEMBAR_RALAT">Lembar Ralat</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Nomor Agenda
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>

              Akan otomatis diisikan setelah surat disimpan
            </div>
          </div>
          <div className="form-group row">
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Tanggal terima
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>
              <span className="form-control">
                {date.format((new Date()), 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Nomor surat
             <span style={{ color: 'red' }}>*</span>
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>
              <input
                className="form-control"
                name="number"
                value={this.props.parentState.number}
                onChange={inputEventHandler}
              />
            </div>
          </div>
          <DateTimePicker
            label="Tanggal surat"
            name="letterDate"
            value={this.props.parentState.letterDate}
            required={true}
            onChange={inputEventHandler}
            disabled={false}
          />
          <ClassificationModal
            name="classification"
            value={this.props.parentState.classification.str}
            label="Pola Klasifikasi"
            placeholder="Klik di sini untuk membuka Daftar Klasifikasi"
            required={true}
            onChange={inputEventHandler}
          />


          {this.props.parentState.manualLetterType === 'TNDE_UNDANGAN' &&
            <div>
              <hr />
              <h4>Detil Undangan</h4>
              <DateTimePicker
                label="Dari Tanggal"
                name="invitationStartDate"
                value={this.props.parentState.invitationStartDate}
                required={true}
                onChange={inputEventHandler}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Sampai Tanggal"
                name="invitationEndDate"
                value={this.props.parentState.invitationEndDate}
                onChange={inputEventHandler}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Waktu Mulai"
                name="invitationStartTime"
                value={this.props.parentState.invitationStartTime}
                required={true}
                onChange={inputEventHandler}
                timeOnly={true}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <DateTimePicker
                label="Waktu Selesai"
                name="invitationEndTime"
                value={this.props.parentState.invitationEndTime}
                onChange={inputEventHandler}
                timeOnly={true}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
              <LetterFormTextArea
                label="Tempat Undangan"
                name="invitationVenue"
                value={this.props.parentState.invitationVenue}
                onChange={inputEventHandler}
                maxChar={500}
                row={1}
                require={true}
                additionalLabel={'Tempat undangan jangan salin dari Ms. Word atau situs web'}
                disabled={this.props.parentState.isInProcessChain && this.props.parentState.isProcessChainHolder}
              />
            </div>
          }
          <hr />
          <h4>Alamat Surat</h4>
          <div className="form-group row" style={{ marginTop: 10 }}>
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Dari
             <span style={{ color: 'red' }}>*</span>
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>
              <input
                className="form-control"
                name="manualLetterSender"
                value={this.props.parentState.manualLetterSender}
                onChange={inputEventHandler}
              />
            </div>
          </div>
          <AddressBookModal
            name="recipients"
            value={this.props.parentState.recipients.str}
            raw={this.props.parentState.recipients.raw}
            label="Kepada"
            placeholder="Klik di sini untuk membuka Buku Alamat"
            onChange={inputEventHandler}
            row={4}
            oaVisible={true}
            required={true}
            single={false}
          />
          <AddressBookModal
            name="ccRecipients"
            value={this.props.parentState.ccRecipients.str}
            raw={this.props.parentState.ccRecipients.raw}
            label="Tembusan"
            placeholder="Klik di sini untuk membuka Buku Alamat"
            onChange={inputEventHandler}
            row={2}
          />
          {config.manualLetterReviewerEnabled ?
            <AddressBookModal
              name="reviewer"
              value={this.props.parentState.reviewer.str}
              raw={this.props.parentState.reviewer.raw}
              label="Pemeriksa"
              placeholder="Klik di sini untuk membuka Buku Alamat"
              onChange={inputEventHandler}
              row={1}
              required={false}
              single={true}
            /> : <div></div>}

          <hr />
          <h4>Perihal Surat</h4>

          <Editor
            name="subject"
            label="Isi Perihal"
            required={true}
            type="simple-styling"
            maxContents="500"
            content={this.props.parentState.subject}
            onChange={inputEventHandler}

          />
          <div className="form-group row" style={{ marginTop: 10 }}>
            <label className={`col-md-1 col-sm-2 control-label`} style={{ textAlign: 'right', padding: 15 }}>
              Lampiran
             <span style={{ color: 'red' }}>*</span>
            </label>
            <div className={`col-md-10 col-sm-10`} style={{ padding: 15 }}>
              <input
                className="form-control"
                name="customAttachment"
                value={this.props.parentState.customAttachment.length > 0 ? this.props.parentState.customAttachment :
                  (this.props.parentState.files.length === 0 ? "" : this.props.parentState.files.length + ' berkas')}
                onChange={inputEventHandler}
              />
            </div>
          </div>
          <DateTimePicker
            name="deadline"
            value={this.props.parentState.deadline}
            label="Tenggat Waktu"
            required={false}
            onChange={inputEventHandler}
            labelWidth="1"
            fieldWidth="8"
          />
          <LetterFormSelectInput
            name="importance"
            value={this.props.parentState.importance}
            label="Sifat"
            labelWidth="1"
            fieldWidth="8"
            onChange={inputEventHandler}
            options={[
              { value: "NORMAL", str: "Biasa" },
              { value: "LIMITED", str: "Terbatas" },
              { value: "SECRET", str: "Rahasia" },
              { value: "TOP_SECRET", str: "Sangat Rahasia" },
              { value: "IMPORTANT", str: "Segera" },
              { value: "VERY_IMPORTANT", str: "Sangat Segera" }
            ]}
            defaultValue="NORMAL"
          />
          <LetterFormTextArea
            name="notes"
            value={this.props.parentState.notes}
            label="Catatan"
            onChange={inputEventHandler}
            row={3}
            maxChar={500}
          />

          <hr />
          <h4>
            Dokumen
             <span style={{ color: 'red' }}>*</span>
          </h4>
          <FileUploader
            files={this.props.parentState.files}
            onChange={this.props.attachmentHandler}
            hideInternalAttachment={true}
          />
        </form>
      </div>
    );
  }
}
