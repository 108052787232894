import React, { Component } from 'react';
import './style.css'
import debounce from 'debounce';

export default class Editor extends Component {
  state = { config: false, id: 'oa' + (new Date()).valueOf().toString() }
  constructor() {
    super()
    // Immediate replace doesn't work.
    // Give it more time for element to get rendered properly
    this.confTimeout = setTimeout(() => {
      try {
        var options = {
          height: this.props.config ? this.props.config.height || 600 : 600,
          on: {
            contentDom: function () {
              var doc = this.document,
                head = doc.getHead();

              // Via <link> tag.
              doc.createElement('link', {
                attributes: {
                  rel: 'stylesheet',
                  type: 'text/css',
                  href: '/print.css',
                  media: 'print'
                }
              }).appendTo(head);

            }
          }
        }
        if (this.props.type === "simple-styling") {
          options.plugins = "htmlwriter,basicstyles,wysiwygarea,toolbar"
          options.height = 50
          options.removeButtons = "Subscript,Superscript"
        }
        window.CKEDITOR.replace(this.state.id, options);

      } catch (e) {
      }
      this.syncTimeout = setTimeout(() => {
        if (this.state.id && window.CKEDITOR.instances[this.state.id]) {
          let editor = window.CKEDITOR.instances[this.state.id];
          if (editor) {
            editor.on('blur', () => {
              this.sync();
            });
          }

        }
        this.loop = setInterval(() => {
          this.sync();
        }, 500);
      }, 1000);
    }, 200);
  }

  componentWillReceiveProps = debounce((e) => {
    if (this.state.id && window.CKEDITOR.instances[this.state.id] && e.name && e.name === this.props.name
      && e.content && e.content.length > 0 && e.content.indexOf('<p>') !== 0) {
      window.CKEDITOR.instances[this.state.id].setData(e.content);
    }
  }, 200)

  componentWillUnmount = () => {
    clearInterval(this.loop)
    clearTimeout(this.confTimeout)
    clearTimeout(this.syncTimeout)
    // No onChange event in CKEditor. Lets catch the content now
    this.sync();
  }

  sync = () => {
    if (this.state.id && window.CKEDITOR.instances[this.state.id]) {
      let e = {
        target: {
          name: this.props.name,
          value: window.CKEDITOR.instances[this.state.id].getData(),
        }
      }
      if (this.lastValue !== e.target.value) {
        this.onChange(e);
        this.lastValue = e.target.value
      }
    }
  }

  onChange = (event) => {
    event.target.name = this.props.name;
    this.props.onChange(event);

  }

  render() {
    var labelClass = '', divClass = '', containerClass = ''
    if (this.props.type === "simple-styling") {
      labelClass = "col-md-1 col-sm-1"
      divClass = "col-md-10 col-sm-10"
      containerClass = "form-group row"
    }
    return (
      <div className={containerClass} style={{ marginBottom: 15 }}>
        <label className={labelClass + ' control-label'}>
          {this.props.label}
          &nbsp;<span className="red">{this.props.required && '*'}</span>
        </label>
        <div className={divClass}>

          <textarea
            className="editor"
            name={this.state.id}
            id={this.state.id}
            rows={this.props.rows || "10"}
            cols={this.props.cols || "80"}
            value={this.props.content}
            onChange={e => console.log(e)}
          >
          </textarea>
          {this.props.warningText && <span style={{ color: 'red' }}>{this.props.warningText}</span>}

        </div>
      </div>
    );
  }
}
