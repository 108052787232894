import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import './styles.css';
import DateTimePicker from '../components/DateTimePicker';
import Letter from '../../../services/Letter';
import Comment from '../../../services/Comment';
import async from 'async';
import SwAlert from 'sweetalert-react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';
import Config from '../../../config';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import '../sweetalert.css';
import DispositionAction from  '../../../services/DispositionAction';

const LetterService = new Letter();
const CommentService = new Comment();
const DispositionActionService = new DispositionAction();

export default class LetterReadDisposition extends Component {
  state = {
    loading: false,
    currentDeadline: null,
    currentDeadlineChanged: false,
    dispositions: [],
    actionMap: {},
  }
  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      DispositionActionService.getAll()
      .then((result) => {
        var obj = {}
        for ( let i in result.data ) {
          let codeName = result.data[i].codeName
          obj[codeName] = result.data[i].name
        }
        this.setState({ loading: false, actionMap: obj });
      })
    })
    this.load();
  }
  load = () => {
    this.setState({ loading: true }, () => {
      // TODO Determine whether the current user can comment on the disposition
      async.each(this.props.dispositions, (disposition, cb) => {
        let dispositionRecipientOrigin = null;
        let derivedPIC = false;
        for (let i in disposition.recipients) {
          if (disposition.recipients[i].split('|')[0] === this.props.currentUser.id ||
            disposition.recipients[i].split('|')[0] === this.props.currentUser.organization.id ||
            disposition.recipients[i].split('|')[0] === this.props.currentUser.pltId ||
            disposition.recipients[i].split('|')[0] === this.props.currentUser.delgatedId) {
            dispositionRecipientOrigin = disposition.id
          }
        }
        if (disposition.groupPIC === this.props.currentUser.id ||
          disposition.groupPIC === this.props.currentUser.organization.id ||
          disposition.groupPIC === this.props.currentUser.pltId ||
          disposition.groupPIC === this.props.currentUser.delgatedId) {
          derivedPIC = true;
        }
        this.props.setDispositionRecipientOrigin(dispositionRecipientOrigin, derivedPIC);
        CommentService.getList({ page: 1, limit: 999, itemId: disposition.id })
          .then((comments) => {
            let obj = {}
            disposition.comments = comments;
            for (let i in comments) {
              if (comments[i].isUnread) {
                obj[disposition.id + '_expanded'] = true;
                this.setState(obj);
                this.setReadStatus(disposition);
              }
            }
            if (disposition.isUnread) {
              obj[disposition.id + '_expanded'] = true;
              this.setState(obj);
              this.setReadStatus(disposition);
            }
            cb();
          })
          .catch((err) => {
            cb(err);
          });
      }, (err) => {
        if (err) {
          this.setState({ loading: false, dispositions: [] });
          return;
        }
        this.setState({ loading: false, dispositions: this.props.dispositions });
      });
    });
  }
  inputEventHandler = (event) => {
    console.log('inputEventHandler');
    console.log(event.target.name);
    console.log(event.target.value);
    console.log(event.target.raw);
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    if (event.target.raw) {
      obj[event.target.name] = {}
      obj[event.target.name].str = val;
      obj[event.target.name].raw = event.target.raw;
    } else {
      obj[event.target.name] = val;
    }
    console.log(obj);
    this.setState(obj);
    if (event.target.name === 'currentDeadline') {
      this.setState({ currentDeadlineChanged: true });
    }
  }
  sendConfirmation = () => {
    this.setState({ sendConfirmationPopup: true });
  }
  send = () => {
    if (this.state.recipients.raw.length < 1) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon pilih minimal satu penerima disposisi.'
      });
      return;
    }
    this.setState({ action: 'Mengirim...', loading: true, sendConfirmationPopup: false }, () => {
    });
  }
  comment = (disposition, action) => {
    var recipients = [disposition.originator];
    recipients.push(disposition.recipients[0].split('|')[0])
    var spinner = {}
    spinner[disposition.id + '_spinner'] = true;
    this.setState(spinner, () => {
      spinner[disposition.id + '_spinner'] = false;
      spinner[disposition.id + '_comment'] = '';
      let obj = {
        itemId: disposition.id,
        recipients: recipients,
        notes: this.state[disposition.id + '_comment'],
      }
      if (!obj.notes || (obj.notes && obj.notes.trim().length < 1)) {
        this.setState(spinner)
        return;
      }
      if (this.props.currentLetter.isCurrentUserAPelaksana) {
        obj.asPelaksana = this.props.currentLetter.isCurrentUserAPelaksana;
        obj.pelaksanaType = this.props.currentLetter.currentUserPelaksanaType;
      }
      CommentService.create(obj)
        .then(() => {
          if (!action || (action && action.length === 0)) {
            this.setState(spinner, () => {
              this.load();
            });
            return;
          }
          return this.props.setDispositionState(action)
        })
        .then(() => {
          this.setState(spinner)
        })
        .catch((err) => {
          console.log(err);
          this.setState(spinner);
        });
    });
  }
  setReadStatus = (item) => {
    LetterService.setReadStatus(item.id, 'DISPOSITION');
    // If there is comments, set read status for them
    if (item.comments && item.comments.data && item.comments.data.length > 0) {
      for (let i in item.comments.data) {
        if (item.comments.data[i].isUnread) {
          LetterService.setReadStatus(item.comments.data[i].id, 'COMMENT');
        }
      }
    }
  }
  setDeadline = (item) => {
    let dispositions = this.state.dispositions;
    for (let i in dispositions) {
      if (dispositions[i].letterId === item.letterId) {
        dispositions[i].loading = true;
      }
    }
    this.setState({ dispositions: dispositions }, () => {
      var payload = {
        letterId: item.letterId,
        deadline: this.state.currentDeadline,
        id: item.id,
      }
      LetterService.updateDisposition(payload)
        .then(() => {
          let dispositions = this.state.dispositions;
          for (let i in dispositions) {
            if (dispositions[i].letterId === item.letterId) {
              dispositions[i].loading = false;
            }
          }
          this.setState({ dispositions: dispositions, currentDeadlineChanged: false });
        })
        .catch((err) => {
          let dispositions = this.state.dispositions;
          for (let i in dispositions) {
            if (dispositions[i].letterId === item.letterId) {
              dispositions[i].loading = false;
            }
          }
          this.setState({ dispositions: dispositions, currentDeadlineChanged: false });
        });
    });
  }

  forwardDisposition = (id) => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin meneruskan disposisi ini ke atasan?',
    }, (ok) => {
      if (ok) {
        LetterService.forwardDisposition(id)
        .then(() => {
          swal({
            title: '',
            text: 'Disposisi berhasil diteruskan.'
          })
          window.browserHistory.push('/inbox/all');          
        })
        .catch((err) => {
          swal({
            title: '',
            text: 'Disposisi tidak berhasil diteruskan.'
          })
          console.log(err);
        });
      }
    })
  }

  render() {
    return (
      <div>
        <SwAlert
          show={this.state.sendConfirmationPopup}
          title="Kirim"
          text={'Apakah Anda yakin ingin mengirim disposisi ini?'}
          showCancelButton
          onConfirm={this.send}
          onCancel={() => { this.setState({ sendConfirmationPopup: false }) }}
        />
        {this.state.loading && <Spinner />}

        {!this.state.loading &&
          this.state.dispositions &&
          this.state.dispositions.length === 0 &&
          <div>
            <hr />
            <div
              style={{
                textAlign: 'center',
                marginTop: 50,
                paddingBottom: 15,
                margin: '0 auto'
              }}
            >
              Belum ada disposisi
          </div>
          </div>
        }

        {this.state.dispositions && this.state.dispositions.map((item, index) => {
          return <div className="box box-primary box-solid" style={{ marginTop: 15 }} key={index}>
            <div
              className="box-header with-border"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!this.state[item.id + '_expanded']) this.setReadStatus(item);
                let obj = {};
                obj[item.id + '_expanded'] = !this.state[item.id + '_expanded'];
                this.setState(obj);
              }}
            >
              <span style={{ fontWeight: 'bold' }}>
                Disposisi dari {item.originatorName} untuk {item.recipients[0].split('|')[1]}
                {item.statusIsClosed &&
                  <Fa.FaCheck size={18} style={{ marginLeft: 15 }} />
                }
              </span>
              <div className="box-tools pull-right pointer">
                {!this.state[item.id + '_expanded'] ?
                  <Fa.FaChevronDown size={18} />
                  :
                  <Fa.FaChevronUp size={18} />
                }
              </div>
            </div>
            <div className={'body-body ' + (!this.state[item.id + '_expanded'] && 'collapse')}>
              <div>
                <div className="row" style={{ padding: 15, paddingBottom: '0px' }}>
                  <div className="col-md-6">
                    <b>Aksi</b> : &nbsp;&nbsp;
                   {item.actions && item.actions.map((action, actionIndex) => {
                      return <span
                        className="alert alert-comment"
                        key={actionIndex}
                        style={{ padding: '5px 10px 5px 10px', marginRight: 10 }}>
                        {this.state.actionMap[action]}
                      </span>
                    })}
                    <p
                      style={{ paddingBottom: '0px' }}
                    >
                      <b>Tanggal</b> : {date.format(item.time, 'dddd, DD MMMM YYYY, HH:mm', { locale: dateLocaleId })}
                    </p>
                    <p
                      style={{ whiteSpace: 'pre' }}
                    >
                      <b>Catatan</b> :<br />{item.notes || '-'}
                    </p>
                  </div>
                  {!this.props.archiveView && !item.isCurrentUserAnAssistant &&
                    item.originator !== this.props.currentUser.id &&
                    item.originator !== this.props.currentUser.organization.id &&
                    item.originator !== this.props.currentUser.pltId &&
                    item.originator !== this.props.currentUser.delegatedId &&
                    <div className="col-md-6">
                      <div style={{ float: 'right', width: '100%' }}>
                        Tenggat waktu / prioritas:
              		  <DateTimePicker
                          name="currentDeadline"
                          value={item.deadline.indexOf('0001-01-01') > -1 ? this.state.currentDeadline : item.deadline}
                          onChange={this.inputEventHandler}
                          required={false}
                          labelWidth="0"
                          fieldWidth="12"
                        />
                        {item.loading && <div style={{ float: 'right' }}>
                          <Spinner height={50} inline={true} />
                        </div>}
                        {!item.loading && this.state.currentDeadlineChanged && <div style={{ float: 'right' }}>
                          {this.state.currentDeadline &&
                            <button onClick={() => { this.setState({ currentDeadline: null }); }} className="btn btn-default">Batal</button>
                          }
                          &nbsp;&nbsp;
                        {this.state.currentDeadline &&
                            <button onClick={() => { this.setDeadline(item); }} className="btn btn-primary">Simpan</button>
                          }
                        </div>}
                      </div>
                    </div>}
                </div>
                <div style={{ padding: 15, paddingTop: '0px', paddingBottom: '0px', marginTop: 17 }}>
                  {item.comments && item.comments.data && item.comments.data.map((comment, commentIndex) => {
                    return <div key={commentIndex} style={{ border: '1px solid lightgrey', fontSize: 14, marginBottom: 15 }}>
                      <div
                        style={{
                          display: 'inline-block',
                          width: '35%',
                          background: '#f9fafc',
                          padding: '5px 10px 5px 10px',
                          borderRight: '1px solid lightgrey',
                          borderBottom: '1px solid lightgrey'
                        }}
                      >
                        <img
                          src={Config.apiHost + "/api/v1/blob/unrestricted/" + comment.originatorPicture}
                          onError={(e) => { e.target.src = "/images/default-avatar.png" }}
                          alt=""
                          style={{
                            background: 'grey',
                            width: this.props.sidebarCollapse ? 30 : 40,
                            height: this.props.sidebarCollapse ? 30 : 40,
                            borderRadius: 50,
                            marginBottom: 15
                          }}
                        />
                        <br />
                        {comment.originatorName.split('|')[0]}<br />
                        {comment.originatorName.split('|')[1] && <span> {comment.originatorName.split('|')[1]}</span>}<br />
                        <span style={{ fontSize: 12 }}>{date.format(comment.timestamp, 'dddd, DD MMMM YYYY', { locale: dateLocaleId })}</span><br />
                        <span style={{ fontSize: 12 }}>{date.format(comment.timestamp, 'HH:MM', { locale: dateLocaleId })}</span>
                      </div>
                      <div style={{ display: 'inline-block', verticalAlign: 'top', padding: '5px 10px 5px 10px', width: '64%' }}>{comment.notes}</div>
                    </div>
                  })}
                </div>
                {item.statusLastModifier && item.statusLastModifier.split('|').length >= 2 &&
                  <div className="col-md-12" style={{ marginBottom: 15, textAlign: 'center' }}>
                    <span>
                      {item.statusIsClosed ?
                        'Ditutup oleh:' : 'Dibuka kembali oleh:'}
                      {item.statusLastModifier.split('|')[1]}</span>
                    {item.statusLastModifier.split('|')[2] &&
                      <span>, {item.statusLastModifier.split('|')[2]}</span>
                    }
                  </div>
                }
                {!this.props.archiveView && !item.statusIsClosed &&
                  <div className="col-md-12" style={{ marginBottom: 15, textAlign: 'center' }}>
                    {!item.isCurrentUserAnAssistant &&
                    <textarea
                      className="form-control"
                      value={this.state[item.id + '_comment']}
                      name={item.id + '_comment'}
                      onChange={(e) => {
                        let obj = {};
                        obj[item.id + '_comment'] = e.target.value; this.setState(obj);
                      }}
                      style={{ width: '100%' }}
                    />
                    }
                    {this.state[item.id + '_spinner'] && <Spinner />}
                    <div style={{ marginTop: 15 }}>
                      {!this.state[item.id + '_spinner'] && !item.isCurrentUserAnAssistant &&
                        <button className="btn btn-primary" onClick={() => { this.comment(item, null); }}>
                          <Fa.FaMailReply />&nbsp;Komentari
                        </button>
                      }
                      {!this.state[item.id + '_spinner'] && item.closable && !item.isCurrentUserAnAssistant &&
                        <button
                          className="btn btn-danger"
                          style={{ marginLeft: 15 }}
                          onClick={() => { this.comment(item, 'CLOSE'); }}
                        >
                          <Fa.FaMailReply />&nbsp;Komentari & Tutup
                        </button>
                      }
                      {!this.state[item.id + '_spinner'] && item.isCurrentUserAnAssistant === true &&
                        <button className="btn btn-primary" onClick={() => { this.forwardDisposition(item.id)}}>
                          <Fa.FaCheckSquareO />&nbsp;Teruskan ke atasan
                        </button>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        })}
      </div>
    );
  }
}
