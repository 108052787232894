import React, { Component } from 'react';
import {Bar,HorizontalBar} from 'react-chartjs-2';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../services/Letter';

const LetterService = new Letter();

export default class DasboardLeader extends Component {
    state = {
        sortLetter: "Daily",
        sortLetterType: "Daily",
        userFilter: "A",

        dispositionOut: 0,
        dispositionInProcess: 0,
        dispositionClosed: 0,
        letterWillBeApproved: 0,

        barDataLetterDay: {},
        barDataLetterWeek: {},
        barDataLetterMonth: {},
        barDataLetterYear: {},
        barDataLetterTypeDay: {},
        barDataLetterTypeWeek: {},    
        barDataLetterTypeMonth: {},          
        barDataLetterTypeYear: {},

        horizontalBarData: { 
            datasets:[], 
            labels:[] 
        },

        horizontalBarOptions: {
          responsive: true,
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
              }
            }]
          }
        },

        barOptions: {
            responsive: true,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            }
        }
        
    }

    componentDidMount = () => {
      LetterService.getDashboardAdminStats()
      .then((result) => {
        let obj = {
          dispositionOut: result['DISPOSITION_OUT'],
          dispositionInProcess: result['DISPOSITION_IN_PROCESS'],
          dispositionClosed: result['DISPOSITION_CLOSED'],
          letterWillBeApproved: result['LETTER_WILL_BE_APPROVED'],
          barDataLetterDay: {
            labels: ["Hari Ini"],
            datasets:[{
              label: "Dalam Proses",
              backgroundColor: "#FFC900",
              data: [result.letterInProcess.daily]
            }, {
              label: "Dalam Finalisasi",
              backgroundColor: "#00ACE4",
              data: [result.letterInFinalization.daily]
            }, {
              label: "Dikembalikan",
              backgroundColor: "#E80041",
              data: [result.letterReturned.daily]
            }, {
              label: "Terkirim",
              backgroundColor: "#00CE7E",
              data: [result.letterSent.daily]
            }]
          },
          barDataLetterWeek: {
            labels: ["Dalam Proses", "Dalam Finalisasi", "Dikembalikan", "Terkirim"],
            datasets: [{
              label: "Senin",
              backgroundColor: "#FFC900",
              data: [
                result.letterInProcess.weekly['Senin'],
                result.letterInFinalization.weekly['Senin'],
                result.letterReturned.weekly['Senin'],
                result.letterSent.weekly['Senin'],
              ],
            }, {
              label: "Selasa",
              backgroundColor: "#00ACE4",
              data: [
                result.letterInProcess.weekly['Selasa'],
                result.letterInFinalization.weekly['Selasa'],
                result.letterReturned.weekly['Selasa'],
                result.letterSent.weekly['Selasa'],
              ],
            }, {
              label: "Rabu",
              backgroundColor: "#E80041",
              data: [
                result.letterInProcess.weekly['Rabu'],
                result.letterInFinalization.weekly['Rabu'],
                result.letterReturned.weekly['Rabu'],
                result.letterSent.weekly['Rabu'],
              ],
            }, {
              label: "Kamis",
              backgroundColor: "#00CE7E",
              data: [
                result.letterInProcess.weekly['Kamis'],
                result.letterInFinalization.weekly['Kamis'],
                result.letterReturned.weekly['Kamis'],
                result.letterSent.weekly['Kamis'],
              ],
            }, {
              label: "Jumat",
              backgroundColor: "#152D43",
              data: [
                result.letterInProcess.weekly['Jumat'],
                result.letterInFinalization.weekly['Jumat'],
                result.letterReturned.weekly['Jumat'],
                result.letterSent.weekly['Jumat'],
              ],
            }],
          },
          barDataLetterMonth: {
            labels: ["Dalam Proses", "Dalam Finalisasi", "Dikembalikan", "Terkirim"],
            datasets: [{
              label: "Minggu ke-1",
              backgroundColor: "#FFC900",
              data: [
                result.letterInProcess.monthly['Minggu ke-1'],
                result.letterInFinalization.monthly['Minggu ke-1'],
                result.letterReturned.monthly['Minggu ke-1'],
                result.letterSent.monthly['Minggu ke-1'],
              ]
            }, {
              label: "Minggu ke-2",
              backgroundColor: "#00ACE4",
              data: [
                result.letterInProcess.monthly['Minggu ke-2'],
                result.letterInFinalization.monthly['Minggu ke-2'],
                result.letterReturned.monthly['Minggu ke-2'],
                result.letterSent.monthly['Minggu ke-2'],
              ]
            }, {
              label: "Minggu ke-3",
              backgroundColor: "#E80041",
              data: [
                result.letterInProcess.monthly['Minggu ke-3'],
                result.letterInFinalization.monthly['Minggu ke-3'],
                result.letterReturned.monthly['Minggu ke-3'],
                result.letterSent.monthly['Minggu ke-3'],
              ]
            }, {
              label: "Minggu ke-4",
              backgroundColor: "#00CE7E",
              data: [
                result.letterInProcess.monthly['Minggu ke-4'],
                result.letterInFinalization.monthly['Minggu ke-4'],
                result.letterReturned.monthly['Minggu ke-4'],
                result.letterSent.monthly['Minggu ke-4'],
              ]
            }, {
              label: "Minggu ke-5",
              backgroundColor: "#152D43",
              data: [
                result.letterInProcess.monthly['Minggu ke-5'],
                result.letterInFinalization.monthly['Minggu ke-5'],
                result.letterReturned.monthly['Minggu ke-5'],
                result.letterSent.monthly['Minggu ke-5'],
              ]
            }],
        },
          
        barDataLetterYear: {
            labels: ["Dalam Proses", "Dalam Finalisasi", "Dikembalikan", "Terkirim"],
            datasets: [{
              label: "Januari",
              backgroundColor: "#FFC900",
              data: [
                result.letterInProcess.yearly['Januari'],
                result.letterInFinalization.yearly['Januari'],
                result.letterReturned.yearly['Januari'],
                result.letterSent.yearly['Januari'],
              ]
            }, {
              label: "Februari",
              backgroundColor: "#00ACE4",
              data: [
                result.letterInProcess.yearly['Februari'],
                result.letterInFinalization.yearly['Februari'],
                result.letterReturned.yearly['Februari'],
                result.letterSent.yearly['Februari'],
              ]
            }, {
              label: "Maret",
              backgroundColor: "#E80041",
              data: [
                result.letterInProcess.yearly['Maret'],
                result.letterInFinalization.yearly['Maret'],
                result.letterReturned.yearly['Maret'],
                result.letterSent.yearly['Maret'],
              ]
            }, {
              label: "April",
              backgroundColor: "#00CE7E",
              data: [
                result.letterInProcess.yearly['April'],
                result.letterInFinalization.yearly['April'],
                result.letterReturned.yearly['April'],
                result.letterSent.yearly['April'],
              ]
            }, {
              label: "Mei",
              backgroundColor: "#152D43",
              data: [
                result.letterInProcess.yearly['Mei'],
                result.letterInFinalization.yearly['Mei'],
                result.letterReturned.yearly['Mei'],
                result.letterSent.yearly['Mei'],
              ]
            }, {
              label: "Juni",
              backgroundColor: "#FF00E5",
              data: [
                result.letterInProcess.yearly['Juni'],
                result.letterInFinalization.yearly['Juni'],
                result.letterReturned.yearly['Juni'],
                result.letterSent.yearly['Juni'],
              ]
            }, {
              label: "Juli",
              backgroundColor: "#B000FF",
              data: [
                result.letterInProcess.yearly['Juli'],
                result.letterInFinalization.yearly['Juli'],
                result.letterReturned.yearly['Juli'],
                result.letterSent.yearly['Juli'],
              ]
            }, {
              label: "Agustus",
              backgroundColor: "#1A00FF",
              data: [
                result.letterInProcess.yearly['Agustus'],
                result.letterInFinalization.yearly['Agustus'],
                result.letterReturned.yearly['Agustus'],
                result.letterSent.yearly['Agustus'],
              ]
            }, {
              label: "September",
              backgroundColor: "#FF8100",
              data: [
                result.letterInProcess.yearly['September'],
                result.letterInFinalization.yearly['September'],
                result.letterReturned.yearly['September'],
                result.letterSent.yearly['September'],
              ]
            }, {
              label: "Oktober",
              backgroundColor: "#005A14",
              data: [
                result.letterInProcess.yearly['Oktober'],
                result.letterInFinalization.yearly['Oktober'],
                result.letterReturned.yearly['Oktober'],
                result.letterSent.yearly['Oktober'],
              ]
            }, {
              label: "November",
              backgroundColor: "#403F3A",
              data: [
                result.letterInProcess.yearly['November'],
                result.letterInFinalization.yearly['November'],
                result.letterReturned.yearly['November'],
                result.letterSent.yearly['November'],
              ]
            }, {
              label: "Desember",
              backgroundColor: "#2B5597",
              data: [
                result.letterInProcess.yearly['Desember'],
                result.letterInFinalization.yearly['Desember'],
                result.letterReturned.yearly['Desember'],
                result.letterSent.yearly['Desember'],
              ]
            }]
        },

        barDataLetterTypeDay: {
            labels: ["Hari Ini"],
            datasets:[{
              label: "Nota Dinas",
              backgroundColor: "#FFC900",
              data: [result.tndeNotaDinas.daily]
            }, {
              label: "Surat Tugas",
              backgroundColor: "#00ACE4",
              data: [result.tndeSuratTugas.daily]
            }, {
              label: "Surat Undangan",
              backgroundColor: "#E80041",
              data: [result.tndeUndangan.daily]
            }, {
              label: "Surat",
              backgroundColor: "#00CE7E",
              data: [result.tndeSurat.daily]
            }, {
              label: "Memo",
              backgroundColor: "#152D43",
              data: [result.tndeMemo.daily]
            }]
        },

        barDataLetterTypeWeek: {
            labels: ["Nota Dinas", "Surat Tugas", "Surat Undangan", "Surat", "Memo"],
            datasets: [{
              label: "Senin",
              backgroundColor: "#FFC900",
              data: [
                result.tndeNotaDinas.weekly['Senin'],
                result.tndeSuratTugas.weekly['Senin'],
                result.tndeUndangan.weekly['Senin'],
                result.tndeSurat.weekly['Senin'],
                result.tndeMemo.weekly['Senin'],
              ],
            }, {
              label: "Selasa",
              backgroundColor: "#00ACE4",
              data: [
                result.tndeNotaDinas.weekly['Selasa'],
                result.tndeSuratTugas.weekly['Selasa'],
                result.tndeUndangan.weekly['Selasa'],
                result.tndeSurat.weekly['Selasa'],
                result.tndeMemo.weekly['Selasa'],
              ],
            }, {
              label: "Rabu",
              backgroundColor: "#E80041",
              data: [
                result.tndeNotaDinas.weekly['Rabu'],
                result.tndeSuratTugas.weekly['Rabu'],
                result.tndeUndangan.weekly['Rabu'],
                result.tndeSurat.weekly['Rabu'],
                result.tndeMemo.weekly['Rabu'],
              ],
            }, {
              label: "Kamis",
              backgroundColor: "#00CE7E",
              data: [
                result.tndeNotaDinas.weekly['Kamis'],
                result.tndeSuratTugas.weekly['Kamis'],
                result.tndeUndangan.weekly['Kamis'],
                result.tndeSurat.weekly['Kamis'],
                result.tndeMemo.weekly['Kamis'],
              ],
            }, {
              label: "Jumat",
              backgroundColor: "#152D43",
              data: [
                result.tndeNotaDinas.weekly['Jumat'],
                result.tndeSuratTugas.weekly['Jumat'],
                result.tndeUndangan.weekly['Jumat'],
                result.tndeSurat.weekly['Jumat'],
                result.tndeMemo.weekly['Jumat'],
              ],
            }],
        },
    
        barDataLetterTypeMonth: {
            labels: ["Nota Dinas", "Surat Tugas", "Surat Undangan", "Surat", "Memo"],
            datasets: [{
              label: "Minggu ke-1",
              backgroundColor: "#FFC900",
              data: [
                result.tndeNotaDinas.monthly['Minggu ke-1'],
                result.tndeSuratTugas.monthly['Minggu ke-1'],
                result.tndeUndangan.monthly['Minggu ke-1'],
                result.tndeSurat.monthly['Minggu ke-1'],
                result.tndeMemo.monthly['Minggu ke-1'],
              ],
            }, {
              label: "Minggu ke-2",
              backgroundColor: "#00ACE4",
              data: [
                result.tndeNotaDinas.monthly['Minggu ke-2'],
                result.tndeSuratTugas.monthly['Minggu ke-2'],
                result.tndeUndangan.monthly['Minggu ke-2'],
                result.tndeSurat.monthly['Minggu ke-2'],
                result.tndeMemo.monthly['Minggu ke-2'],
              ],
            }, {
              label: "Minggu ke-3",
              backgroundColor: "#E80041",
              data: [
                result.tndeNotaDinas.monthly['Minggu ke-3'],
                result.tndeSuratTugas.monthly['Minggu ke-3'],
                result.tndeUndangan.monthly['Minggu ke-3'],
                result.tndeSurat.monthly['Minggu ke-3'],
                result.tndeMemo.monthly['Minggu ke-3'],
              ],
            }, {
              label: "Minggu ke-4",
              backgroundColor: "#00CE7E",
              data: [
                result.tndeNotaDinas.monthly['Minggu ke-4'],
                result.tndeSuratTugas.monthly['Minggu ke-4'],
                result.tndeUndangan.monthly['Minggu ke-4'],
                result.tndeSurat.monthly['Minggu ke-4'],
                result.tndeMemo.monthly['Minggu ke-4'],
              ],
            }, {
              label: "Minggu ke-5",
              backgroundColor: "#152D43",
              data: [
                result.tndeNotaDinas.monthly['Minggu ke-5'],
                result.tndeSuratTugas.monthly['Minggu ke-5'],
                result.tndeUndangan.monthly['Minggu ke-5'],
                result.tndeSurat.monthly['Minggu ke-5'],
                result.tndeMemo.monthly['Minggu ke-5'],
              ],
            }],
        },
          
        barDataLetterTypeYear: {
            labels: ["Nota Dinas", "Surat Tugas", "Surat Undangan", "Surat", "Memo"],
            datasets: [{
              label: "Januari",
              backgroundColor: "#FFC900",
              data: [
                result.tndeNotaDinas.yearly['Januari'],
                result.tndeSuratTugas.yearly['Januari'],
                result.tndeUndangan.yearly['Januari'],
                result.tndeSurat.yearly['Januari'],
                result.tndeMemo.yearly['Januari'],
              ]
            }, {
              label: "Februari",
              backgroundColor: "#00ACE4",
              data: [
                result.tndeNotaDinas.yearly['Februari'],
                result.tndeSuratTugas.yearly['Februari'],
                result.tndeUndangan.yearly['Februari'],
                result.tndeSurat.yearly['Februari'],
                result.tndeMemo.yearly['Februari'],
              ]
            }, {
              label: "Maret",
              backgroundColor: "#E80041",
              data: [
                result.tndeNotaDinas.yearly['Maret'],
                result.tndeSuratTugas.yearly['Maret'],
                result.tndeUndangan.yearly['Maret'],
                result.tndeSurat.yearly['Maret'],
                result.tndeMemo.yearly['Maret'],
              ]
            }, {
              label: "April",
              backgroundColor: "#00CE7E",
              data: [
                result.tndeNotaDinas.yearly['April'],
                result.tndeSuratTugas.yearly['April'],
                result.tndeUndangan.yearly['April'],
                result.tndeSurat.yearly['April'],
                result.tndeMemo.yearly['April'],
              ]
            }, {
              label: "Mei",
              backgroundColor: "#152D43",
              data: [
                result.tndeNotaDinas.yearly['Mei'],
                result.tndeSuratTugas.yearly['Mei'],
                result.tndeUndangan.yearly['Mei'],
                result.tndeSurat.yearly['Mei'],
                result.tndeMemo.yearly['Mei'],
              ]
            }, {
              label: "Juni",
              backgroundColor: "#FF00E5",
              data: [
                result.tndeNotaDinas.yearly['Juni'],
                result.tndeSuratTugas.yearly['Juni'],
                result.tndeUndangan.yearly['Juni'],
                result.tndeSurat.yearly['Juni'],
                result.tndeMemo.yearly['Juni'],
              ]
            }, {
              label: "Juli",
              backgroundColor: "#B000FF",
              data: [
                result.tndeNotaDinas.yearly['Juli'],
                result.tndeSuratTugas.yearly['Juli'],
                result.tndeUndangan.yearly['Juli'],
                result.tndeSurat.yearly['Juli'],
                result.tndeMemo.yearly['Juli'],
              ]
            }, {
              label: "Agustus",
              backgroundColor: "#1A00FF",
              data: [
                result.tndeNotaDinas.yearly['Agustus'],
                result.tndeSuratTugas.yearly['Agustus'],
                result.tndeUndangan.yearly['Agustus'],
                result.tndeSurat.yearly['Agustus'],
                result.tndeMemo.yearly['Agustus'],
              ]
            }, {
              label: "September",
              backgroundColor: "#FF8100",
              data: [
                result.tndeNotaDinas.yearly['September'],
                result.tndeSuratTugas.yearly['September'],
                result.tndeUndangan.yearly['September'],
                result.tndeSurat.yearly['September'],
                result.tndeMemo.yearly['September'],
              ]
            }, {
              label: "Oktober",
              backgroundColor: "#005A14",
              data: [
                result.tndeNotaDinas.yearly['Oktober'],
                result.tndeSuratTugas.yearly['Oktober'],
                result.tndeUndangan.yearly['Oktober'],
                result.tndeSurat.yearly['Oktober'],
                result.tndeMemo.yearly['Oktober'],
              ]
            }, {
              label: "November",
              backgroundColor: "#403F3A",
              data: [
                result.tndeNotaDinas.yearly['November'],
                result.tndeSuratTugas.yearly['November'],
                result.tndeUndangan.yearly['November'],
                result.tndeSurat.yearly['November'],
                result.tndeMemo.yearly['November'],
              ]
            }, {
              label: "Desember",
              backgroundColor: "#2B5597",
              data: [
                result.tndeNotaDinas.yearly['Desember'],
                result.tndeSuratTugas.yearly['Desember'],
                result.tndeUndangan.yearly['Desember'],
                result.tndeSurat.yearly['Desember'],
                result.tndeMemo.yearly['Desember'],
              ]
            }]
          },
        }
        this.setState(obj);
      })
      .catch((err) => {
        console.log(err)
      })
    }
    
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="small-box bg-red pointer">
                            <div className="inner" style={{ textAlign: 'center' }}>
                                <h5>Disposisi Keluar</h5>
                                <h3>{this.state.dispositionOut}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="small-box bg-yellow pointer">
                            <div className="inner" style={{textAlign: 'center', color: 'black' }}>
                                <h5>Disposisi Dalam Proses</h5>
                                <h3>{this.state.dispositionInProcess}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="small-box bg-green pointer">
                            <div className="inner" style={{ textAlign: 'center'   }}>
                                <h5>Disposisi Selesai</h5>
                                <h3>{this.state.dispositionClosed}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="small-box bg-aqua pointer">
                            <div className="inner" style={{ textAlign: 'center' }}>
                                <h5>Surat Akan Disetujui</h5>
                                <h3>{this.state.letterWillBeApproved}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="box box-success">
                            <div className="box-header box-header-dashboard">
                                <div className="row">
                                    <div className="col-md-9">
                                        <Fa.FaBarChart /> Status Surat Keluar 
                                    </div>
                                    <div className="col-md-3">
                                        <select 
                                        name="sort" 
                                        className="form-control"
                                        placeholder="Sort"
                                        value={this.state.sortLetter}
                                        onChange={(e) => {
                                            this.setState({sortLetter: e.target.value})
                                        }}
                                        >
                                        <option value="Daily">Hari ini</option>
                                        <option value="Weekly">Minggu ini</option>
                                        <option value="Monthly">Bulan ini</option>
                                        <option value="Yearly">Tahun Ini</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.sortLetter === "Daily" ? 
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterDay} />
                                : this.state.sortLetter === "Weekly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterWeek} />
                                : this.state.sortLetter === "Monthly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterMonth} />
                                : this.state.sortLetter === "Yearly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterYear} />
                                : <p>No Data</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="box box-success">
                            <div className="box-header box-header-dashboard">
                                <div className="row">
                                    <div className="col-md-9">
                                        <Fa.FaBarChart /> Status Surat Keluar Per Jenis Surat
                                    </div>
                                    <div className="col-md-3">
                                        <select
                                        name="otherSort"
                                        className="form-control"
                                        placeholder="Sort"
                                        value={this.state.sortLetterType}
                                        onChange={(e) => {
                                            this.setState({sortLetterType: e.target.value})
                                        }}
                                        >
                                        <option value="Daily">Hari ini</option>
                                        <option value="Weekly">Minggu ini</option>
                                        <option value="Monthly">Bulan ini</option>
                                        <option value="Yearly">Tahun Ini</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.sortLetterType === "Daily" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeDay} />
                                : this.state.sortLetterType === "Weekly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeWeek} />
                                : this.state.sortLetterType === "Monthly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeMonth} />
                                : this.state.sortLetterType === "Yearly" ?
                                <Bar type='bar' options={this.state.barOptions} data={this.state.barDataLetterTypeYear} />
                                : <p>No Data</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {false && //Hide this feature
                  <div className="row">
                      <div className="col-md-6">
                          <div className="box box-success">
                              <div className="box-header box-header-dashboard">
                                  <div className="row">
                                      <div className="col-md-9">
                                          <Fa.FaBarChart /> Surat Keluar Tiap Pengguna
                                      </div>
                                      <div className="col-md-3">
                                          <select 
                                          name="userFilter"
                                          className="form-control" 
                                          placeholder="Filter"
                                          value={this.state.userFilter}
                                          onChange={(e) =>
                                              this.setState({userFilter: e.target.value})
                                          }
                                          >
                                            <option value="A">Top 10</option>
                                            <option value="B">Top 20</option>
                                            <option value="C">Hari Ini</option>
                                            <option value="D">Minggu Ini</option>
                                            <option value="E">Bulan Ini</option>
                                            <option value="F">Tahun Ini</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                              <div className="box-body">
                                  <HorizontalBar type='horizontalBar' data={this.state.horizontalBarData} options={this.state.horizontalBarOptions} />
                              </div>
                          </div>
                      </div>
                  </div>
                }
            </div>
        )
    }
}