import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import Switch from 'react-switch';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import LetterHeader from '../../../services/LetterHeader';
import Blob from '../../../services/Blob';
import Config from '../../../config';
import Editor from '../../../components/Editor';

const LetterHeaderService = new LetterHeader();
const BlobService = new Blob();

export default class LetterHeaders extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    loading: false,
    mode: 'list',
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (window.store.getState().rootReducers.currentUser.role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      let opt = { page: this.state.page, limit: this.state.limit, search: this.state.searchString }
      LetterHeaderService.getAll(opt)
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data || [],
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  inputEventHandler = (event) => {
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    obj[event.target.name] = val;
    this.setState(obj);
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: page, limit: this.state.limit, search: this.state.searchString }
    LetterHeaderService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page + 1, limit: this.state.limit, search: this.state.searchString }
    LetterHeaderService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { page: this.state.page - 1, limit: this.state.limit, search: this.state.searchString }
    LetterHeaderService.getAll(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  back = () => {
    this.setState({
      mode: 'list',
      id: null,
      name: null,
      header: '',
      logo: null,
      isDefault: null,
      loading: false
    }, () => {
      this.reload();
    });
  }

  new = () => {
    this.setState({
      mode: 'form',
      id: null,
      name: null,
      header: '',
      logo: null,
      isDefault: null
    });
  }

  edit = (item) => {
    this.setState({
      mode: 'form',
      id: null,
      name: null,
      header: '',
      logo: null,
      isDefault: null
    }, () => {
      this.setState(item);
    });
  }

  uploadLogo = (e) => {
    this.setState({ logoUploadLoading: true });
    e.preventDefault();
    let reader = new FileReader();
    let logoFile = e.target.files[0];
    reader.onloadend = () => {
      console.log(logoFile);
    }
    reader.addEventListener('load', () => {
      BlobService.uploadUnrestricted(logoFile)
        .then((blobUuid) => {
          this.setState({ logo: blobUuid, logoUploadLoading: false });
        })
        .catch((err) => {
          this.setState({ logoUploadLoading: false });
          console.log(err);
        });
    });
    reader.readAsDataURL(logoFile);
  }

  reload = () => {
    this.page()
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({ searchString: '' }, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target) {
      console.log(e.target.value);
      this.setState({ searchString: e.target.value });
    }
  }

  isDefaultSwitched = (checked) => {
    this.setState({ isDefault: checked ? true : false });
  }

  save = () => {
    const payload = {
      id: this.state.id || null,
      name: this.state.name,
      header: this.state.header,
      logo: this.state.logo,
      isDefault: this.state.isDefault,
    }
    this.setState({ loading: true });
    if (payload.id) {
      LetterHeaderService.update(payload.id, payload)
        .then(() => {
          this.back();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    } else {
      LetterHeaderService.create(payload)
        .then(() => {
          this.back();
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  }

  removeLogo = () => {
    this.setState({logo:''});
  }

  delete = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus templat kop surat ini?',
    }, (ok) => {
      if (ok) {
        LetterHeaderService.delete(this.state.id)
          .then(() => {
            this.back();
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });

  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.state.mode === 'list' &&
            <div style={{ float: 'right' }}>
              <span className="btn btn-default" onClick={this.new}>
                <Fa.FaPlus /> Tambah kop surat baru
            </span>
            </div>
          }
          <h4>
            {this.state.mode !== 'list' && <Fa.FaChevronLeft style={{ marginRight: 15, cursor: 'pointer' }} onClick={this.back} />}
            {this.state.mode === 'list' ? 'Templat Kop Surat' :
              (this.state.id ? 'Sunting Templat Kop Surat' : 'Buat Templat Kop Surat Baru')}
          </h4>
          {this.state.mode === 'form' &&
            <div className="box box-success">
              <div className="box-body" style={{ padding: 0 }}>
                <div className="row" style={{ margin: 15 }}>
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Nama templat</label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="name"
                          placeholder="Nama templat"
                          value={this.state.name}
                          onChange={(e) => { this.setState({ name: e.target.value }) }}
                        />
                        <small id="nameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Bawaan utama</label>
                      <div className="col-md-9" >
                        <Switch
                          onChange={this.isDefaultSwitched}
                          checked={this.state.isDefault}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Logo</label>
                      <div className="col-md-9" >
                        <input
                          disabled={this.state.loading}
                          id="uploadLogo"
                          type="file"
                          onChange={this.uploadLogo}
                          multiple={false} style={{ display: 'none', fontSize: 10 }}
                        />
                        {this.state.logoUploadLoading && <Spinner inline={true} />}
                        {!this.state.logoUploadLoading && <div>
                          <button className="btn btn-default" onClick={() => { document.getElementById("uploadLogo").click() }}>Unggah logo</button>&nbsp;
                          <button className="btn btn-warning" onClick={this.removeLogo}>Hapus logo</button>&nbsp;
                        <div>
                            {this.state.logo && this.state.logo.length > 0 &&
                            <img src={Config.apiHost + "/api/v1/blob/unrestricted/" + this.state.logo} onError={(e) => { e.target.src = Config.variant.images.letterLogo }} alt="" style={{ background: 'grey', width: 100, height: 100 }} />
                            }
                          </div>
                        </div>}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-2 col-sm-2">Kop Surat</label>
                      <div className="col-md-9">
                        {!this.state.loading &&
                          <Editor
                            name="header"
                            id="header"
                            required={false}
                            content={this.state.header}
                            onChange={this.inputEventHandler}
                            config={{
                              height: 600,
                            }}
                          />
                        }
                      </div>
                    </div>
                    {this.state.loading ?
                      <Spinner /> :
                      <div className="form-group row">
                        <div className="col-md-2" >
                        </div>
                        <div className="col-md-9" >
                          {this.state.id &&
                            <button className="btn btn-danger" onClick={this.delete}>Hapus</button>
                          }
                          &nbsp;&nbsp;
                        <button className="btn btn-primary" onClick={this.save}>Simpan templat</button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {this.state.mode === 'list' &&
            <div className="box box-success">
              <div className="box-body" style={{ padding: 0 }}>
                <div className="row" style={{ padding: 15 }}>
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="input-group input-group-sm">
                      <span className="input-group-btn">
                        <span className="btn btn-success">
                          <Fa.FaRefresh />
                        </span>
                      </span>
                      <input
                        className="form-control" type="text"
                        onChange={this.handleSearchString}
                        onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                        value={this.state.searchString}
                      />
                      {this.state.searchString && this.state.searchString.length > 0 &&
                        <span className="input-group-btn">
                          <span className="btn btn-default" onClick={this.dismissSearch}>
                            <Fa.FaClose />
                          </span>
                        </span>
                      }
                      <span className="input-group-btn">
                        <span className="btn btn-default" onClick={this.search}>
                          <Fa.FaSearch />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div style={{ float: 'right' }}>
                      <Pagination
                        disabled={this.state.loading}
                        page={this.state.page}
                        limit={this.state.limit}
                        total={this.state.total}
                        prev={this.prev} next={this.next}
                        toPage={this.page} />
                    </div>
                  </div>
                  <hr />
                </div>
                {this.state.loading ? <Spinner /> :
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Nama templat</th>
                        <th>Utama</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <tr key={index} onClick={() => { this.edit(this.state.data[item]) }} style={{ cursor: 'pointer' }}>
                          <td>{this.state.data[item].name}</td>
                          <td>{this.state.data[item].isDefault && <Fa.FaCheck color={'green'} />}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                }
                {!(this.state.total > 0) && !this.state.loading &&
                  <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                    <br />
                    <h4>Tidak ada data</h4>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
