import React, { Component } from 'react';
import { connect } from "react-redux";
import * as Fa from 'react-icons/lib/fa';
import FullCalendar from 'fullcalendar-reactwrapper-cdl';
import 'fullcalendar-reactwrapper-cdl/dist/css/fullcalendar.min.css';
import Pagination from '../../../components/Pagination';
import Pie from 'react-chartjs-2';
import Spinner from '../../../components/Spinner';
import Letter from '../../../services/Letter';
import Auth from '../../../services/Auth';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import debounce from 'debounce';
import stripTags from '../../../utils';
import Constants from '../../../consts';

const LetterService = new Letter();
const AuthService = new Auth();

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser,
    sidebarCollapse: state.rootReducers.sidebarCollapse,
    stats: state.rootReducers.stats,
    'location': state.router.location
  }
}

const dispatcher = dispatch => {
  return {
    letterStats: stats => dispatch(window.actions.letterStats(stats)),
  }
}



class DashboardUser extends Component {
  state = {
    actionMap: Constants.actionMap,
    recapPieData: {},

    invitationDataFirstTimeLoad: false,
    invitationData: [],
    invitationPage: 1,
    invitationLimit: 10,
    invitationTotal: 0,
    invitationLoading: false,
    invitationCurrentDate: new Date(),
    invitationStartDate: ((new Date((new Date()).setHours(0, 0, 0, 0)))).toISOString(),
    invitationEndDate: (new Date((new Date()).setHours(23, 59, 0, 0))).toISOString(),

    calendarDataFirstTimeLoad: false,
    calendarData: [],
    calendarPage: 1,
    calendarLimit: 10,
    calendarTotal: 0,
    calendarLoading: true,
    calendarStartDate: (new Date((new Date((new Date()).setHours(0, 0, 0, 0))).setDate(1))).toISOString(),
    calendarEndDate: (new Date((new Date((new Date((new Date()).setMonth((new Date()).getMonth() + 1))).setDate(0))).setHours(23, 59, 0, 0))).toISOString(),
    calendarEvents: [
    ],

    unreadData: [],
    unreadPage: 1,
    unreadLimit: 10,
    unreadTotal: 0,
    unreadLoading: false,

    dispositionData: [],
    dispositionPage: 1,
    dispositionLimit: 10,
    dispositionTotal: 0,
    dispositionLoading: false,

    calendarEventRenderCount: 0,
    
  }
  componentDidMount = () => {
    // load recap data
    let token = window.localStorage.getItem('access_token');
    if (!token || (token && token.length < 1)) {
      return;
    }
    setInterval(() => {
      let obj = {
        recapPieData: {
          datasets: [
            {
              data: [
                this.props.stats["INBOX_TOTAL"],
                this.props.stats["DISPOSITION_TOTAL"],
                this.props.stats["NEED_APPROVAL"],
                this.props.stats["DELEGATED_TOTAL"],
              ],
              backgroundColor: [
                '#00c0ef',
                '#dd4b39',
                '#00a65a',
                '#f39c12',
              ],
            }
          ],
          labels: [],
        }
      }
      let keys = ["Kotak masuk", "Disposisi", "Perlu persetujuan", "Delegasi"];
      for (var i in keys) {
        obj.recapPieData.labels.push(keys[i]);
      }
      this.setState(obj);
    }, 500)
    

    // Undangan    filterValue: '',

    this.invitationPage(1)
    // Inbox
    this.unreadReload()
    // Disposition
    this.dispositionReload()
  } 

  invitationNext = () => { }
  invitationPrev = () => { }
  invitationPage = () => { }

  unreadReload = () => {
    this.setState({ unreadLoading: true }, () => {
      LetterService.getList({ letterType: 'all', page: 1, limit: 10, box: "INBOX", unread: true })
        .then((result) => {
          this.setState({
            unreadPage: result.page,
            unreadLimit: result.limit,
            unreadTotal: result.total,
            unreadData: result.data || [],
            unreadLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            unreadData: [],
            unreadLoading: false,
          });
        });
    });
  }

  dispositionReload = () => {
    this.setState({ dispositionLoading: true }, () => {
      LetterService.getDispositions({ page: 1, limit: 10, unread: true })
        .then((result) => {
          this.setState({
            dispositionPage: result.page,
            dispositionLimit: result.limit,
            dispositionTotal: result.total,
            dispositionData: result.data || [],
            dispositionLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            dispositionData: [],
            dispositionLoading: false,
          });
        });
    });
  }

  read = (id) => {
    window.browserHistory.push('/read/' + id);
  }

  calendarEventClick = (data) => {
    let date = data.start._d;
    let invitationStartDate = ((new Date((date).setHours(0, 0, 0, 0))));
    let invitationEndDate = (new Date((date).setHours(23, 59, 0, 0)));
    this.setState({ invitationCurrentDate: date, invitationStartDate: invitationStartDate.toISOString(), invitationEndDate: invitationEndDate.toISOString() }, () => {
      this.invitationPage(1)
    });
  }

  
  calendarEventRender = (data) => {
    if (!this.state.calendarDataFirstTimeLoad) {
      // First time load
      this.setState({ calendarDataFirstTimeLoad: true });
    } else {
      let defaultDate = new Date(data.dateProfile.date.toISOString());
      this.setState({ calendarStartDate: data.start._d.toISOString(), calendarEndDate: data.end._d.toISOString(), calendarDefaultDate: defaultDate, calendarLoading: true }, () => {
        this.setState({ calendarDataFirstTimeLoad: false });
        this.calendarReload();
      });
    }
  }

  calendarReload = () => {
    this.setState({ searchString: '', calendarLoading: true });
    let opt = { letterType: 'TNDE_UNDANGAN', page: 1, limit: 99, box: "INBOX", invitationStartDate: this.state.calendarStartDate, invitationEndDate: this.state.calendarEndDate }
    var result;
    LetterService.getList(opt)
      .then((res) => {
        result = res;
        opt.invitationDisposition = true;
        return LetterService.getList(opt);
      })
      .then((res) => {
        result.total += res.total;
        result.data = result.data.concat(res.data);
        this.setState({
          calendarPage: result.page,
          calendarLimit: result.limit,
          calendarTotal: result.total,
          calendarData: result.data || [],
        }, () => {

          let exists = [];
          let events = [];
          let eventCount = {}
          for (let i in this.state.calendarData) {
            if (exists.indexOf(this.state.calendarData[i].id) > -1) {
              continue;
            } else {
              exists.push(this.state.calendarData[i].id);
            }
            let startDate = new Date(this.state.calendarData[i].invitationStartDateTime);
            let month = (startDate.getMonth() + 1) + '';
            if (month.length === 1) month = '0' + month;
            let day = startDate.getDate() + '';
            if (day.length === 1) day = '0' + day;
            let startDateStr = `${startDate.getFullYear()}-${month}-${day}`;
            if (events.indexOf(startDateStr) < 0) {
              events.push(startDateStr);
            }
            eventCount[startDateStr] = eventCount[startDateStr] || 0
            eventCount[startDateStr] += 1
          }
          let calendarEvents = this.state.calendarEvents;
          calendarEvents.splice(0, calendarEvents.length);
          for (let i in events) {
            calendarEvents.push({
              title: eventCount[events[i]],
              editable: false,
              start: events[i],
            });
          }
          this.setState({ calendarEvents: calendarEvents }, () => {
            this.setState({ calendarLoading: false })
          });
        });
      })
      .catch((err) => {
        this.setState({
          calendarData: [],
          calendarLoading: false,
        });
      });
  }

  invitationPage = (page) => {
    this.setState({ invitationLoading: true, searchString: '' });
    let opt = { letterType: "TNDE_UNDANGAN", page: page, limit: this.state.limit, box: "INBOX", invitationStartDate: this.state.invitationStartDate, invitationEndDate: this.state.invitationEndDate }
    var result;
    LetterService.getList(opt)
      .then((res) => {
        result = res;
        opt.invitationDisposition = true;
        return LetterService.getList(opt);
      })
      .then((res) => {
        result.total += res.total;
        result.data = result.data.concat(res.data);
        this.setState({
          invitationPage: result.page,
          invitationLimit: result.limit,
          invitationTotal: result.total,
          invitationData: result.data || [],
          invitationLoading: false,
        }, () => {
          if (!this.state.calendarDataFirstTimeLoad) {
            this.calendarReload();
          }
        });
      })
      .catch((err) => {
        this.setState({
          invitationData: [],
          invitationLoading: false,
        });
      });
  }

  invitationNext = () => {
    this.setState({ invitationLoading: true, searchString: '' });
    let opt = { letterType: "TNDE_UNDANGAN", page: this.state.page + 1, limit: this.state.limit, box: "INBOX", invitationStartDate: this.state.invitationStartDate, invitationEndDate: this.state.invitationEndDate }
    var result;
    LetterService.getList(opt)
      .then((res) => {
        result = res;
        opt.invitationDisposition = true;
        return LetterService.getList(opt);
      })
      .then((res) => {
        result.total += res.total;
        result.data = result.data.concat(res.data);
        this.setState({
          invitationPage: result.page,
          invitationLimit: result.limit,
          invitationTotal: result.total,
          invitationData: result.data || [],
          invitationLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          invitationData: [],
          invitationLoading: false,
        });
      });
  }

  invitationPrev = () => {
    this.setState({ invitationLoading: true, searchString: '' });
    let opt = { letterType: "TNDE_UNDANGAN", page: this.state.page - 1, limit: this.state.limit, box: "INBOX", invitationStartDate: this.state.invitationStartDate, invitationEndDate: this.state.invitationEndDate }
    var result;
    LetterService.getList(opt)
      .then((res) => {
        result = res;
        opt.invitationDisposition = true;
        return LetterService.getList(opt);
      })
      .then((res) => {
        result.total += res.total;
        result.data = result.data.concat(res.data);
        this.setState({
          invitationPage: result.page,
          invitationLimit: result.limit,
          invitationTotal: result.total,
          invitationData: result.data || [],
          invitationLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          invitationData: [],
          invitationLoading: false,
        });
      });
  }

  render() {
    if (!AuthService.getToken()) {
      return (<div />)
    }
    var isSigner = false
    var signerName = ''

    return (
      <div className="">
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="small-box bg-aqua pointer" onClick={() => { window.browserHistory.push('/inbox/all') }}>
                  <div className="inner">
                    <h3>{this.props.stats["ALL_UNREADS"]}</h3>
                    <p>Surat Masuk</p>
                  </div>
                  <div className="icon">
                    <Fa.FaInbox color={'white'} />
                  </div>
                </div>
                <div className="small-box bg-red pointer" onClick={() => { window.browserHistory.push('/inbox/disposition') }}>
                  <div className="inner">
                    <h3>{this.props.stats["DISPOSITION"]}</h3>
                    <p>Disposisi Masuk</p>
                  </div>
                  <div className="icon">
                    <Fa.FaCheckSquareO color={'white'} />
                  </div>
                </div>
                <div className="small-box bg-green pointer" onClick={() => { window.browserHistory.push('/processing/need-approval') }}>
                  <div className="inner">
                    <h3>{this.props.stats["NEED_APPROVAL"]}</h3>
                    <p>Perlu Persetujuan</p>
                  </div>
                  <div className="icon">
                    <Fa.FaCheckCircleO color={'white'} />
                  </div>
                </div>
                <div className="small-box bg-yellow">
                  <div className="inner">
                    <h3>
                      {this.props.currentUser.isAssignedAsDelegation ?
                        <span>
                          Delegasi aktif
                      </span>
                        :
                        <span>
                          Delegasi tidak aktif
                      </span>
                      }
                    </h3>
                    <div>Sebagai delegasi untuk :
                      {this.props.currentUser.isAssignedAsDelegation ?
                        <div>
                          {this.props.currentUser.delegatedOrgName}<br />
                          {this.props.currentUser.delegatedPersonalName}
                        </div>
                        :
                        <div>Tidak ada delegasi</div>
                      }
                    </div>
                  </div>
                  <div className="icon">
                    <Fa.FaUser color={'white'} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box box-success">
                  <div className="box-header box-header-dashboard">
                    <Fa.FaBarChart /> Ringkasan Rekapitulasi Surat
                  </div>
                  <div className="box-body">
                    <Pie data={this.state.recapPieData} type={'pie'} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="box box-success">
                  <div className="box-header box-header-dashboard">
                    <Fa.FaCalendar /> Kalender Undangan
                  </div>
                  <div className="box-body calendar-container">
                    {!this.state.calendarLoading ?
                      <div className="fade-transition">
                        <FullCalendar id="dashboard-calendar"
                          header={{
                            left: 'prev,next todaymyCustomButton',
                            center: 'title',
                            right: '',
                          }}
                          locale="id"
                          editable={false}
                          eventLimit={true}
                          events={this.state.calendarEvents}
                          eventClick={this.calendarEventClick}
                          defaultDate={this.state.calendarDefaultDate}
                          eventAfterAllRender={debounce(this.calendarEventRender, 200, false)}
                        />
                      </div>
                      :
                      <Spinner />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box box-success">
                  <div className="box-header box-header-dashboard">
                    <Fa.FaListAlt /> Undangan&nbsp;
                              {date.format(this.state.invitationCurrentDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
                    <div style={{ float: 'right' }}>
                      <Pagination
                        disabled={this.state.invitationLoading}
                        page={this.state.initationPage}
                        limit={this.state.initationLimit}
                        total={this.state.initationTotal}
                        prev={this.invitationPrev} next={this.invitationNext}
                        toPage={this.invitationPage} />
                    </div>
                  </div>
                  {!this.state.invitationLoading ?
                    <div className="box-body">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Jam</th>
                            <th>Perihal</th>
                            <th>Tempat</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(this.state.invitationData).map((item, index) => {
                            return <tr key={index} onClick={() => { this.read(this.state.invitationData[item].id) }} style={{ cursor: 'pointer', fontWeight: this.state.invitationData[item].isUnread ? 'bold' : 'normal' }}>
                              <td>
                                {date.format(this.state.invitationData[item].invitationStartDateTime, 'HH:mm', { locale: dateLocaleId })}
                              </td>
                              <td>{stripTags(this.state.invitationData[item].subject)}</td>
                              <td>{this.state.invitationData[item].invitationVenue}</td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                      {!(this.state.invitationTotal > 0) && !this.state.invitationLoading &&
                        <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                          <span style={{ fontSize: 32 }}>
                            <Fa.FaInbox />
                          </span>
                          <br />
                          <h4>Belum ada undangan</h4>
                        </div>
                      }
                    </div>
                    : <Spinner />}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="box box-success">
                  <div className="box-header box-header-dashboard">
                    <Fa.FaInbox /> Surat Belum Dibaca
                    <div style={{ float: 'right' }}>
                      <Fa.FaRefresh style={{ cursor: 'pointer' }} onClick={this.unreadReload} />
                    </div>
                  </div>
                  <div className="box-body">
                    {this.state.unreadLoading ? <Spinner /> :
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>No. Surat</th>
                            <th>Dari</th>
                            <th>Perihal</th>
                            <th>Tanggal</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(this.state.unreadData).map((item, index) => {
                            if (this.state.unreadData[item].signers && this.state.unreadData[item].signers.length > 0 ) {
                              const index = this.state.unreadData[item].signers.length - 1  
                              if (index != null) {
                                  const data = this.state.unreadData[item].signers[index]
                                  if (data) {
                                    const result = data.split('|')
                                    if (result.length > 1 && result.length != null) {
                                      signerName = result[1]
                                      isSigner = true
                                    }
                                  }
                              }
                            }
                            return <tr key={index} onClick={() => { this.read(this.state.unreadData[item].id) }} style={{ cursor: 'pointer', fontWeight: this.state.unreadData[item].isUnread ? 'bold' : 'normal' }}>
                              <td>{this.state.unreadData[item].number}</td>
                              <td>
                                {this.state.unreadData[item].manualLetterSender}
                                {isSigner && signerName}
                              </td>
                              <td>{stripTags(this.state.unreadData[item].subject)}</td>
                              <td>
                                {date.format(this.state.unreadData[item].letterDate, 'DD MMMM YYYY', { locale: dateLocaleId })}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {this.state.unreadData[item].repliedstatus === 'In Progress' && <Fa.FaHourglass style={{ marginLeft: 5 }} />}
                                {this.state.unreadData[item].repliedstatus === 'Complete' && <Fa.FaCheck style={{ marginLeft: 5 }} />}
                                {this.state.unreadData[item].dispostatus === '1' && <Fa.FaShareAlt style={{ marginLeft: 5 }} />}
                              </td>
                            </tr>
                          })}
                        </tbody>
                      </table>
                    }
                    {!(this.state.unreadTotal > 0) && !this.state.unreadLoading &&
                      <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                        <span style={{ fontSize: 32 }}>
                          <Fa.FaInbox />
                        </span>
                        <br />
                        <h4>Tidak ada surat baru</h4>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="box box-success">
                  <div className="box-header box-header-dashboard">
                    <Fa.FaInbox /> Disposisi Belum Dibaca
                    <div style={{ float: 'right' }}>
                      <Fa.FaRefresh style={{ cursor: 'pointer' }} onClick={this.dispositionReload} />
                    </div>
                  </div>
                  <div className="box-body">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Dari</th>
                          <th>Perihal</th>
                          <th>Tanggal Disposisi</th>
                          <th>Nota Tindakan</th>
                          <th>Catatan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.state.dispositionData).map((item, index) => {
                          return <tr key={index} onClick={() => { this.read(this.state.dispositionData[item].letterId) }} style={{ cursor: 'pointer', fontWeight: this.state.dispositionData[item].isUnread ? 'bold' : 'normal' }}>
                            <td>{this.state.dispositionData[item].originatorName}</td>
                            <td>{stripTags(this.state.dispositionData[item].letterSubject)}</td>
                            <td>{date.format(this.state.dispositionData[item].time, 'DD MMMM YYYY', { locale: dateLocaleId })}</td>
                            {this.state.dispositionData[item].actions && this.state.dispositionData[item].actions.map((action, actionIndex) => {
                              return <td key={actionIndex}>{this.state.actionMap[action]}</td>
                            })}
                            <td>{this.state.dispositionData[item].notes}</td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                    {!(this.state.dispositionTotal > 0) && !this.state.dispositionLoading &&
                      <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                        <span style={{ fontSize: 32 }}>
                          <Fa.FaInbox />
                        </span>
                        <br />
                        <h4>Tidak ada disposisi baru</h4>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default connect(mapper, dispatcher)(DashboardUser);
