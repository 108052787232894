import axios from "axios";
import Config from "../config";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class LetterHeader {
  getAll = (opt) => {
    return new Promise((resolve, reject) => {
      opt = opt || {}
      opt.page = opt.page || 1;
      opt.limit = opt.limit || 10;
      let url = `${Config.apiHost}/api/v1/letter-headers?page=${opt.page}&limit=${opt.limit}`;
      if (opt.search && opt.search.length > 0) {
        url += `&search=${opt.search}`
      }
      axios.get(url, {
        headers: MiscService.generateHeaders()
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  get = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(Config.apiHost + "/api/v1/letter-header/" + id, {
        headers: MiscService.generateHeaders()
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  create = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/v1/letter-headers", 
        payload,
        {headers: MiscService.generateHeaders()}
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        ErrorService.handle(err);
        reject(err);
      })
    });
  }

  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      axios.put(Config.apiHost + "/api/v1/letter-header/" + id,
        payload,
        {headers: MiscService.generateHeaders()}
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }

  delete = (id) => {
    return new Promise((resolve, reject) => {
      axios.delete(Config.apiHost + "/api/v1/letter-header/" + id,
      {headers: MiscService.generateHeaders()}
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          ErrorService.handle(err);
          reject(err);
        })
    });
  }
}

export default LetterHeader;
