import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import DateTimePicker from '../../../components/DateTimePicker';
import LetterRead from '../../LetterRead';
import LetterReadDetail from '../../LetterReadDetail';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import '../styles.css';
import stripTags from '../../../../../utils'


const LetterService = new Letter();

export default class Outbox extends Component {
  state = {
    page : 1,
    limit : 10,
    total : 0,
    pagination : [],
    data : [],
    searchString : '',
    loading : false,
    box:'CHAIN_SENT',

    mode : 'list',
    currentLetter : {},
    dateFilterCollapsed : true,
    letterDateStart: null,
    letterDateEnd: null,
  }

  componentDidMount = () => {
    this.setState({loading:true}, () => {
      let opt = {box:this.state.box, letterType: this.props.type, page : 1, limit : 10, search : this.state.searchString}
      if (opt.letterType.toUpperCase() === 'CANCELED') {
        opt.box = 'CANCELED';
        delete(opt.letterType);
      }
      LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page : result.page, 
          limit : result.limit, 
          total : result.total, 
          data : result.data || [],
          loading : false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          data : [],
          loading : false,
        });
      });
    });
  }

  page = (page) => {
    this.setState({loading : true, searchString:''});
    let opt = { box:this.state.box, letterType : this.props.type, page : page, limit : this.state.limit, search : this.state.searchString }
    if (opt.letterType.toUpperCase() === 'CANCELED') {
      opt.box = 'CANCELED';
      delete(opt.letterType);
    }
    LetterService.getList(opt)
    .then((result)=>{
      this.setState({
        page : result.page, 
        limit : result.limit, 
        total : result.total, 
        data : result.data || [],
        loading : false,
      });
    })
    .catch((err)=>{
      this.setState({
        data : [],
        loading : false,
      });
    });
  }

  next = () => {
    this.setState({loading : true, searchString:''});
    let opt = { box:this.state.box, letterType : this.props.type, page : this.state.page + 1, limit : this.state.limit, search : this.state.searchString }
    if (opt.letterType.toUpperCase() === 'CANCELED') {
      opt.box = 'CANCELED';
      delete(opt.letterType);
    }
    LetterService.getList(opt)
    .then((result)=>{
      this.setState({
        page : result.page, 
        limit : result.limit, 
        total : result.total, 
        data : result.data || [],
        loading : false,
      });
    })
    .catch((err)=>{
      this.setState({
        data : [],
        loading : false,
      });
    });
  }

  prev = () => {
    this.setState({loading : true, searchString:''});
    let opt = { box:this.state.box, letterType : this.props.type, page : this.state.page - 1, limit : this.state.limit, search : this.state.searchString }
    if (opt.letterType.toUpperCase() === 'CANCELED') {
      opt.box = 'CANCELED';
      delete(opt.letterType);
    }
    LetterService.getList(opt)
    .then((result)=>{
      this.setState({
        page : result.page, 
        limit : result.limit, 
        total : result.total, 
        data : result.data || [],
        loading : false,
      });
    })
    .catch((err)=>{
      this.setState({
        data : [],
        loading : false,
      });
    });
  }

  reload = () => {
    this.setState({loading : true});
    let startDate = null;
    let endDate = null;
    if (this.state.letterDateStart) {
      startDate = this.state.letterDateStart.toISOString();
    }
    if (this.state.letterDateEnd) {
      endDate = this.state.letterDateEnd.toISOString();
    }
    let opt = { 
      letterType : this.props.type, 
      page : this.state.page, 
      limit : this.state.limit, 
      box : this.state.box, 
      search : this.state.searchString,
      startDate: startDate,
      endDate: endDate
    }
    if (opt.letterType.toUpperCase() === 'CANCELED') {
      opt.box = 'CANCELED';
      delete(opt.letterType);
    }
    LetterService.getList(opt)
    .then((result)=>{
      this.setState({
        currentLetter : {},
        page : result.page, 
        limit : result.limit, 
        total : result.total, 
        data : result.data || [],
        loading : false,
      });
    })
    .catch((err)=>{
      this.setState({
        currentLetter : {},
        data : [],
        loading : false,
      });
    });
  }

  search = () => {
    this.reload()
  }

  dismissSearch = () => {
    this.setState({searchString : ''}, () => {
      this.reload()
    });
  }

  handleSearchString = (e) => {
    if (e && e.target && e.target.value) {
      console.log(e.target.value);
      this.setState({searchString : e.target.value});
    }
  }

  read = (id) => {
    console.log(id);
    if (this.props.type === 'canceled') {
      window.browserHistory.push('/read/' + id + '?action=processStatus'); 
    } else {
      window.browserHistory.push('/read/' + id); 
    }
  }

  toggle = () => {
    this.setState({ dateFilterCollapsed: !this.state.dateFilterCollapsed });
  }

  inputEventHandler = (e) => {
    let obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(obj);
  }

  resetFilter = () => {
    this.setState({ letterDateStart: null, letterDateEnd: null}, () => {
      this.reload()
    })
  }
  
  render() {
    return (
    <div>
      {this.state.mode === 'list' &&
	  <div className="row">
	    <div className="col-md-12">
          <h4> Kotak Keluar&nbsp;
            {this.props.type === 'TNDE_NOTA_DINAS' && 'Nota Dinas'}
            {this.props.type === 'TNDE_SURAT_TUGAS' && 'Surat Tugas'}
            {this.props.type === 'TNDE_SURAT' && 'Surat'}
            {this.props.type === 'TNDE_UNDANGAN' && 'Surat Undangan'}
            {this.props.type === 'TNDE_MEMO' && 'Memo'}
            {this.props.type === 'canceled' && 'Batal'}
            {this.props.type === 'disposition' && 'Disposisi'}
          </h4>
          <div className="box box-success">
            <div className="card card-default">
              <div className="card-header" 
                style={{cursor : 'pointer'}} 
                onClick={() => {this.toggle()}} >
                <span style={{float:'right', cursor : 'pointer'}}>
                  {this.state.dateFilterCollapsed ? <Fa.FaChevronDown/> : <Fa.FaChevronUp/>}
                </span>
                Filter Tanggal
              </div>
              <div className="card-body" style={{display : this.state.dateFilterCollapsed ? 'none' : 'block'}}>
                <div style={{ float: "left", width: '50%' }}>
                  <DateTimePicker
                    label="Dari Tanggal"
                    name="letterDateStart"
                    value={this.state.letterDateStart}
                    onChange={this.inputEventHandler}
                    labelWidth={3}
                    fieldWidth={9}
                  />
                </div>
                <div style={{float: "right", width: '50%' }}>
                  <DateTimePicker
                    label="Sampai Tanggal"
                    name="letterDateEnd"
                    value={this.state.letterDateEnd}
                    onChange={this.inputEventHandler}
                    labelWidth={3}
                    fieldWidth={9}
                  />
                </div>
                <button className="btn btn-small btn-success" onClick={this.reload}>Filter</button> &nbsp;
                <button className="btn btn-small btn-default" onClick={this.resetFilter}>Reset Filter</button>
              </div>
            </div>
          </div>
          <div className="box box-success">
            <div className="box-body" style={{padding:0}}>
              <div className="row" style={{padding:15}}>
                <div className="col-sm-12 col-md-6 col-lg-8">
                  <div className="input-group input-group-sm">
                    <span className="input-group-btn">
                      <span className="btn btn-success">
                        <Fa.FaRefresh/>
                      </span>
                    </span>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleSearchString}
                      value={this.state.searchString}
                      onKeyPress={(e) => { if (e.key === 'Enter') this.search() }}
                    />
                    {this.state.searchString && this.state.searchString.length > 0 &&
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.dismissSearch}>
                        <Fa.FaClose/>
                      </span>
                    </span>
                    }
                    <span className="input-group-btn">
                      <span className="btn btn-default" onClick={this.search}>
                        <Fa.FaSearch/>
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div style={{float:'right'}}>
                    <Pagination 
                      page={this.state.page} 
                      limit={this.state.limit} 
                      total={this.state.total} 
                      prev={this.prev} next={this.next} 
                      toPage={this.page}/>
                  </div>
                </div>
                <hr/>
              </div>
              {this.state.loading ? <Spinner/> :
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>No. Surat</th>
                    <th>Kepada</th>
                    <th>Perihal</th>
                    <th>
                      {this.props.type === 'canceled' ? 'Tanggal dibatalkan' : 'Tanggal'}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data && Object.keys(this.state.data).map((item, index) => {
                    return <tr
                      key={index}
                      onClick={() => {this.read(this.state.data[item].id)}}
                      style={{cursor:'pointer', fontWeight: this.state.data[item].isUnread && this.state.data[item].letterState === 'CANCELED' ? 'bold' : 'normal'}}
										>
                      <td>{this.state.data[item].number}</td>
                      <td>
                        {this.state.data[item].recipients && Object.keys(this.state.data[item].recipients).map((r,i) => {
                          if (this.state.data[item].recipients[r].recipientType.indexOf('VIA') > -1 ||
                              this.state.data[item].recipients[r].recipientType.indexOf('ASSISTANT') > -1 ||
                              this.state.data[item].recipients[r].recipientType.indexOf('DELEGATION') > -1 ||
                              this.state.data[item].recipients[r].recipientType.indexOf('FORWARD') > -1
                          ) {
                              return '';
                          } else {
                            return <span key={i}>{this.state.data[item].recipients[r].recipientName}
                              {(this.state.data[item].recipients.length-1 !== parseInt(r, 10)) && <span>, </span>}
                            </span>
                          }
                        })}
                      </td>
                      <td>{stripTags(this.state.data[item].subject)}</td>
                      <td>
                        {this.props.type === 'canceled' ? date.format(this.state.data[item].modifiedTimestamp, 'DD MMMM YYYY', { locale : dateLocaleId }) : date.format(this.state.data[item].letterDate, 'DD MMMM YYYY', { locale : dateLocaleId })}
                      </td>
                      <td style={{textAlign:'center'}}>
                        {this.state.data[item].flagFinalisasi === 'email' && <Fa.FaEnvelope style={{marginLeft:5}}/>}
                        {this.state.data[item].flagFinalisasi === 'cetak' && <Fa.FaPrint style={{marginLeft:5}}/>}
                      </td>
                      <td>
                        {this.state.data[item].isCurrentUserAPelaksana && <Fa.FaUser style={{ marginLeft: 5 }} />}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{textAlign:'center', marginTop:50, marginBottom:50}}>
                  <span style={{fontSize:32}}>
                    <Fa.FaInbox/>
                  </span>
                  <br/>
                  <h4>Tidak ada surat</h4>
                </div>
              }
		    </div>
		  </div>
	    </div>
	  </div>
      }
      {this.state.mode === 'read' &&
        <LetterRead
		  currentLetter={this.state.currentLetter}
		  back={this.backToList}
		  readDetail={this.readDetail}
		/>
      }
      {this.state.mode === 'readDetail' &&
        <LetterReadDetail
		  currentLetter={this.state.currentLetter}
		  back={this.backToRead}
		/>
      }
    </div>
    );
  }
}
