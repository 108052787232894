import React, { Component } from 'react';
import { connect } from  'react-redux';
import DashboardUser from './DashboardUser';
import DashboardLeader from './DashboardLeader';
import Tab from '../../../components/Tab';
import Letter from '../../../services/Letter';

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser
  }
}

const LetterService = new Letter()

class Dashboard extends Component {
  state = {
    currentTab: 'LEADER',
    tabNav: {
      LEADER: {
        name: 'Dashboard Pimpinan',
        default: true
      },
      USER: {
        name: 'Dashboard User'
      }
    }
  }

  componentDidMount = () => {
    let token = window.localStorage.getItem('access_token');
    if (!token || (token && token.length < 1)) {
      return;
    }
    LetterService.getStats()
    
  }

  switchTab = (tab) => {
    this.setState({currentTab: tab})
  }

  render() {
    let isLeaderDashboardEnabled = JSON.parse(localStorage.getItem('current_user'))
    return (
      <div>
        <div>
          {isLeaderDashboardEnabled && isLeaderDashboardEnabled.organization.leaderDashboardEnabled === true ? 
            <div>
              <Tab nav={this.state.tabNav} handler={this.switchTab} />
                {
                  this.state.currentTab === 'LEADER' ? 
                  <DashboardLeader />
                  :this.state.currentTab === 'USER' ?
                  <DashboardUser /> 
                  : <div></div>
                }
            </div>
            :
            <DashboardUser/>
          }
        </div>        
      </div>
    )
  }
}

export default connect(mapper)(Dashboard);