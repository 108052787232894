import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import Config from '../../../config';
import Auth from '../../..//services/Auth';
import Letter from '../../..//services/Letter';
import User from '../../..//services/User';

const AuthService = new Auth();
const LetterService = new Letter();
const UserService = new User()
var downloadAttr = {};

const mapper = state => {
  return {
    currentUser: state.rootReducers.currentUser,
    sidebarCollapse: state.rootReducers.sidebarCollapse,
    stats: state.rootReducers.stats,
    'location': state.router.location
  }
}

const dispatcher = dispatch => {
  return {
    toggleSidebar: isCollapse => dispatch(window.actions.toggleSidebar(isCollapse)),
    letterStats: stats => dispatch(window.actions.letterStats(stats)),
  }
}

class Sidebar extends Component {
  state = {
    isLoggedIn: AuthService.isLoggedIn(),
    currentPage: {
      path: '/dashboard',
      name: 'Dashboard',
    },
    firstLoad: true,
    navigation: {
      dashboard: {
        path: '/dashboard',
        name: 'Dashboard',
      },
      userProfile: {
        path: '/userProfile',
        name: 'Profile User',
      },
      compose: {
        path: '/compose',
        name: 'Buat Surat',
        childs: {
          notaDinas: {
            path: '/compose/TNDE_NOTA_DINAS',
            name: 'Nota Dinas',
          },
          suratTugas: {
            path: '/compose/TNDE_SURAT_TUGAS',
            name: 'Surat Tugas',
          },
          suratUndangan: {
            path: '/compose/TNDE_UNDANGAN',
            name: 'Surat Undangan',
          },
          surat: {
            path: '/compose/TNDE_SURAT',
            name: 'Surat',
          },
          memo: {
            path: '/compose/TNDE_MEMO',
            name: 'Memo',
          },
          tndeTemplate: {
            path: '/settings/letter-template',
            name: 'Templat Naskah TNDE',
          },
        }
      },
      inbox: {
        path: '/inbox',
        name: 'Kotak Masuk',
        unread: 'ALL_UNREADS',
        childs: {
          all: {
            path: '/inbox/all',
            name: 'Semua',
            unread: 'INBOX',
          },
          notaDinas: {
            path: '/inbox/nota-dinas',
            name: 'Nota Dinas',
            unread: 'TNDE_NOTA_DINAS',
          },
          suratTugas: {
            path: '/inbox/surat-tugas',
            name: 'Surat Tugas',
            unread: 'TNDE_SURAT_TUGAS',
          },
          suratUndangan: {
            path: '/inbox/surat-undangan',
            name: 'Surat Undangan',
            unread: 'TNDE_UNDANGAN',
          },
          surat: {
            path: '/inbox/surat',
            name: 'Surat',
            unread: 'TNDE_SURAT',
          },
          memo: {
            path: '/inbox/memo',
            name: 'Memo',
            unread: 'TNDE_MEMO',
          },
          separator: {
            path: 'separator',
          },
          disposition: {
            path: '/inbox/disposition',
            name: 'Disposisi',
            unread: 'DISPOSITION',
          },
          tanggapan: {
            path: '/inbox/tanggapan',
            name: 'Tanggapan',
            unread: 'COMMENT',
          },
        }
      },
      outbox: {
        path: '/outbox',
        name: 'Kotak Keluar',
        unread: 'CANCELED',
        childs: {
          all: {
            path: '/outbox/all',
            name: 'Semua',
          },
          notaDinas: {
            path: '/outbox/nota-dinas',
            name: 'Nota Dinas',
          },
          suratTugas: {
            path: '/outbox/surat-tugas',
            name: 'Surat Tugas',
          },
          suratUndangan: {
            path: '/outbox/surat-undangan',
            name: 'Surat Undangan',
          },
          surat: {
            path: '/outbox/surat',
            name: 'Surat',
          },
          memo: {
            path: '/outbox/memo',
            name: 'Memo',
          },
          separator: {
            path: 'separator',
          },
          disposition: {
            path: '/outbox/disposition',
            name: 'Disposisi',
          },
          manual: {
            path: '/outbox/manual',
            name: 'Surat Manual',
          },
          canceled: {
            path: '/outbox/canceled',
            name: 'Batal',
            unread: 'CANCELED',
          },
        }
      },
      processing: {
        path: '/processing',
        name: 'Kotak Proses',
        unread: 'PROCESSING',
        childs: {
          finalization: {
            path: '/processing/finalization',
            name: 'Perlu Finalisasi',
            unread: 'FINAL',
          },
          finalized: {
            path: '/processing/finalized',
            name: 'Sudah Difinalisasi',
          },
          needApproval: {
            path: '/processing/need-approval',
            name: 'Perlu Persetujuan',
            unread: 'NEED_APPROVAL',
          },
          needOpinion: {
            path: '/processing/need-opinion',
            name: 'Perlu Pendapat',
            unread: 'NEED_OPINION',
          },
          status: {
            path: '/processing/status',
            name: 'Status',
            unread: 'RETURNED',
          },
					/*
          requester: {
            path: '/processing/requester',
            name: 'Pemesan',
          },
					*/
          draft: {
            path: '/processing/draft',
            name: 'Draf',
            unread: 'DRAFT',
          },
        }
      },
      archive: {
        path: '/archive',
        name: 'Kotak Arsip',
        childs: {
          manual: {
            path: '/archive/manual-letter-archive',
            name: 'Surat Manual Lama',
          },
          in: {
            path: '/archive/external',
            name: 'Arsip Eksternal',
          },
          out: {
            path: '/archive/internal',
            name: 'Arsip Internal',
          },
          disposition: {
            path: '/archive/disposition',
            name: 'Arsip Disposisi',
          },
        }
      },
      tools: {
        path: '/tools',
        name: 'Perkakas',
        childs: {
          userGuideOASystem: {
            path: '/docs/user_guide_office_automation.pdf',
            newTab: true,
            name: 'Panduan Penggunaan OA',
          }
        }
      },
      settings: {
        path: '/settings',
        name: 'Pengaturan',
        childs: {
          general: {
            path: '/settings/satker',
            name: 'Satuan Kerja',
          },
          externalRecipient: {
            path: '/settings/external_letter_recipient',
            name: 'Penerima Surat Luar',
          },
          users: {
            path: '/settings/users',
            name: 'Manajemen Pengguna',
          },
          userGroups: {
            path: '/settings/user-groups',
            name: 'Grup Pengguna',
          },
          orgs: {
            path: '/settings/organizations',
            name: 'Struktur Organisasi',
          },
          units: {
            path: '/settings/units',
            name: 'Unit',
          },
          dipositionAction: {
            path: '/settings/disposition-actions',
            name: 'Setelan Aksi Disposisi'
          },
          classification: {
            path: '/settings/classification',
            name: 'Klasifikasi Surat',
          },
          letterHeader: {
            path: '/settings/letter-header',
            name: 'Templat Kop Surat',
          },
          monitorLog: {
            path: '/settings/monitor',
            name: 'Monitor Log',
          },
        }
      },
      help: {
        path: '/helpdesk',
        name: 'Bantuan',
      },
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.sidebarCollapse) {
      document.body.classList.toggle('sidebar-collapse', nextProps.sidebarCollapse);
      document.body.classList.remove('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-collapse');
      document.body.classList.toggle('sidebar-open', !nextProps.sidebarCollapse);
    }
  }
  componentDidMount = () => {
    LetterService.getStats()
  }
  showSidebar = () => {
    document.body.classList.toggle('sidebar-open', true);
  }
  hideSidebar = () => {
    if (this.props.sidebarCollapse) {
      document.body.classList.toggle('sidebar-open', false);
    }
  }
  logout = () => {
    swal({
      title: '',
      type: 'warning',
      text: 'Anda yakin ingin keluar dari sistem?',
      showCancelButton: true,
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          AuthService.logout()
        }, 500);
      }
    })
  }
  navigateTo = (page) => {
    // Expand
    this.setState({ currentPage: page });
    if (!page.childs) {
      window.browserHistory.push(page.path)
    }
  }

  logoutImpersonate = () => {
    swal({
      title: '',
      type: 'warning',
      text: 'Anda yakin ingin keluar dari penyamaran ?',
      showCancelButton: true,
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          UserService.logoutImpersonate()
          .then((result) => {
            this.setState({loading: false})
            window.store.dispatch(window.actions.loggedIn({ accessToken: localStorage.getItem('impersonator_origin_token'), currentUser: JSON.parse(localStorage.getItem('impersonator_user'))}));
            localStorage.removeItem('impersonator_origin_token')
            localStorage.removeItem('impersonator_token_id')
            localStorage.removeItem('impersonate_id')
            localStorage.removeItem('impersonator_user')
            window.browserHistory.push('/');
          })
          .catch((err) => {
            this.setState({loading: false})
          })
        }, 500)
      }
    })
  }

  render() {
    if (!AuthService.getToken()) {
      return (<div />)
    }

    return (
      <div>
        {this.props.location.pathname !== '/login' && this.props.location.pathname.indexOf('reset-password') < 0 &&
          <aside className="main-sidebar">
            <section className="sidebar"
            //onMouseOver={this.showSidebar} onMouseLeave={this.hideSidebar}
            >
              <Link to="/user-profile">
                <div
                  className="user-panel"
                  style={{ cursor: 'pointer' }}
                >
                  <div className="pull-left image" style={{ width: 40, display: 'inline-block' }}>
                    {
                      this.props.currentUser.picture?
                      <img src={Config.apiHost + "/api/v1/blob/unrestricted/" + this.props.currentUser.picture} onError={(e) => { e.target.src = "/images/default-avatar.png" }} alt="" style={{ background: 'grey', width: this.props.sidebarCollapse ? 30 : 40, height: this.props.sidebarCollapse ? 30 : 40, borderRadius: 50 }} />
                      :""
                    }
                  </div>
                  <div className="pull-left info" style={{ display: 'inline-block', width: 175 }}>
                    <p className="ellipsis">{this.props.currentUser.name}</p>
                    <p className="ellipsis">{this.props.currentUser.title}</p>
                    <p>{this.props.currentUser.username}</p>
                  </div>
                </div>
              </Link>
              <div className="sidebar-menu-container">
                <ul className="sidebar-menu" data-widget="tree">
                  {this.props.currentUser && AuthService.getRoles().role === 'FRONTOFFICE' &&
                    <li>
                      <Link to="/compose/MANUAL" className="a-nohref" style={{ cursor: 'pointer' }}>
                        <Fa.FaEdit style={{ fontSize: 16, lineHeight: '12px' }} />&nbsp;
                        Buat Surat Masuk Manual
                    </Link>
                    </li>
                  }
                  {Object.keys(this.state.navigation).map((item, index) => {
                    if (item === 'userProfile'
                      || item === 'dashboard'
                    ) {
                      return '';
                    }
                    if (item === 'settings' && AuthService.getRoles().role !== 'ADMIN') {
                      return '';
                    }
                    
                    if (this.state.navigation[item].childs && this.state.navigation[item].childs.externalRecipient && !Config.gerbang) {
                      delete this.state.navigation[item].childs.externalRecipient;
                    }

                    return <li
                      key={index}
                      id={'top-menu-' + index}
                      className={`${this.state.currentPage.path.indexOf(item) > -1 && 'active'}`}
                    >
                      <a className="a-nohref" onClick={() => {
                        this.navigateTo(this.state.navigation[item])
                        Object.keys(this.state.navigation).map((k, i) => {
                          let el = document.getElementById('top-menu-' + i)
                          if (el) {
                            if (i === index) {
                              el.className = (el.className === 'false') ? 'active' : 'false';
                            } else {
                              el.className = 'false';
                            }
                          }
                          return '';
                        })
                      }}>
                        {/* Icons */}
                        {this.state.navigation[item].path === '/compose' &&
                          <Fa.FaPencilSquare style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/inbox' &&
                          <Fa.FaInbox style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/outbox' &&
                          <Fa.FaShareSquareO style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/processing' &&
                          <Fa.FaHourglass style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/archive' &&
                          <Fa.FaArchive style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/tools' &&
                          <Fa.FaWrench style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/settings' &&
                          <Fa.FaCog style={{ fontSize: 16, lineHeight: '12px' }} />}
                        {this.state.navigation[item].path === '/helpdesk' &&
                          <Fa.FaQuestion style={{ fontSize: 16, lineHeight: '12px' }} />}

                        <span>&nbsp; {this.state.navigation[item].name}</span>
                        <span className="unread-count">{this.state.navigation[item].unread && this.props.stats && this.props.stats[this.state.navigation[item].unread] !== 0 && this.props.stats[this.state.navigation[item].unread]}</span>
                      </a>
                      {this.state.navigation[item].childs ?
                        <ul className="treeview-menu">
                          {Object.keys(this.state.navigation[item].childs).map((child, childIndex) => {

                            if (this.state.navigation[item].childs[child].path.endsWith('.pdf')) {
                              var fileName = this.state.navigation[item].childs[child].path.split(/(\\|\/)/g).pop();
                              downloadAttr = { download: fileName };
                            }

                            if (this.state.navigation[item].childs[child].path === '/processing/finalization' && this.props.currentUser && AuthService.getRoles().role !== 'FINALIZER') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === '/archive/internal' && this.props.currentUser && AuthService.getRoles().role !== 'ADMIN' && this.props.currentUser && AuthService.getRoles().orgRole !== 'PUKS') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === '/archive/external' && this.props.currentUser && AuthService.getRoles().role !== 'ADMIN' && this.props.currentUser && AuthService.getRoles().orgRole !== 'PUKS') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === '/archive/disposition' && this.props.currentUser && AuthService.getRoles().role !== 'ADMIN' && this.props.currentUser && AuthService.getRoles().orgRole !== 'PUKS') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === '/processing/finalized' && this.props.currentUser && AuthService.getRoles().role !== 'FINALIZER') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === '/outbox/manual' && this.props.currentUser && AuthService.getRoles().role !== 'FRONTOFFICE') {
                              return '';
                            } else if (this.state.navigation[item].childs[child].path === 'separator') {
                              return <hr key={childIndex} style={{margin:0}}/>;
                            } else {

                              return <li
                                key={childIndex}
                                className={`${this.props.location.pathname === this.state.navigation[item].childs[child].path && 'active'}`}
                              >
                                {this.state.navigation[item].childs[child].newTab ?
                                  <a href={this.state.navigation[item].childs[child].path} target="_blank" {...downloadAttr}>
                                    {this.state.navigation[item].childs[child].name}
                                    <br />
                                  </a>
                                  :
                                  <Link to={this.state.navigation[item].childs[child].path}>
                                    {this.state.navigation[item].childs[child].name}
                                    <span className={child === 'needApproval' ? 'important-count' : child === 'draft' ? 'item-count' : 'unread-count'}>{this.state.navigation[item].childs[child].unread && this.props.stats && this.props.stats[this.state.navigation[item].childs[child].unread] !== 0 && this.props.stats[this.state.navigation[item].childs[child].unread]}</span>
                                    <br />
                                  </Link>
                                }
                              </li>
                            }
                          })}
                        </ul>
                        : ''}
                    </li>
                  })}
                  {!localStorage.getItem('impersonator_token_id') ? 
                    <li><a className="no-a-href" style={{ cursor: 'pointer' }} onClick={this.logout}><i className="fa fa-circle-o text-aqua"></i> <span>Keluar</span></a></li> 
                    :
                    <li><a className="no-a-href" style={{ cursor: 'pointer' }} onClick={this.logoutImpersonate}><i className="fa fa-circle-o text-aqua"></i> <span>Hentikan Penyamaran</span></a></li>
                  }
                </ul>
              </div>
            </section>
          </aside>
        }
      </div>
    );
  }
}

export default connect(mapper, dispatcher)(Sidebar);
