import loginCompanyLogo from './images/company-logo.png'
import backgroundImage from './images/background.jpg'
import navigationLogo from './images/company-logo-nav.png'
import letterLogo from './images/logo-kop.png'
import favIcon from './images/favicon.png'
import styles from './styles.css'

const config = () => {
    return {
        "name": "default",
        "applicationTitle": "Office Automation - Surelintas",
        "footer": "Office Automation - Surelintas",
        "images": {
            "companyLogo": loginCompanyLogo,
            "backgroundImage": backgroundImage,
            "navigationLogo": navigationLogo,
            "letterLogo": letterLogo,
            "favIcon": favIcon
        }, 
        "styles": styles
    }
}
export default config()
