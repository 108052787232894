import React, { Component } from 'react';
import Letter from '../../../../../services/Letter';
import Spinner from '../../../../../components/Spinner';
import Pagination from '../../../../../components/Pagination';
import Tab from '../../../../../components/Tab';
import * as Fa from 'react-icons/lib/fa';
import date from 'date-fns';
import dateLocaleId from 'date-fns/locale/id';
import stripTags from '../../../../../utils'

const LetterService = new Letter();

export default class ProcessingNeedApproval extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    pagination: [],
    data: [],
    filterValue: '',
    loading: false,
    currentTab: 'NEED_APPROVAL',
    tabNav: {
      NEED_APPROVAL: {
        name: 'Perlu Persetujuan',
        default: true,
      },
      WILL_NEED_APPROVAL: {
        name: 'Akan Disetujui',
      },
    },
  }

  componentDidMount = () => {
    this.setState({ loading: true }, () => {
      LetterService.getList({ box: this.state.currentTab, page: 1, limit: 10 })
        .then((result) => {
          this.setState({
            page: result.page,
            limit: result.limit,
            total: result.total,
            data: result.data,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab }, () => {
      this.page();
    });
  }

  page = (page) => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: page, limit: this.state.limit }
    LetterService.getList(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  next = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: this.state.page + 1, limit: this.state.limit }
    LetterService.getOutbox(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  prev = () => {
    this.setState({ loading: true, filterValue: '' });
    let opt = { box: this.state.currentTab, page: this.state.page - 1, limit: this.state.limit }
    LetterService.getOutbox(opt)
      .then((result) => {
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          data: result.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          data: [],
          loading: false,
        });
      });
  }

  read = (id) => {
    if (this.state.currentTab !== 'NEED_APPROVAL') {
      return;
    }
    window.browserHistory.push('/read/' + id + '?action=process');
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Perlu Persetujuan</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              <Tab nav={this.state.tabNav} handler={this.switchTab} />
              {this.state.loading ? <Spinner /> :
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Tanggal</th>
                      <th>Perihal</th>
                      {this.state.currentTab === 'NEED_APPROVAL' &&
                        <th>Status</th>
                      }
                      <th>Pemeriksa saat ini</th>
                      <th>Sisa Step</th>
                      <th>Penanda Tangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data && Object.keys(this.state.data).map((item, index) => {
                      return <tr key={index} onClick={() => { this.read(this.state.data[item].id) }} style={{ cursor: 'pointer' }}>
                        <td>
                          {date.format(this.state.data[item].timestamp, 'DD MMMM YYYY', { locale: dateLocaleId })}
                        </td>
                        <td>{stripTags(this.state.data[item].subject)}</td>
                        {/* TODO: find the proper field */}
                        {this.state.currentTab === 'NEED_APPROVAL' &&
                          <td>In Progress</td>
                        }
                        <td>
                          {this.state.data[item].processings && this.state.data[item].processings.map((procItem, procIndex) => {
                            let holderName = '';
                            if (procItem.state === 'PROCESS') holderName = procItem.holderName;
                            return <span key={procIndex}>{holderName.split('|')[0]}{holderName.split('|')[1] && <span>, {holderName.split('|')[1]}</span>}</span>
                          })}
                        </td>
                        <td> {this.state.data[item].processings && this.state.data[item].processings.map((procItem, procIndex) => {
                          if (procItem.state === 'PROCESS') {
                            return <span key={procIndex}>{this.state.data[item].processings.length - procIndex}</span>
                          } else {
                            return <span key={procIndex}></span>
                          }
                        })}
                        </td>
                        <td>
                          {this.state.data[item].processings[this.state.data[item].processings.length - 1].holderName.split('|')[0]}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              }
              {!(this.state.total > 0) && !this.state.loading &&
                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 50 }}>
                  <span style={{ fontSize: 32 }}>
                    <Fa.FaInbox />
                  </span>
                  <br />
                  <h4>Tidak ada surat</h4>
                </div>
              }
              <Pagination
                page={this.state.page}
                limit={this.state.limit}
                total={this.state.total}
                prev={this.prev} next={this.next}
                toPage={this.page}
                disabled={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
