import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import qs from 'qs';
import Tab from '../../../../components/Tab';
import Spinner from '../../../../components/Spinner';
import hash from 'hash.js';
import { connect } from "react-redux";

import FormSuratMasukManual from './components/FormSuratMasukManual';
import FormInformasiSurat from './components/FormInformasiSurat';
import FormPenjelasanIsiSurat from './components/FormPenjelasanIsiSurat';
import FormIsiSurat from './components/FormIsiSurat';
import FormAtribut from './components/FormAtribut';
import Letter from '../../../../services/Letter';
import SwAlert from 'sweetalert-react';
import swal from 'sweetalert';
import { LoremIpsum } from "lorem-ipsum";
import '../../sweetalert.css';
import hex from 'hex.js';
import config from '../../../../config';

const LetterService = new Letter();

const mapper = state => {
  return {
    testerMode: state.rootReducers.testerMode
  }
}

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

const tabMap = {
  FormInformasiSurat,
  FormPenjelasanIsiSurat,
  FormIsiSurat,
  FormAtribut,
}
const defaultState = {
  // FIXME Using react dev tool, inspect all state values on full filled letter,
  // then register their empty values here.
  query: {},
  isInProcessChain: false,
  currentTab: FormInformasiSurat,
  loading: false,
  action: '',
  mode: 'form', // enums : form, sendSuccess, saveSuccess
  files: [],
  tabNav: {
    FormInformasiSurat: { // Use component name as key
      name: 'Form Informasi Surat',
      default: true,
      icon: true,
      completed: false, // The Send ('Kirim') button disabled state is controlled by this value
    },
    FormPenjelasanIsiSurat: {
      name: 'Form Penjelasan Isi Surat',
      icon: true,
      completed: false,
    },
    FormIsiSurat: {
      name: 'Isi',
      icon: true,
      completed: false,
    },
    FormAtribut: {
      name: 'Atribut',
      icon: true,
      completed: false,
    }
  },
  formAtributTabClicked: false,
  attributeContainerCollapse: true,
  signerContainerCollapse: false,
  // The letter itself, 
  // if you want to change this, make sure you 
  // change related components as well (under Form*)
  // - Informasi surat
  signers: {
    str: '',
    raw: [],
  },
  signerNames: [],
  perProcurationem: '',

  signerDetailLoading: false,
  signerDetail: {
  },
  classification: {
    str: '',
    raw: '',
  },
  letterHeaderId: '',
  // - Penjelasan
  // - Isi
  subject: '', //'ok' + (new Date()).valueOf(),
  contents: '', //'ok' + (new Date()).valueOf(),
  contentAbstract: '', //'ok' + (new Date()).valueOf(),
  notes: '', //'ok' + (new Date()).valueOf(),
  number: '', // This is a nomor surat from agenda
  deadline: '',
  importance: '',
  via: {
    str: '',
    raw: []
  },
  signatureType: config.signatureType,
  numOfPrintedAttachments: 0,
  letterType: '',
  manualLetterType: 'TNDE_NOTA_DINAS',
  approvers: {
    str: '',
    raw: []
  },
  references: [],
  dispositionStatusReferences: [],
  replyReferenceNumber: null,
  replyReference: null,

  bundleState: 'NONE',
  bundle: [],
  attachments: [],

  recipients: {
    str: '',
    raw: []
  },
  // This will merged into recipients
  viaRecipients: {
    str: '',
    raw: []
  },

  ccRecipients: {
    str: '',
    raw: []
  },
  bccSecretRecipients: {
    str: '',
    raw: []
  },
  showOrderer: false,
  showCc: false,
  showForAttention: false,
  orderedBy: {
    str: '',
    raw: '',
  },
  ordererNotes: '',
  externVia: {
    str: '',
    raw: []
  },
  externRecipients: {
    str: '',
    raw: []
  },
  externCcRecipients: {
    str: '',
    raw: []
  },
  forAttentionInternRecipient: {
    str: '',
    raw: []
  },
  forAttentionExternRecipient: {
    str: '',
    raw: []
  },
  recipientSorted: {
    str: '',
    raw: []
  },
  ccRecipientSorted: {
    str: '',
    raw: []
  },
  destination: null,

  // Invitation
  invitationStartDate: null,
  invitationEndDate: null,
  invitationStartTime: null,
  invitationEndTime: null,
  invitationVenue: null,
  invitationRapatRoom: null,

  // - Attribute
  // expirationDate : '', -> deadline
  type: '',
  year: (new Date()).getFullYear(),
  letterId: '',
  // - Attribute, data Penanda tangan surat, auto filled (?)

  //Confirmations
  sendConfirmation: false,
  saveConfirmation: false,
  updateInprocessConfirmation: false,
  discardConfirmation: false,

  customAttachment: '',
  pelaksana: '',
  city: '',

  isBundlerProcHolderChain: false,

  unit: null,
  originator: '',
  letterState: '',
  reviewer: ''
}

class ComposeComponent extends Component {
  state = {
    files: [],
  }

  componentWillReceiveProps = () => {
    let queryStr = window.location.href.split("?")[1] || '';
    let defaultValues = JSON.parse(JSON.stringify(defaultState));
    defaultValues.query = qs.parse(queryStr);
    this.reset(defaultValues, () => {
    })
    // Simulate empty inputEventHandler
    let e = { target: { name: 'loading', value: false } }
    this.inputEventHandler(e);
    this.forceUpdate();
  }

  componentDidMount = () => {
    let queryStr = window.location.href.split("?")[1] || '';
    let query = qs.parse(queryStr);
    this.setState({ query: query, loading: true }, () => {
      let uuid = '';
      if (this.state.query && this.state.query.uuid && this.state.query.uuid.length > 0) {
        uuid = this.state.query.uuid;
      }
      if (this.state.query && this.state.query.recompose && this.state.query.recompose.length > 0) {
        uuid = this.state.query.recompose;
      }
      if (uuid && uuid.length > 0) {
        LetterService.readLetter(uuid)
          .then((result) => {
            let obj = Object.assign({}, defaultState);
            obj.query = query
            obj.tabNav.FormAtribut.completed = true;
            obj.perProcurationem = result.onBehalfOf;
            // Map the array values first
            obj.signers = { str: '', raw: [] }
            for (let i in result.signers) {
              if (obj.signers.str.length > 0) {
                obj.signers.str += ', ';
              }
              if (result.signers[i].split('|').length >= 2) {
                obj.signers.str += result.signers[i].split('|')[1];
                obj.signers.raw.push(result.signers[i].split('|')[0]);
              }
            }
            obj.signerNames = result.signerNames;
            obj.approvers = { str: '', raw: [] }
            for (let j in result.approvers) {
              if (obj.approvers.str.length > 0) {
                obj.approvers.str += ', ';
              }
              if (result.approvers[j].split('|').length >= 2) {
                obj.approvers.str += result.approvers[j].split('|')[1];
                obj.approvers.raw.push(result.approvers[j].split('|')[0]);
              }
            }
            obj.via = { str: '', raw: [] }
            for (let i in result.via) {
              if (obj.via.str.length > 0) {
                obj.via.str += ', ';
              }
              if (result.via[i].split('|').length >= 2) {
                obj.via.str += result.via[i].split('|')[1];
                obj.via.raw.push(result.via[i].split('|')[0]);
              }
            }
            obj.recipients = { str: '', raw: [] }
            obj.viaRecipients = { str: '', raw: [] }
            obj.ccRecipients = { str: '', raw: [] }
            obj.bccSecretRecipients = { str: '', raw: [] }
            obj.externRecipients = { str: '', raw: [] }
            obj.externVia = { str: '', raw: [] }
            obj.externCcRecipients = { str: '', raw: [] }
            obj.recipientSorted = { str: '', raw: [] }
            obj.ccRecipientSorted = { str: '', raw: [] }
            obj.forAttentionInternRecipient = { str: '', raw: [] }
            obj.forAttentionExternRecipient = { str: '', raw: [] }
            for (let k in result.externVia) {
              obj.externVia.str = result.externVia[k];
              obj.externVia.raw = [];
              obj.externVia.raw.push(result.externVia[k]);
            }
            
            if (result.recipients) {
              for (var i = result.recipients.length - 1; i >= 0; i--) {
                if (result.recipients[i].parent !== '') {
                  result.recipients.splice(i, 1);
                }
              }
              result.recipients = result.recipients.sort((a, b) => { return a.recipientOrder - b.recipientOrder })
              for (let k in result.recipients) {
                // Split current recipient that has a holder name
                const recipientName = result.recipients[k].recipientName.split(', ')[1]? 
                  result.recipients[k].recipientName.split(', ')[1]: result.recipients[k].recipientName;
                let recipient = {
                  recipientUuid: result.recipients[k].recipientUuid,
                  recipientName: recipientName,
                  recipientType: result.recipients[k].recipientType,
                  recipientOrder: result.recipients[k].recipientOrder,
                }
                if (result.recipients[k].recipientType === 'RECIPIENT') {
                  if (obj.recipients.str.length > 0) {
                    obj.recipients.str += '; ';
                  }
                  obj.recipients.str += recipientName;
                  obj.recipients.raw.push(recipient);
                } else if (result.recipients[k].recipientType === 'CC') {
                  if (obj.ccRecipients.str.length > 0) {
                    obj.ccRecipients.str += '; ';
                  }
                  obj.ccRecipients.str += result.recipients[k].recipientName;
                  obj.ccRecipients.raw.push(recipient);
                } else if (result.recipients[k].recipientType === 'BCC_SECRET') {
                  if (obj.bccSecretRecipients.str.length > 0) {
                    obj.bccSecretRecipients.str += '; ';
                  }
                  obj.bccSecretRecipients.str += result.recipients[k].recipientName;
                  obj.bccSecretRecipients.raw.push(recipient);
                } else if (result.recipients[k].recipientType === 'EXTERN_RECIPIENT') {
                  if (obj.externRecipients.str.length > 0) {
                    obj.externRecipients.str += '; ';
                  }
                  obj.externRecipients.str += result.recipients[k].recipientName;
                  obj.externRecipients.raw.push(recipient.recipientName);
                } else if (result.recipients[k].recipientType === 'EXTERN_CC_RECIPIENT') {
                  if (obj.externCcRecipients.str.length > 0) {
                    obj.externCcRecipients.str += '; ';
                  }
                  obj.externCcRecipients.str += result.recipients[k].recipientName;
                  obj.externCcRecipients.raw.push(recipient.recipientName);
                } else if (result.recipients[k].recipientType === 'VIA') {
                  if (obj.viaRecipients.str.length > 0) {
                    obj.viaRecipients.str += '; ';
                  }
                  obj.viaRecipients.str += result.recipients[k].recipientName;
                  obj.viaRecipients.raw.push(recipient);
                }else if (result.recipients[k].recipientType.indexOf('_FOR_ATTENTION') > -1) {
                  obj.showForAttention = true
                  if ( result.recipients[k].recipientType.indexOf('EXTERN') > -1 ) {
                    if (obj.forAttentionExternRecipient.str.length > 0) {
                      obj.forAttentionExternRecipient.str += '; ';
                    }
                    obj.forAttentionExternRecipient.str += result.recipients[k].recipientName;
                    obj.forAttentionExternRecipient.raw.push(recipient.recipientName);
                  } else {
                    if (obj.forAttentionInternRecipient.str.length > 0) {
                      obj.forAttentionInternRecipient.str += '; ';
                    }
                    obj.forAttentionInternRecipient.str += result.recipients[k].recipientName;
                    obj.forAttentionInternRecipient.raw.push(recipient);
                  }
                }
  
                // Assume that the array has been already sorted by recipientOrder, just put the it to the sorted array
                if (!(result.recipients[k].recipientType.indexOf('CC') > -1)) {
                  if (result.recipients[k].recipientType.indexOf('_FOR_ATTENTION') > -1) {
                    continue;
                  }
                  if (obj.recipientSorted.str.length > 0) {
                    obj.recipientSorted.str += '; ';
                  }
                  obj.recipientSorted.str += result.recipients[k].recipientName;
                  let rec = Object.assign({}, recipient);
                  rec.recipientOrder = parseInt(k, 10) + 1;
                  obj.recipientSorted.raw.push(rec);
                } else {
                  if (obj.ccRecipientSorted.str.length > 0) {
                    obj.ccRecipientSorted.str += '; ';
                  }
                  obj.ccRecipientSorted.str += result.recipients[k].recipientName;
                  let rec = Object.assign({}, recipient);
                  rec.recipientOrder = parseInt(k, 10) + 1;
                  obj.ccRecipientSorted.raw.push(rec);
                }
              }
            }

            obj.destination = result.destination;

            let fields = Object.keys(defaultState);
            for (var l in fields) {
              if (result[fields[l]] && (typeof result[fields[l]] === 'string' || typeof result[fields[l]] === 'number')) {
                obj[fields[l]] = result[fields[l]];
                // Handle specific fields
                if ((fields[l] === 'deadline' ||
                  fields[l] === 'invitationStartTime' ||
                  fields[l] === 'invitationEndTime'
                ) && (new Date(result[fields[l]])).getYear() < 1) {
                  obj[fields[l]] = '';
                }
                if (fields[l] === 'orderedBy' && result[fields[l]].length > 0) {
                  obj['showOrderer'] = true;
                }
              } else if (result[fields[l]] && typeof result[fields[l]] === 'object') {
                if (fields[l] === 'recipients') {
                  for (let i in result[fields[l]]) {
                    if (result[fields[l]][i].recipientType === 'CC' || result[fields[l]][i].recipientType === 'BCC_SECRET' || 
                      result[fields[l]][i].recipientType === 'EXTERN_CC_RECIPIENT') {
                      obj['showCc'] = true;
                    }
                  }
                }
              }
            }
            // classification
            if (result.classification.split('|').length === 2) {
              obj.classification = { str: result.classification.split('|')[1], raw: result.classification.split('|')[0] }
            }
            // Attachments
            let existingFiles = [];
            if (result.attachments && result.attachments.length > 0) {
              obj.attachments = [];
              for (let i in result.attachments) {
                if (result.attachments[i].split(',').length === 4) {
                  obj.attachments.push(result.attachments[i].split(',')[0]);
                  let attachment = {
                    file: { name: result.attachments[i].split(',')[3] },
                    state: 'done',
                    timestamp: (new Date()).valueOf().toString(),
                    uuid: result.attachments[i].split(',')[0],
                  }
                  existingFiles.push(attachment);
                }
              }
            }
            
            if (result.internal_attachments && result.internal_attachments.length > 0) {
              obj.internal_attachments = [];
              for (let i in result.internal_attachments) {
                if (result.internal_attachments[i].split(',').length === 4) {
                  obj.internal_attachments.push(result.internal_attachments[i].split(',')[0]);
                  let attachment = {
                    file: { name: result.internal_attachments[i].split(',')[3] },
                    state: 'done',
                    isInternalAttachment: true,
                    timestamp: (new Date()).valueOf().toString(),
                    uuid: result.internal_attachments[i].split(',')[0],
                  }
                  existingFiles.push(attachment);
                }
              }
            }

            obj.originator = result.originator
            obj.letterState = result.letterState

            // Orderer
            if (result.orderedBy && result.orderedBy.split('|').length > 1) {
              obj.orderedBy = {
                raw: result.orderedBy.split('|')[0],
                str: result.orderedBy.split('|')[1],
              }
            }

            // Deadline
            obj.deadline = new Date(result.deadline);
            if (!obj.deadline || (obj.deadline && obj.deadline.toString === 'Invalid Date')) {
              obj.deadline = null;
            }

            // Invitation
            obj.invitationStartDate = new Date(result.invitationStartDateTime);
            if (!obj.invitationStartDate ||
              (obj.invitationStartDate && obj.invitationStartDate.toString === 'Invalid Date') ||
              (obj.invitationStartDate && obj.invitationStartDate.getFullYear().toString() === "1")) {
              obj.invitationStartDate = null;
            } else {
              if (!(obj.invitationStartDate.getHours().toString() === '0' &&
                obj.invitationStartDate.getMinutes().toString() === '0')) {
                obj.invitationStartTime = new Date(result.invitationStartDateTime);
                obj.invitationStartTime.setHours(obj.invitationStartDate.getHours());
                obj.invitationStartTime.setMinutes(obj.invitationStartDate.getMinutes());
              }
            }
            obj.invitationEndDate = new Date(result.invitationEndDateTime);
            if (!obj.invitationEndDate ||
              (obj.invitationEndDate && obj.invitationEndDate.toString === 'Invalid Date') ||
              (obj.invitationEndDate && obj.invitationEndDate.getFullYear().toString() === "1")) {
              obj.invitationEndDate = null;
            } else {
              if (!(obj.invitationEndDate.getHours().toString() === '0' &&
                obj.invitationEndDate.getMinutes().toString() === '0')) {
                obj.invitationEndTime = new Date(result.invitationEndDateTime);
                obj.invitationEndTime.setHours(obj.invitationEndDate.getHours());
                obj.invitationEndTime.setMinutes(obj.invitationEndDate.getMinutes());
              }
            }
            obj.invitationVenue = result.invitationVenue;

            // References
            obj.references = [];
            for (let i in result.references) {
              let ref = {
                id: result.references[i].split('|')[0],
                number: result.references[i].split('|')[1] || '000000',
                blobId: result.references[i].split('|')[2] || null,
              }
              obj.references.push(ref);
            }

            // Bundle
            obj.bundle = [];
            for (let i in result.bundle) {
              let b = {
                id: result.bundle[i].split('|')[0],
                subject: result.bundle[i].split('|')[1] || '-',
              }
              obj.bundle.push(b);
            }
            obj.isBundlerProcChainHolder = result.isCurrentUserABundlerProcessChainHolder;

            var currentUser = JSON.parse(localStorage.getItem('current_user'));
            obj.isOriginator = false;
            if (result.originator === currentUser.id) {
              obj.isOriginator = true;
            }
            obj.isInProcessChain = false;
            obj.isProcessChainHolder = false;
            // Determine whether the current user (non originator) is in process chain and can edit the letter
            for (let i in result.processings) {
              if (currentUser.id === result.processings[i].holder || currentUser.organization.id === result.processings[i].holder || currentUser.pltId === result.processings[i].holder || currentUser.delegatedId === result.processings[i].holder) {
                obj.isInProcessChain = true;
              }
              if ((currentUser.id === result.processings[i].holder || currentUser.organization.id === result.processings[i].holder || currentUser.pltId === result.processings[i].holder || currentUser.delegatedId === result.processings[i].holder)
                && result.processings[i].state === 'PROCESS') {
                obj.isProcessChainHolder = true;
              }
            }
            obj.currentUser = currentUser;

            obj.isCurrentUserAPelaksana = result.isCurrentUserAPelaksana;
            obj.currentUserPelaksanaType = result.currentUserPelaksanaType;

            if (this.props.letterType === 'TNDE_MEMO') obj.signatureType = 'SCANNED'

            this.reset(obj);
            if (existingFiles.length > 0) {
              let files = this.state.files;
              files.splice(0, files.length);
              for (let i in existingFiles) {
                files.push(existingFiles[i]);
              }
              this.setState({ files: files });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      } else if (this.state.query && this.state.query.reference && this.state.query.reference.split('|').length >= 2) {
        defaultState.references = [];
        let ref = {
          id: this.state.query.reference.split('|')[0],
          number: hex.unHex(this.state.query.reference.split('|')[1]),
        }
        if (this.state.query.reference.split('|')[2]) {
          // Disposition references
          const dispositionStatusIds = this.state.query.reference.split('|')[2].split(',')
          for (let i in dispositionStatusIds) {
            if (defaultState.dispositionStatusReferences.indexOf(dispositionStatusIds[i]) > -1) continue;
            defaultState.dispositionStatusReferences.push(dispositionStatusIds[i]);
          }
        }
        defaultState.replyReferenceNumber = ref.number;
        defaultState.replyReference = ref.id;
        defaultState.references.push(ref);
        this.reset(defaultState);
      } else {
        defaultState.replyReferenceNumber = null;
        defaultState.replyReference = null;
        defaultState.references = []
        this.reset(defaultState);
      }
    });
  }

  setStateWrapper = (obj) => {
    this.setState(obj);
  }

  checkForAttention = (name, obj) => {
    if ((this.state[name].raw.length > 0 && this.state[name].raw.length < 2 && obj[name].raw.length > 1) || obj[name].raw.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  inputEventHandler = (event) => {
    let queryStr = window.location.href.split("?")[1] || '';
    let query = qs.parse(queryStr);
    let val = (event.target.getContent && event.target.getContent()) || event.target.value;
    let obj = {};
    if (event.target.raw) {
      obj[event.target.name] = {}
      obj[event.target.name].str = val;
      obj[event.target.name].raw = event.target.raw;
    } else if (event.target.name === 'externRecipients' ||
      event.target.name === 'externCcRecipients' ||
      event.target.name === 'externVia' || 
      event.target.name === 'forAttentionExternRecipient'
    ) {
      // For extern recipients and extern cc recipient
      obj[event.target.name] = {}
      obj[event.target.name].str = val;
      val = val.replace(/; /g, ';').replace(/ ;/g, ';').replace(/\n/g, '');
      obj[event.target.name].raw = val.split(";");
    } else {
      obj[event.target.name] = val;
    }
    
    // Check for duplicate role in the process chain, except for signer
    if (event.target.name === 'via' || event.target.name === 'approvers') {
      let isDuplicate = false;
      for (let i in event.target.raw) {
        if (isDuplicate) break;
        if (event.target.name !== 'via') {
          for (let j in this.state.via.raw) {
            if (this.state.via.raw[j] === event.target.raw[i]) {
              isDuplicate = true;
              swal({
                title: '',
                type: 'warning',
                text: (event.target.raw.length > 1 ? 'Salah satu nama' : 'Nama') + ' yang Anda pilih sudah memiliki peran sebagai melalui.'
              });
              break;
            }
          }
        }
        if (event.target.name !== 'approvers') {
          for (let j in this.state.approvers.raw) {
            if (this.state.approvers.raw[j] === event.target.raw[i]) {
              isDuplicate = true;
              swal({
                title: '',
                type: 'warning',
                text: (event.target.raw.length > 1 ? 'Salah satu nama' : 'Nama') + ' yang Anda pilih sudah memiliki peran sebagai pemeriksa.'
              });
              break;
            }
          }
        }
      }
      if (isDuplicate) {
        return;
      }
    }

    if (event.target.name.indexOf('forAttention') > -1 ) {
      let checked = this.checkForAttention(event.target.name, obj)
      if (checked) {
        swal({
          title: '',
          text: 'Hanya boleh memilih satu sebagai penerima'
        })
        return;
      }
    }

    // Update the existing sorted
    if (event.target.name === 'recipients') {
      let addList = [];
      let removeList = [];

      // Against recipients
      for (let i in event.target.raw) {
        let found = false;
        for (let j in this.state.recipientSorted.raw) {
          if (event.target.raw[i].recipientUuid === this.state.recipientSorted.raw[j].recipientUuid) {
            found = true;
          }
        }
        if (!found) addList.push(event.target.raw[i]);
      }
      for (let i in this.state.recipientSorted.raw) {
        let found = false;
        for (let j in event.target.raw) {
          if (event.target.raw[j].recipientUuid === this.state.recipientSorted.raw[i].recipientUuid) {
            found = true;
          }
        }
        if (!found && this.state.recipientSorted.raw[i].recipientType.indexOf('EXTERN_R') < 0) removeList.push(this.state.recipientSorted.raw[i]);
      }

      // Against EXTERN recipients
      for (let i in this.state.externRecipients.raw) {
        let found = false;
        for (let j in this.state.recipientSorted.raw) {
          if (this.state.externRecipients.raw[i] === this.state.recipientSorted.raw[j].recipientName) {
            found = true;
          }
        }
        if (!found && this.state.externRecipients.raw[i].length > 0) {
          let r = {
            recipientUuid: hash.sha256().update(this.state.externRecipients.raw[i]).digest('hex'),
            recipientName: this.state.externRecipients.raw[i],
            recipientType: 'EXTERN_RECIPIENT',
          }
          addList.push(r);
        }
      }
      for (let i in this.state.recipientSorted.raw) {
        let found = false;
        for (let j in this.state.externRecipients.raw) {
          if (this.state.externRecipients.raw[j] === this.state.recipientSorted.raw[i].recipientName) {
            found = true;
          }
        }
        if (!found && this.state.recipientSorted.raw[i].recipientType.indexOf('EXTERN_R') > -1) {
          let r = {
            recipientUuid: hash.sha256().update(this.state.recipientSorted.raw[i].recipientName).digest('hex'),
            recipientName: this.state.recipientSorted.raw[i].recipientName,
            recipientType: 'EXTERN_RECIPIENT',
          }
          removeList.push(r);
        }
      }

      // Apply
      let sorted = JSON.parse(JSON.stringify(this.state.recipientSorted));
      for (let i in removeList) {
        for (let j in sorted.raw) {
          if (sorted.raw[j].recipientUuid === removeList[i].recipientUuid) {
            sorted.raw.splice(j, 1);
          }
        }
      }
      for (let i in addList) {
        sorted.raw.push(addList[i]);
      }
      sorted.str = '';
      for (let i in sorted.raw) {
        if (sorted.str.length > 0) sorted.str += '; ';
        sorted.str += sorted.raw[i].recipientName;
      }
      obj.recipientSorted = sorted;
    }

    if (event.target.name === 'letterHeaderId') {
      console.log("CHANGED TO", event.target.value)
      this.setState({
        letterHeaderId: event.target.value
      })
    }
    // Update the existing CC sorted
    if (event.target.name === 'ccRecipients') {
      let addList = [];
      let removeList = [];

      // Against recipients
      for (let i in event.target.raw) {
        let found = false;
        for (let j in this.state.ccRecipientSorted.raw) {
          if (event.target.raw[i].recipientUuid === this.state.ccRecipientSorted.raw[j].recipientUuid) {
            found = true;
          }
        }
        if (!found) addList.push(event.target.raw[i]);
      }
      for (let i in this.state.ccRecipientSorted.raw) {
        let found = false;
        for (let j in event.target.raw) {
          if (event.target.raw[j].recipientUuid === this.state.ccRecipientSorted.raw[i].recipientUuid) {
            found = true;
          }
        }
        if (!found && this.state.ccRecipientSorted.raw[i].recipientType.indexOf('EXTERN_CC') < 0) removeList.push(this.state.ccRecipientSorted.raw[i]);
      }

      // Against EXTERN recipients
      for (let i in this.state.externCcRecipients.raw) {
        let found = false;
        for (let j in this.state.ccRecipientSorted.raw) {
          if (this.state.externCcRecipients.raw[i] === this.state.ccRecipientSorted.raw[j].recipientName) {
            found = true;
          }
        }
        if (!found && this.state.externCcRecipients.raw[i].length > 0) {
          let r = {
            recipientUuid: hash.sha256().update(this.state.externCcRecipients.raw[i]).digest('hex'),
            recipientName: this.state.externCcRecipients.raw[i],
            recipientType: 'EXTERN_CC_RECIPIENT',
          }
          addList.push(r);
        }
      }
      for (let i in this.state.ccRecipientSorted.raw) {
        let found = false;
        for (let j in this.state.externCcRecipients.raw) {
          if (this.state.externCcRecipients.raw[j] === this.state.ccRecipientSorted.raw[i].recipientName) {
            found = true;
          }
        }
        if (!found && this.state.ccRecipientSorted.raw[i].recipientType.indexOf('EXTERN_CC') > -1) {
          let r = {
            recipientUuid: hash.sha256().update(this.state.ccRecipientSorted.raw[i].recipientName).digest('hex'),
            recipientName: this.state.ccRecipientSorted.raw[i].recipientName,
            recipientType: 'EXTERN_CC_RECIPIENT',
          }
          removeList.push(r);
        }
      }

      // Apply
      let sorted = JSON.parse(JSON.stringify(this.state.ccRecipientSorted));
      for (let i in removeList) {
        for (let j in sorted.raw) {
          if (sorted.raw[j].recipientUuid === removeList[i].recipientUuid) {
            sorted.raw.splice(j, 1);
          }
        }
      }
      for (let i in addList) {
        sorted.raw.push(addList[i]);
      }
      sorted.str = '';
      for (let i in sorted.raw) {
        if (sorted.str.length > 0) sorted.str += '; ';
        sorted.str += sorted.raw[i].recipientName;
      }
      obj.ccRecipientSorted = sorted;
    }

    this.setState(obj, () => {

      // Check the required fields
      let tabNav = this.state.tabNav;

      let recipientsExist = false
      if (this.props.letterType !== 'TNDE_MEMO' && this.props.letterType !== 'TNDE_SURAT_TUGAS') {
        if ((this.state.recipients && this.state.recipients.raw && this.state.recipients.raw.length > 0) ||
          (this.state.externRecipients &&
            this.state.externRecipients.raw &&
            this.state.externRecipients.raw.length > 0 &&
            this.state.externRecipients.raw[0].length > 0)) {
          recipientsExist = true;
        }
      } else {
        if (this.state.recipients && this.state.recipients.raw && this.state.recipients.raw.length > 0) {
          recipientsExist = true;
        }
      }

      let signerOk = false
      if (this.props.letterType === 'TNDE_MEMO') {
        signerOk = true;
      } else if (this.state.signers && this.state.signers.raw && this.state.signers.raw.length > 0) {
        signerOk = true;
      }

      // FormInformasiSurat
      if (recipientsExist
        && signerOk
        && (this.state.classification && this.state.classification.raw && this.state.classification.raw.length > 0)
        && (this.state.subject && this.state.subject.length > 0)
      ) {
        tabNav.FormInformasiSurat.completed = true;
      } else {
        tabNav.FormInformasiSurat.completed = false;
      }
      /*
              tabNav.FormPenjelasanIsiSurat.completed = true;
              tabNav.FormIsiSurat.completed = true;
              tabNav.FormAtribut.completed = true;
      */
      // FormPenjelasanIsiSurat
      if ((this.state.contentAbstract && this.state.contentAbstract.length > 0)) {
        tabNav.FormPenjelasanIsiSurat.completed = true;
      } else {
        tabNav.FormPenjelasanIsiSurat.completed = false;
      }
      // FormIsiSurat
      if ((this.state.contents && this.state.contents.length > 0)
        && (this.state.notes && this.state.notes.length > 0)) {
        tabNav.FormIsiSurat.completed = true;
      } else {
        tabNav.FormIsiSurat.completed = false;
      }
      // FormAtribut
      if (this.state.formAtributTabClicked && this.props.letterType === 'TNDE_MEMO') {
        tabNav.FormAtribut.completed = true;
      } else if (this.state.formAtributTabClicked && this.state.signers && this.state.signers.raw.length > 0) {
        tabNav.FormAtribut.completed = this.state.formAtributTabClicked;
      } else if (!query.uuid) {
        tabNav.FormAtribut.completed = false;
      }
      this.setState({ tabNav: tabNav });
    });
  }

  attachmentHandler = (files) => {
    this.setState({ files: files });
  }

  switchTab = (tab) => {
    if (tabMap[tab]) {
      this.setState({ currentTab: tabMap[tab] });
    }
  }

  wrapLetter = (state) => {
    let letter = {
      subject: this.state.subject,
      letterHeaderId: this.state.letterHeaderId,
      signers: this.state.signers.raw,
      signerNames: this.state.signerNames,
      classification: this.state.classification.raw,
      contentAbstract: this.state.contentAbstract,
      contents: this.state.contents,
      number: this.state.number,
      notes: this.state.notes,
      via: this.state.via.raw,
      externVia: (this.state.externVia && this.state.externVia.str && this.state.externVia.str.length > 0) ? this.state.externVia.str.split(';') : [],
      signatureType: this.state.signatureType,
      importance: this.state.importance,
      numOfPrintedAttachments: this.state.numOfPrintedAttachments,
      letterType: (this.state.manualLetterSender && this.state.manualLetterSender.length > 0) ? this.state.manualLetterType : this.props.letterType,
      approvers: this.state.approvers.raw,
      references: [],
      dispositionStatusReferences: [],
      orderedBy: (this.state.orderedBy.raw.length === 0) ? null : this.state.orderedBy.raw,
      attachments: [],
      internal_attachments: [],
      letterState: state,
      recipients: [],
      // For manual letter
      letterDate: this.state.letterDate,
      receivedDate: this.state.receivedDate,
      manualLetterSender: this.state.manualLetterSender,
      onBehalfOf: this.state.perProcurationem,
      customAttachment: this.state.customAttachment,
      pelaksana: this.state.pelaksana,
      city: this.state.city,
      bundleState: this.state.bundleState,
      bundle: [],
      unit: this.state.unit,

      isCurrentUserAPelaksana: this.state.isCurrentUserAPelaksana,
      currentUserPelaksanaType: this.state.currentUserPelaksanaType,

      reviewer: this.state.reviewer.raw,
    }

    if (letter.customAttachment === "0") {
      letter.customAttachment = ""
    }
    for (let i in this.state.references) {
      letter.references.push(this.state.references[i].id);
    }
    for (let i in this.state.dispositionStatusReferences) {
      letter.dispositionStatusReferences.push(this.state.dispositionStatusReferences[i]);
    }
    for (let i in this.state.bundle) {
      letter.bundle.push(this.state.bundle[i].id);
    }
    // Combine all type of recipients
    // Pick the sorted list first
    letter.recipients = [];
    for (let i in this.state.recipientSorted.raw) {
      let obj = this.state.recipientSorted.raw[i];
      obj.recipientOrder = parseInt(i, 10) + 1;
      letter.recipients.push(obj);
    }
    let lastOrder = this.state.recipients.length;
    // Then the recipients
    for (let i in this.state.recipients.raw) {
      let exists = false;
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === this.state.recipients.raw[i].recipientUuid) {
          exists = true;
        }
      }
      if (!exists && this.state.recipients.raw[i].recipientName) {
        lastOrder += 1;
        let obj = this.state.recipients.raw[i];
        obj.recipientOrder = lastOrder;
        letter.recipients.push(obj);
      }
    }
    // Now the extern
    for (let i in this.state.externRecipients.raw) {
      let exists = false;
      if (this.state.externRecipients.raw[i] && this.state.externRecipients.raw[i].length > 0) {
        let id = hash.sha256().update(this.state.externRecipients.raw[i]).digest('hex');
        for (let j in letter.recipients) {
          if (letter.recipients[j].recipientUuid === id) {
            exists = true;
          }
        }
        if (!exists) {
          letter.recipients.push({
            recipientUuid: id,
            recipientName: this.state.externRecipients.raw[i],
            recipientType: 'EXTERN_RECIPIENT',
          });
        }
      }
    }
    // BCC secret recipient
    for (let i in this.state.bccSecretRecipients.raw) {
      let exists = false;
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === this.state.bccSecretRecipients.raw[i].recipientUuid) {
          exists = true;
        }
      }
      if (!exists) {
        let obj = this.state.bccSecretRecipients.raw[i];
        obj.recipientType = 'BCC_SECRET';
        letter.recipients.push(obj);
      }
    }

    
    for (let i in this.state.forAttentionExternRecipient.raw) {
      let exists = false;
      if (this.state.forAttentionExternRecipient.raw[i] && this.state.forAttentionExternRecipient.raw[i].length > 0) {
        let id = hash.sha256().update(this.state.forAttentionExternRecipient.raw[i]).digest('hex');
        for (let j in letter.recipients) {
          if (letter.recipients[j].recipientUuid === id) {
            exists = true;
          }
        }
        if (!exists) {
          letter.recipients.push({
            recipientUuid: id,
            recipientName: this.state.forAttentionExternRecipient.raw[i],
            recipientType: 'EXTERN_RECIPIENT_FOR_ATTENTION',
          });
        }
      }
    }
    
    for (let i in this.state.forAttentionInternRecipient.raw) {
      let exists = false;
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === this.state.forAttentionInternRecipient.raw[i].recipientUuid) {
          exists = true;
        }
      }
      if (!exists) {
        let obj = this.state.forAttentionInternRecipient.raw[i];
        obj.recipientType = 'RECIPIENT_FOR_ATTENTION';
        letter.recipients.push(obj);
      }
    }
    
    // cc recipient
    // Pick the sorted list first
    for (let i in this.state.ccRecipientSorted.raw) {
      let obj = this.state.ccRecipientSorted.raw[i];
      if (obj.recipientType === 'RECIPIENT') {
        obj.recipientType = 'CC';
      }
      if (obj.recipientType === 'EXTERN_RECIPIENT') {
        obj.recipientType = 'EXTERN_CC_RECIPIENT';
      }
      lastOrder += 1;
      obj.recipientOrder = lastOrder;
      letter.recipients.push(obj);
    }
    // Then the recipients
    for (let i in this.state.ccRecipients.raw) {
      let exists = false;
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === this.state.ccRecipients.raw[i].recipientUuid) {
          exists = true;
        }
      }
      if (!exists) {
        let obj = this.state.ccRecipients.raw[i];
        obj.recipientType = 'CC';
        letter.recipients.push(obj);
      }
    }
    
    // Now the extern cc
    for (let i in this.state.externCcRecipients.raw) {
      let exists = false;
      let id = hash.sha256().update(this.state.externCcRecipients.raw[i]).digest('hex');
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === id) {
          exists = true;
        }
      }
      if (!exists) {
        lastOrder += 1;
        letter.recipients.push({
          recipientUuid: id,
          recipientName: this.state.externCcRecipients.raw[i],
          recipientType: 'EXTERN_CC_RECIPIENT',
          recipientOrder: lastOrder,
        });
      }
    }

    // Then the via recipients
    for (let i in letter.recipients) {
      if (letter.recipients[i].recipientType === 'VIA') {
        letter.recipients.splice(i, 1);
      }
    }
    for (let i in this.state.viaRecipients.raw) {
      let exists = false;
      for (let j in letter.recipients) {
        if (letter.recipients[j].recipientUuid === this.state.viaRecipients.raw[i].recipientUuid) {
          exists = true;
        }
      }
      if (!exists && this.state.viaRecipients.raw[i].recipientName) {
        lastOrder += 1;
        let obj = this.state.viaRecipients.raw[i];
        obj.recipientOrder = lastOrder;
        obj.recipientType = 'VIA';
        letter.recipients.push(obj);
      }
    }

    // Reassign order number
    let ordered = []
    for (let i in letter.recipients) {
      let obj = {
        recipientOrder: parseInt(i, 10) + 1,
      }
      let keys = Object.keys(letter.recipients[i]);
      for (let j in keys) {
        if (keys[j] === 'recipientOrder') continue;
        obj[keys[j]] = letter.recipients[i][keys[j]];
      }
      ordered.push(obj);
    }
    letter.recipients = ordered;

    // Elimenate recipient item that has no name
    for (let i in letter.recipients) {
      if (letter.recipients[i].recipientName.length < 1) {
        letter.recipients.splice(i, 1);
      }
    }

    // Elimenate extern via item that has no name
    for (let i in letter.externVia) {
      if (letter.externVia[i].length < 1) {
        letter.externVia.splice(i, 1);
      }
    }


    // TODO validate
    if (this.state.deadline) {
      letter.deadline = this.state.deadline.toISOString();
    }

    for (var i in this.state.files) {
      if (this.state.files[i].state === 'done' && !this.state.files[i].isInternalAttachment 
        && this.state.files[i].uuid && this.state.files[i].uuid.length === 36) {
        letter.attachments.push(this.state.files[i].uuid);
      } else if (this.state.files[i].state === 'done' && this.state.files[i].isInternalAttachment 
        && this.state.files[i].uuid && this.state.files[i].uuid.length === 36) {
        letter.internal_attachments.push(this.state.files[i].uuid)
      }
      
    }
    letter.numOfPrintedAttachments = letter.attachments.length;

    // Invitation
    if (letter.letterType === 'TNDE_UNDANGAN') {
      if (this.state.invitationStartDate) {
        letter.invitationStartDateTime = this.state.invitationStartDate;
        letter.invitationStartDateTime.setHours(0);
        letter.invitationStartDateTime.setMinutes(0);
        letter.invitationStartDateTime.setSeconds(0);
        if (this.state.invitationStartTime) {
          letter.invitationStartDateTime.setHours(this.state.invitationStartTime.getHours());
          letter.invitationStartDateTime.setMinutes(this.state.invitationStartTime.getMinutes());
        }
      }
      if (this.state.invitationEndDate) {
        letter.invitationEndDateTime = this.state.invitationEndDate;
        letter.invitationEndDateTime.setHours(0);
        letter.invitationEndDateTime.setMinutes(0);
        letter.invitationEndDateTime.setSeconds(0);
        if (this.state.invitationEndTime) {
          letter.invitationEndDateTime.setHours(this.state.invitationEndTime.getHours());
          letter.invitationEndDateTime.setMinutes(this.state.invitationEndTime.getMinutes());
        }
      } else {
        if (this.state.invitationStartDate && this.state.invitationEndTime) {
          letter.invitationEndDateTime = this.state.invitationStartDate;
          letter.invitationEndDateTime.setHours(0);
          letter.invitationEndDateTime.setMinutes(0);
          letter.invitationEndDateTime.setSeconds(0);
          letter.invitationEndDateTime.setHours(this.state.invitationEndTime.getHours());
          letter.invitationEndDateTime.setMinutes(this.state.invitationEndTime.getMinutes());
        }
      }
      letter.invitationVenue = this.state.invitationVenue;
      letter.invitationRapatRoom = this.state.invitationRapatRoom
    } else {
      letter.invitationStartDateTime = null;
      letter.invitationEndDateTime = null;
      letter.invitationVenue = null;
      letter.invitationapatRoom = null
    }

    letter.destination = this.state.destination;

    console.log('-----------------------------wrap result : ');
    console.log(letter);
    return letter;
  }


  sendConfirmation = () => {
    let incompleteUpload = false
    for (var i in this.state.files) {
      if (this.state.files[i].state === 'uploading') {
        incompleteUpload = true;
        break;
      }
    }
    if (incompleteUpload) {
      swal({
        title: '',
        type: 'warning',
        text: 'Unggahan berkas belum selesai, mohon ditunggu.',
      });
      return;
    }
    this.setState({ sendConfirmation: true });
  }
  send = () => {
    setTimeout(() => {
      this.setState({ action: 'Mengirim...', loading: true, sendConfirmation: false }, () => {
        let state = 'PROCESS';
        if (this.props.letterType === 'TNDE_MEMO') {
          state = 'SENT';
        }
        let letter = this.wrapLetter(state);
        if (this.state.query && this.state.query.uuid && this.state.query.uuid.length > 0) {
          letter.id = this.state.query.uuid;
          LetterService.updateLetter(letter)
            .then((lastInsertId) => {
              setTimeout(() => {
                this.setState({ sendSuccess: true, lastInsertId: letter.id, loading: false });
              }, 500)
            })
            .catch(() => {
              this.setState({ action: '', loading: false });
            });
        } else {
          LetterService.createLetter(letter)
            .then((lastInsertId) => {
              setTimeout(() => {
                this.setState({ sendSuccess: true, lastInsertId: lastInsertId, loading: false });
              }, 500)
            })
            .catch((err) => {
              this.setState({ action: '', loading: false }, () => {
                if (err.response.data && err.response.data.recipients && 
                  err.response.data.recipients.length > 0 && err.response.data.message === "invalid-recipients") {
                  this.invalidRecipients(err.response.data)
                }
              });
            });
        }
      });
    }, 500);
  }

  invalidRecipients(data) {
    let msg = '<p style="text-align:justify;margin-bottom: 5px;font-family: sans-serif;">Unit tidak ditemukan pada penerima berikut:</p>';
    data.recipients.forEach(val => { msg += `<p style='text-align:justify;font-family: sans-serif;'>- ${val}</p>\n` });
    msg += `<br/><p style="text-align:justify;font-family: sans-serif;">Silakan hubungi Administrator OA. 
            Anda baru dapat mengirim kembali surat ini setelah unit disetel oleh Administrator.</p>`;
    setTimeout(() => {
      swal({title: 'Surat Tidak Dapat Dikirim', text: msg, html: true})
    }, 1000)
  }

  saveConfirmation = () => {
    let incompleteUpload = false
    for (var i in this.state.files) {
      if (this.state.files[i].state === 'uploading') {
        incompleteUpload = true;
        break;
      }
    }
    if (incompleteUpload) {
      swal({
        title: '',
        type: 'warning',
        text: 'Unggahan berkas belum selesai, mohon ditunggu.',
      });
      return;
    }
    this.setState({ saveConfirmation: true });
  }
  updateInProcessConfirmation = () => {
    this.setState({ updateInProcessConfirmation: true });
  }
  discardConfirmation = () => {
    this.setState({ discardConfirmation: true });
  }
  discard = () => {
    this.setState({ action: 'Menghapus...', loading: true, discardConfirmation: false }, () => {
      LetterService.deleteLetter(this.state.query.uuid)
        .then(() => {
          setTimeout(() => {
            this.reset(defaultState, () => {
              this.setState({ query: {} });
              // Should be back to processing/draft
              window.browserHistory.push('/processing/draft');
            })
          }, 500)
        })
        .catch(() => {
          this.setState({ action: '', loading: false });
        });
    });
  }

  back = () => {
    window.history.back();
  }

  updateInProcess = () => {
    this.setState({ action: 'Menyimpan...', loading: true, updateInProcessConfirmation: false }, () => {
      let letter = this.wrapLetter('PROCESS');
      if (this.state.query.uuid && this.state.query.uuid.length > 0) {
        letter.id = this.state.query.uuid;
        LetterService.updateLetter(letter)
          .then(() => {
            setTimeout(() => {
              this.setState({ updateInProcessSuccess: true, lastInsertId: letter.id, loading: false });
            }, 500)
          })
          .catch(() => {
            this.setState({ action: '', loading: false });
          });
      } else {
        this.setState({ action: '', loading: false });
      }
    })
  }

  save = () => {
    setTimeout(() => {
      this.setState({ action: 'Menyimpan...', loading: true, saveConfirmation: false }, () => {
        let letter = this.wrapLetter('DRAFT');
        if (this.state.query && this.state.query.uuid && this.state.query.uuid.length > 0) {
          letter.id = this.state.query.uuid;
          LetterService.updateLetter(letter)
            .then(() => {
              setTimeout(() => {
                this.setState({ saveSuccess: true, lastInsertId: letter.id, loading: false });
              }, 500)
            })
            .catch(() => {
              this.setState({ action: '', loading: false });
            });
        } else {
          LetterService.createLetter(letter)
            .then((lastInsertId) => {
              setTimeout(() => {
                this.setState({ saveSuccess: true, lastInsertId: lastInsertId, loading: false });
              }, 500)
            })
            .catch(() => {
              this.setState({ action: '', loading: false });
            });
        }
      })
    }, 500);
  }

  reset = (state, cb) => {
    this.setState(state, () => {
      let files = this.state.files;
      files.splice(0, files.length);
      this.setState({ files: files, letterType: this.props.letterType }, () => {
        // Simulate empty inputEventHandler
        let e = { target: { name: 'loading', value: false } }
        this.inputEventHandler(e);
        if (cb) cb();
      });
    });
  }

  onSentSuccessOk = () => {
    this.setState({ sendSuccess: false, saveSuccess: false });
    this.reset(defaultState);
    if (this.props.letterType === 'MANUAL') {
      window.browserHistory.push('/outbox/manual');
    } else {
      window.browserHistory.push('/read/' + this.state.lastInsertId + '?action=processStatus');
    }
  }
  onSavedSuccessOk = () => {
    this.setState({ sendSuccess: false, saveSuccess: false });
    this.reset(defaultState);
    window.browserHistory.push('/read/' + this.state.lastInsertId + '?action=draftView');
  }
  onUpdateInProcessSuccessOk = () => {
    this.setState({ sendSuccess: false, updateInProcessSuccess: false });
    this.reset(defaultState);
    this.back();
    window.browserHistory.push('/read/' + (this.state.query.uuid || this.state.lastInsertId) + '?action=process');
  }

  populateValues = () => {
    this.setState({ currentTab: tabMap["FormAtribut"] });
    let defaultValues = JSON.parse(JSON.stringify(defaultState));
    if ( this.props.letterType === 'MANUAL') {
      defaultValues.number = `${lorem.generateWords(2)}`;
      defaultValues.letterDate = new Date();
      defaultValues.manualLetterSender = `${lorem.generateWords(2)} ceb`;
      defaultValues.customAttachment = `${lorem.generateWords(2)}`;
    } else {
      defaultValues.contents = `${lorem.generateSentences(3)}`;
      defaultValues.contentAbstract = `${lorem.generateSentences(3)}`;
      defaultValues.notes = `${lorem.generateSentences(3)}`;
    }
    defaultValues.subject = `${lorem.generateWords(2)} ${(new Date()).valueOf()}`;
    this.reset(defaultValues, () => {
      this.setState({ currentTab: tabMap["FormInformasiSurat"] });
      alert("Some fields has been populated with random values.");
    })
  }

  render() {
    return (
      <div>
        <SwAlert
          show={this.state.sendConfirmation}
          title="Kirim"
          text={'Apakah Anda yakin ingin mengirim surat ini?'}
          showCancelButton
          onConfirm={this.send}
          onCancel={() => { this.setState({ sendConfirmation: false }) }}
        />
        <SwAlert
          show={this.state.saveConfirmation}
          title={this.state.isOriginator && this.state.letterState === 'RETURNED' ? "Simpan" : "Simpan ke Draf"}
          text={this.state.isOriginator && this.state.letterState === 'RETURNED' ? 'Apakah Anda yakin ingin menyimpan ini ?' : 'Apakah Anda yakin ingin menyimpan ini ke draf?'}
          showCancelButton
          onConfirm={this.save}
          onCancel={() => { this.setState({ saveConfirmation: false }) }}
        />
        <SwAlert
          show={this.state.updateInProcessConfirmation}
          title="Simpan"
          text={'Apakah Anda yakin ingin menyimpan surat ini?'}
          showCancelButton
          onConfirm={this.updateInProcess}
          onCancel={() => { this.setState({ updateInProcessConfirmation: false }) }}
        />
        <SwAlert
          show={this.state.discardConfirmation}
          title="Hapus draf"
          text={'Apakah Anda yakin ingin menghapus draf ini?'}
          showCancelButton
          onConfirm={this.discard}
          onCancel={() => { this.setState({ discardConfirmation: false }) }}
        />
        <SwAlert
          show={this.state.sendSuccess}
          title=""
          onConfirm={this.onSentSuccessOk}
          text={this.props.letterType === 'MANUAL' ? 'Surat manual telah disimpan dan dikirim ke tujuan.' : this.props.letterType === 'TNDE_MEMO' ? 'Memo telah terkirim.' : 'Surat telah terkirim dan masuk dalam proses.'}
        />
        <SwAlert
          show={this.state.saveSuccess}
          title=""
          onConfirm={this.onSavedSuccessOk}
          text={'Surat telah disimpan.'}
        />
        <SwAlert
          show={this.state.updateInProcessSuccess}
          title=""
          onConfirm={this.onUpdateInProcessSuccessOk}
          text={'Surat telah disimpan.'}
        />
        {this.state.loading ?
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <p>{this.state.action}</p>
            <Spinner />
          </div>
          :
          <div>
            {this.state.mode === 'form' &&
              <div className="row">
                <div className="col-md-12">
                  <div style={{ display: 'inline-block', verticalAlign: 'top', margin: 5, marginLeft: 0 }}>
                    <Fa.FaChevronLeft size={24} color={'#3c8dbc'} onClick={this.back} style={{ cursor: 'pointer' }} />
                  </div>
                  <div style={{ display: 'inline-block' }}>
                    <h4>
                      {this.props.letterType === 'MANUAL' && 'Buat Surat Masuk Manual'}
                      {this.props.letterType === 'TNDE_NOTA_DINAS' && 'Nota Dinas'}
                      {this.props.letterType === 'TNDE_SURAT_TUGAS' && 'Surat Tugas'}
                      {this.props.letterType === 'TNDE_SURAT' && 'Surat'}
                      {this.props.letterType === 'TNDE_UNDANGAN' && 'Surat Undangan'}
                      {this.props.letterType === 'TNDE_MEMO' && 'Memo'}
                      {this.state.replyReference && <span> : Balas Surat <a target="_blank" href={'/read/' + this.state.replyReference}>{this.state.replyReferenceNumber}</a></span>}
                    </h4>
                  </div>
                  <div className="create-letter-buttons" style={{ marginTop: 15 }}>
                    {this.props.isTesterMode &&
                      <button className="btn letter-btn btn-warning" onClick={() => { this.populateValues() }}><Fa.FaEdit /> Generate random values for some fields</button>
                    }
                    {((!this.state.isInProcessChain || this.state.isOriginator) || (this.state.query && this.state.query.recompose)) && this.props.letterType !== 'MANUAL' && !this.state.isBundlerProcChainHolder &&
                      <button className="btn btn-primary btn-sm"
                        onClick={this.sendConfirmation}
                        disabled={!(this.state.tabNav.FormInformasiSurat.completed
                          && this.state.tabNav.FormPenjelasanIsiSurat.completed
                          && this.state.tabNav.FormIsiSurat.completed
                          && this.state.tabNav.FormAtribut.completed)}
                      >
                        <Fa.FaPaperPlaneO />&nbsp;Kirim
              </button>
                    }
                    {this.props.letterType === 'MANUAL' && !this.state.isBundlerProcChainHolder &&
                      <button className="btn btn-primary btn-sm"
                        onClick={this.sendConfirmation}
                        disabled={!(
                          (this.state.manualLetterSender && this.state.manualLetterSender.length > 0)
                          && (this.state.classification && this.state.classification.raw && this.state.classification.raw.length > 0)
                          && (this.state.subject && this.state.subject.length > 0)
                          && (this.state.recipients && this.state.recipients && this.state.recipients.raw.length > 0)
                          && (this.state.number && this.state.number.length > 0)
                          && (this.state.letterDate && this.state.letterDate.toISOString().length > 0)
                          && (this.state.files && this.state.files.length > 0)
                        )}
                      >
                        <Fa.FaPaperPlaneO />&nbsp;Kirim
              </button>
                    }
                    &nbsp;
              {(this.state.isInProcessChain || this.state.isBundlerProcChainHolder) && this.props.letterType !== 'MANUAL' && !(this.state.query && this.state.query.recompose) &&
                      <button className="btn btn-success btn-sm"
                        onClick={this.updateInProcessConfirmation}
                      >
                        <Fa.FaFloppyO />&nbsp;Simpan
              </button>
                    }
                    {(!(this.state.isInProcessChain || this.state.isBundlerProcChainHolder) || (this.state.query && this.state.query.recompose)) && this.props.letterType !== 'MANUAL' &&

                      <button className="btn btn-success btn-sm"
                        onClick={this.saveConfirmation}
                      >
                        <Fa.FaFloppyO />&nbsp;Simpan
              </button>
                    }
                    &nbsp;
			  {(!this.state.isInProcessChain || this.state.isOriginator) && this.state.query.uuid && this.state.query.uuid.length > 0 && !this.state.isBundlerProcChainHolder &&
                      <button className="btn btn-danger btn-sm"
                        onClick={this.discardConfirmation}
                        disabled={!(this.state.query.uuid)}
                      >
                        <Fa.FaTrashO />&nbsp;Hapus
              </button>}
                  </div>
                  &nbsp;
		    <div className="box box-success">
                    <div className="box-body">
                      {this.props.letterType === 'MANUAL' ?
                        <div>
                          <FormSuratMasukManual
                            parentState={this.state}
                            letterType={this.props.letterType}
                            inputEventHandler={this.inputEventHandler}
                            setParentState={this.setStateWrapper}
                            attachmentHandler={this.attachmentHandler}
                          />
                        </div>
                        :
                        <div>
                          <Tab nav={this.state.tabNav} handler={this.switchTab} />
                          <this.state.currentTab
                            parentState={this.state}
                            letterType={this.props.letterType}
                            inputEventHandler={this.inputEventHandler}
                            setParentState={this.setStateWrapper}
                            attachmentHandler={this.attachmentHandler}
                            currentUser={this.state.currentUser}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

const Compose = ({ match, testerMode }) => (
  <ComposeComponent isTesterMode={testerMode} letterType={match.params.letterType} />
)

export default connect(mapper)(Compose);
