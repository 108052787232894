import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import AppRouter from './AppRouter'
import {
  loggedIn,
  loggedOut,
  showPageTitle,
  toggleSidebar,
  stats,
  enableTesterMode
} from './modules/rootReducers';

import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../node_modules/admin-lte/dist/css/skins/_all-skins.min.css'

import './index.css'
import Config from './config'

const target = document.querySelector('#root')

window.store = store;
window.browserHistory = history;
window.actions = { 
  loggedIn : loggedIn, 
  loggedOut : loggedOut, 
  showPageTitle : showPageTitle,
  toggleSidebar : toggleSidebar,
  stats : stats,
  enableTesterMode : enableTesterMode,
}

window.enableTesterMode = () => {
    store.dispatch(enableTesterMode())
}

store.subscribe((state) => {
	// New state!
  //console.log(state);
});

document.title = Config.variant.applicationTitle || "Surelintas"

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <AppRouter />
    </ConnectedRouter>
  </Provider>,
  target
)

// Register the service-worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw-oa.js')
      .then(registration => {
        console.log(`SW worked`);
      })
      .catch(err => {
        console.log(`There's something wrong: ${err}`);
      });
  });
}