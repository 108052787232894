import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import Spinner from '../../../components/Spinner';
import './styles.css';
import LetterRefCounter from '../../../services/LetterRefCounter';
import swal from 'sweetalert';

const LetterRefCounterService = new LetterRefCounter()

export default class AddressBookModal extends Component {
  state = {
    showModal: false,
    loading: false,
  }

  componentDidMount = () => {
  }

  showModal = () => {
    this.setState({ loading: true, showModal: true }, () => {
      LetterRefCounterService.get(this.props.id)
      .then((result) => {
        this.setState({ loading: false })
        let obj = {}
        for (let i in result.data) {
          obj[result.data[i].letterCode] = result.data[i].counter || 0
        }
        this.setState(obj)
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loading: false })
      })
    })
  }

  hideModal = () => {
    this.setState({ showModal: false });
  }

  onSave = () => {
    let payload = {
      data: [{
        letterCode: 'ND',
        counter: parseInt(this.state.ND, 10)
      },{
        letterCode: 'ST',
        counter: parseInt(this.state.ST, 10)
      },{
        letterCode: 'UND',
        counter: parseInt(this.state.UND, 10)
      },{
        letterCode: 'S',
        counter: parseInt(this.state.S, 10)
      }]
    }
    this.setState({ loading: true }, () => {
      LetterRefCounterService.upsert(payload, this.props.id)
      .then((result) => {
        swal({
          title: '',
          text: 'Berhasil Menyimpan',
          closeOnClickOutside: true,
          closeOnEsc: true,
          type: 'success'
        })
        this.setState({ loading: false, showModal: false })
      })
      .catch((err) => {
        let msg = `<p style="text-align:center;margin-bottom: 5px;font-family: sans-serif;"> Gagal Menyimpan </p>
                          <p style="text-align:center;font-family: sans-serif;">Silahkan isi kode jabatan terlebih dahulu</p>`;
              if (err.response.data.message.indexOf('Please fill the kode jabatan first') > -1) {
                swal({
                  title: '',
                  text: msg,
                  type: 'error',
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                  html: true
                })
              } else {
                swal({
                  title: '',
                  text: 'Gagal Menyimpan',
                  closeOnClickOutside: true,
                  closeOnEsc: true,
                  type: 'error',
                })
              }
        this.setState({ loading: false, showModal: false })
        console.log(err)
      })
    })
  }

  render() {
    return (
      <span>
        <button type="button" onClick={this.showModal} className={this.props.primary ? "btn btn-primary" : "btn btn-default"}>
          <Fa.FaCog /> {this.props.text}
        </button>
        <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Setel Nomor Surat
                </h4>
                <hr />
              </div>
              <div className="modal-body">
                <div className="box box-success" style={{ marginTop: 15 }}>
                  <div className="box box-body">
                    {this.state.loading ? <Spinner/> :
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-sm-3">Nota Dinas</label>
                            <div className="col-sm-9" >
                              <input
                                className="form-control"
                                name="Nota Dinas"
                                placeholder="Nota Dinas"
                                value={this.state.ND || 0}
                                onChange={(e) => { this.setState({ ND: e.target.value }) }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-sm-3">Surat Tugas</label>
                            <div className="col-sm-9" >
                              <input
                                className="form-control"
                                name="Surat Tugas"
                                placeholder="Surat Tugas"
                                value={this.state.ST || 0}
                                onChange={(e) => { this.setState({ ST: e.target.value }) }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-sm-3">Surat Undangan</label>
                            <div className="col-sm-9" >
                              <input
                                className="form-control"
                                name="Surat Undangan"
                                placeholder="Surat Undangan"
                                value={this.state.UND || 0}
                                onChange={(e) => { this.setState({ UND: e.target.value }) }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label style={{ textAlign: 'right' }} className="col-sm-3">Surat</label>
                            <div className="col-sm-9" >
                              <input
                                className="form-control"
                                name="Surat"
                                placeholder="Surat"
                                value={this.state.S || 0}
                                onChange={(e) => { this.setState({ S: e.target.value }) }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span className="btn btn-default" onClick={this.hideModal}>Batal</span>
                <span className="btn btn-primary" onClick={this.onSave}>Simpan</span>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  }
}
