import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import * as Fa from 'react-icons/lib/fa';
import 'flatpickr/dist/themes/light.css';
import 'flatpickr/dist/l10n/id';

import './styles.css'

export default class DateTimePicker extends Component {
  state = {
    value : '',
  }

  componentDidMount = () => {
    this.setState({value : this.props.value});
  }

  updateDate = (name, value) => {
    if (value.length < 1) {
      return;
    }
    let obj = {
      target : {
        name : name,
        value : value[0],
      }
    }
    this.setState({value : obj.target.value});
    this.props.onChange(obj);
  }

  clearDate = () => {
    let obj = {
      target : {
        name : this.props.name,
        value : '',
      }
    }
    this.setState({value : ''});
    this.props.onChange(obj);
  }

  render() {
    const { 
      label,
      name,
      value,
      required,
      labelWidth,
      fieldWidth,
      additionalLabel,
      timeOnly,
      disabled,
      style,
    } = this.props;
    return (
      <div className="form-group row" style={style ? style : {}}>
        {label && 
        <label className={`col-md-${labelWidth ? labelWidth.toString() : '1'} col-sm-${labelWidth ? labelWidth.toString() : '2'} control-label`}>
          {label}
          &nbsp;<span className="red">{required && '*'}</span>
        </label>
        }
        <div className={`datetimepicker-container col-md-${fieldWidth ? fieldWidth.toString() : '10'} col-sm-${fieldWidth ? fieldWidth.toString() : '10'}`}>
          { !timeOnly ? 
          <span className="input-group-addon datetimepicker-calendar-icon">
            {this.state.value && <Fa.FaClose onClick={this.clearDate}/>}
            <Fa.FaCalendar/>
          </span>
          :
          <span className="input-group-addon datetimepicker-clock-icon">
            <Fa.FaClockO/>
          </span>
          }
          { timeOnly ? 
            <Flatpickr
              name={name}
              value={value}
              disabled={disabled}
              
              onChange={(val) => {
                console.log(val);
                this.updateDate(name, val);
              }}
              onClick={() => {
                if (value) {
                  return;
                }
                let val = new Date()
                val.setHours(12);
                val.setMinutes(0);
                val.setSeconds(0);
                this.updateDate(name, [val]);
              }}
              style={{paddingLeft:35, width:100}}
              className="form-control"
              options={{locale: 'id', time_24hr: true, noCalendar : timeOnly, enableTime : timeOnly, allowInput:true}}
            /> 
            : 
            <Flatpickr
              name={name}
              value={value}
              disabled={disabled}
              onChange={(val) => {
                this.updateDate(name, val);
              }}
              options={{locale: 'id', time_24hr: true, altInput : true, altFormat : "l, j F Y", dateFormat:"Y-m-d",allowInput:true}}
            /> 
          }
          { additionalLabel ? <span style={{float:'left', color:'red'}}>{additionalLabel}</span> : '' }
        </div>
      </div>
    );
  }
}
