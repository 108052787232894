import React, { Component } from 'react';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';
import Letter from '../../../services/Letter';

const LetterService = new Letter();

export default class ProcessActionModal extends Component {
  state = {
    showModal: false,
    loading: false,
    notes: '',
    pin: '',
  }
  componentDidMount = () => {
    this.setState({ loading: false, notes: null, pin: null });
    this.showModal();
  }
  componentWillMount = () => {
    this.setState({ loading: false, notes: null, pin: null });
  }

  showModal = () => {
    this.setState({ showModal: true });
    setTimeout(() => {
      this.pinInputElement.focus();
    }, 500);
  }
  hideModal = () => {
    this.setState({ showModal: false });
    this.props.onCanceled();
  }

  ok = () => {
    if (!this.state.pin || (this.state.pin && this.state.pin.length < 1)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Silakan masukkan PIN terlebih dahulu.',
      });
      return;
    }
    this.setState({ loading: true }, () => {
      let payload = this.props.processing;
      payload.state = this.props.action;
      payload.pin = this.state.pin;
      payload.notes = this.state.notes;
      payload.isCurrentUserAPelaksana = this.props.currentLetter.isCurrentUserAPelaksana;
      payload.currentUserPelaksanaType = this.props.currentLetter.currentUserPelaksanaType;
      LetterService.processLetter(this.props.uuid, payload)
        .then((result) => {
          this.props.onProcessed(this.props.action);
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          if (err && (err.message === 'Request failed with status code 401' || err.message === 'Request failed with status code 403')) {
            swal({
              title: '',
              type: 'warning',
              text: 'PIN yang dimasukkan tidak benar.',
            });
          }
          if (err &&
            err.message === 'Request failed with status code 409' &&
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message === 'You are not in charge for this letter\'s current process. Please forward the bundler letter instead.') {
            swal({
              title: '',
              type: 'warning',
              text: 'Surat ini dihantarkan oleh surat lain. Teruskan surat pengantarnya untuk meneruskan surat ini.',
            });
            window.browserHistory.push('/processing/need-approval');
          } else if (err && (err.message === 'Request failed with status code 409')) {
            swal({
              title: '',
              type: 'warning',
              text: 'Surat ini mungkin sudah ditarik atau Anda tidak memiliki hak untuk melakukan operasi ini.',
            });
            window.browserHistory.push('/processing/need-approval');
          }
        });
    });
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.ok();
    }
  }

  render() {
    return (
      <div className="modal modal-open" style={{ display: this.state.showModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="notes">Masukkan komentar</label>
                <textarea
                  className="form-control"
                  type="text"
                  name="notes"
                  aria-describedby="notesHelp"
                  placeholder="Komentar"
                  value={this.state.notes? this.state.notes: '' }
                  onChange={(e) => { this.setState({ notes: e.target.value }) }}
                  onKeyPress={this.onKeyPress}
                />
              </div>
              <div className="form-group">
                <label htmlFor="pin">PIN</label>
                <input
                  className="form-control"
                  type="password"
                  name="pin"
                  aria-describedby="pinHelp"
                  placeholder="PIN"
                  value={this.state.pin? this.state.pin: ''}
                  onChange={(e) => { this.setState({ pin: e.target.value }) }}
                  disabled={this.loading}
                  onKeyPress={this.onKeyPress}
                  ref={pinInputElement => this.pinInputElement = pinInputElement}
                  autoFocus
                />
              </div>
            </div>
            <div className="modal-footer">
              {this.state.loading ?
                <Spinner />
                :
                <div>
                  <span className={'btn ' + ((this.props.action === 'CANCELED') ? 'btn-danger' : 'btn-primary')} style={{ marginRight: 10 }} onClick={this.ok} disabled={(!this.state.pin || (this.state.pin && this.state.pin.length < 1))}>
                    {this.props.action === 'DONE' && 'Setujui'}
                    {this.props.action === 'REJECT' && 'Kembalikan'}
                    {this.props.action === 'CANCELED' && 'Batalkan'}
                  </span>
                  <span className="btn btn-default" onClick={this.hideModal}>Cancel</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
