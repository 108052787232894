import Config from '../config';
import Letter from './Letter';
import Cache from './Cache';


const LetterService = new Letter()
const CacheService = new Cache()
const subscribers = {}

class WS {

  constructor() {
    console.log("WS constructed")
  }

  init = () => {

    console.log("WS Init")
    let currentUser = JSON.parse(localStorage.getItem('current_user'));
    if (!currentUser) {
      return;
    }
    let url = `${Config.wsHost}?userId=${currentUser.id}`;
    this.clearConnection();
    // Wait a bit after get closed
    setTimeout(() => {
      window.oaWS = null;
      try {
        window.oaWS = new window.WebSocket(url);
        window.oaWS.addEventListener('close', this.onClose);
        window.oaWS.addEventListener('open', this.onOpen);
        window.oaWS.addEventListener('error', this.onError);
        window.oaWS.addEventListener('message', this.onMessage);

        window.addEventListener('beforeunload', () => {
          this.clearConnection();
        });
      } catch (e) {
        // Retry on 5 seconds
        setTimeout(() => {
          this.init();
        }, 5000);
      }
    }, 1000);
  }

  clearConnection = () => {
    if (window.oaWS) {
      // Clean all existing connection
      window.oaWS.removeEventListener('close', this.onClose);
      window.oaWS.removeEventListener('open', this.onOpen);
      window.oaWS.removeEventListener('error', this.onError);
      window.oaWS.removeEventListener('message', this.onMessage);
      window.oaWS.close();
    }
  }

  sub = (message, fn) => {
    var subs = subscribers[message]
    if (subs) {
      subs.push(fn)
    } else {
      subs = []
      subs.push(fn)
    }
    subscribers[message] = subs
  }

  runSubs = (message, body) => {
    const subs = subscribers[message]
    console.log(subs)

    if (typeof subs !== 'object') {
      console.log("No subs")
      return
    }
    for (var fn of subs) {
      if (typeof fn === 'function') {
        console.log("Running hndler for", message, fn)
        fn(body)
      }
    }
  }

  onMessage = (payload) => {
    console.log("onmessage", payload)
    try {
      const body = JSON.parse(payload.data).body
      var message = body
      if (body === 'authenticated') {
        console.log('Websocket conn authenticated.')   
      } else if (body === 'fetchNotification') {
        LetterService.getStats();
      } else if (body.startsWith('chatNotification:')) {
        message = body.split(':')[0]
      } else if (body.startsWith('cacheUpgrade:')) {
        const cache_type = body.split(':')[1]
        const cache_version = body.replace('cacheUpgrade:addressbook:', '')
        
        CacheService.getCacheVersionFromStorage(cache_type)
        .then((version) => {
          console.log('Caching.')
          const old_version = new Date(version)
          const new_version = new Date(`${cache_version}`)
          
          if (old_version < new_version) CacheService.cachingByType(cache_type);
          
        })
        .catch((err) => console.error("Error while loaded cache versions. Err: "+ err));
      }
      
      if (!body.startsWith('cacheUpgrade:')) this.runSubs(message, body);

    } catch (e) {
      console.log('Failed to parse websocket message data', e);
    }
  }

  onOpen = () => {
    console.log('WebSocket connection opened. Authenticating...');
    // Give proof to the websocket that we are an authorized user
    window.oaWS.send('{"userId":"' + JSON.parse(localStorage.getItem('current_user')).id + '","token":"' + localStorage.getItem('access_token').split(' ')[1] + '"}')
  }

  onError = () => {
    console.log('WebSocket connection error.');
  }

  onClose = () => {
    console.log('WebSocket connection closed.');
    setTimeout(() => {
      this.init();
    }, 5000);
  }

  close = () => {
    window.oaWS.close();
  }
}

export default WS;
