import React, { Component } from 'react';
import Blob from '../../../services/Blob';
import Spinner from '../../../components/Spinner';
import PDF from 'react-pdf-js';
import * as Fa from 'react-icons/lib/fa';

const BlobService = new Blob();

export default class AttachmentViewer extends Component {
  state = {
    url: '',
    page: 1,
    loading: true,
  }

  componentDidMount = () => {
    this.load();
  }

  load = () => {
    this.setState({ loading: true }, () => {
      if (this.props.url && this.props.url.startsWith("blob:") === false) {
        BlobService.downloadToDataURI(this.props.url)
          .then((res) => {
            this.setState({ url: res.uri, loading: false });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.setState({ url: this.props.url, loading: false });
      }
    });
  }

  onDocumentComplete = (pages) => {
    this.setState({ page: 1, pages });
  }

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  }

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  }

  renderPagination = (page, pages) => {
    let previousButton = <li className="pdf-viewer-previous"><button className="btn btn-primary" onClick={this.handlePrevious}><Fa.FaArrowLeft /></button></li>;
    if (page === 1) {
      previousButton = <li className="pdf-viewer-previous"><button className="btn btn-primary disabled"><Fa.FaArrowLeft /></button></li>;
    }
    let nextButton = <li className="pdf-viewer-next"><button className="btn btn-primary" onClick={this.handleNext}><Fa.FaArrowRight /></button></li>;;
    if (page === pages) {
      nextButton = <li className="pdf-viewer-next"><button className="btn btn-primary disabled"><Fa.FaArrowRight /></button></li>;;
    }
    return (
      <nav className="pdf-viewer">
        <ul className="pdf-viewer-pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  }

  render() {
    let pagination = null;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }
    return (this.state.loading ? <Spinner /> :
      <div style={{ textAlign: 'center' }}>
        {this.props.type === 'application/pdf' ?
          <div><PDF
            file={this.state.url}
            onDocumentComplete={this.onDocumentComplete}
            page={this.state.page}
            scale={1.5}
          />
            {pagination}</div>
          :
          <img alt="attachment" src={this.state.url} style={{ width: '100%' }} />
        }
      </div>
    )
  }
}
