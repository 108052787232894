import React, { Component } from 'react';
import * as Fa from 'react-icons/lib/fa';
import swal from 'sweetalert';
import Spinner from '../../../components/Spinner';
import Classification from '../../../services/Classification';
import Auth from '../../../services/Auth';


const ClassificationService = new Classification();
const AuthService = new Auth();

export default class LetterClassification extends Component {
  state = {
    userData: [],
    data: [],
    loading: false,
    mode: 'structure',

    name: '',
  }

  componentDidMount = () => {
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      this.load();
    });
  }

  load = () => {
    this.setState({
      loading: true,
    }, () => {
      ClassificationService.getAll()
        .then((result) => {
          let data = result.data;
          let sorted = [];

          function findChildsRecursive(id, level, arr) {
            let childs = [];
            for (var i in arr) {
              if (arr[i].parent === id) {
                arr[i].level = level + 1;
                arr[i].visibility = true;
                arr[i].hasChild = false;
                childs.push(arr[i]);
                let c = findChildsRecursive(arr[i].id, level + 1, arr);
                for (var j in c) {
                  arr[i].hasChild = true;
                  childs.push(c[j]);
                }
              }
            }
            return childs;
          }
          // Root
          for (var i in data) {
            if (!data[i].parent) {
              data[i].level = 0;
              data[i].visibility = true;
              data[i].collapse = false;
              data[i].hashChild = true;
              sorted.push(data[i]);
              break;
            }
          }
          // The entire childs, sorted
          let childs = findChildsRecursive(sorted[0].id, sorted[0].level, data);
          if (childs.length > 0) {
            for (var j in childs) {
              sorted.push(childs[j]);
            }
          }
          console.log(sorted);
          this.setState({ data: sorted, loading: false });
        })
        .catch((err) => {
          this.setState({
            data: [],
            loading: false,
          });
        });
    });
  }

  delete = (item) => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus klasifikasi ' + item.name + '?',
    }, (ok) => {
      if (ok) {
        ClassificationService.delete(item.id)
          .then((result) => {
            this.setState({ loading: false });
            this.load();
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    });
  }

  collapseAllChild = (data, parentId) => {
    for (var i in data) {
      if (data[i].parent === parentId) {
        data[i].collapse = true;
        data[i].visibility = false;
        if (data[i].hasChild) {
          this.collapseAllChild(data, data[i].id);
        }
      }
    }
  }

  handleClick = (index) => {
    if (!this.state.data[index].hasChild) {
      return;
    }
    this.setState((prev) => {
      let s = {};
      prev.data[index].current = true;
      if (index === 0 && !prev.data[index].collapse) {
        s = prev.data;
        return s;
      }
      for (var i in prev.data) {
        if (prev.data[i].parent === prev.data[index].id) {
          prev.data[i].visibility = prev.data[index].collapse;
          if (prev.data[i].hasChild) prev.data[i].collapse = true;
          if (!prev.data[index].collapse) {
            this.collapseAllChild(prev.data, prev.data[index].id);
          }
        }
      }
      prev.data[index].collapse = !prev.data[index].collapse;
      s.data = prev.data
      return s;
    });
  }

  newItem = (parent) => {
    this.setState({
      mode: 'new',
      currentParent: parent,
      parent: null,
      name: '',
      currentId: null,
    });
  }

  editItem = (item) => {
    let keys = Object.keys(item);
    this.setState(item, () => {
      this.setState({ mode: 'edit', fields: keys });
    });
  }
  back = (parent) => {
    this.setState({ mode: 'structure', form: {} });
  }

  create = () => {
    let payload = {
      parent: this.state.currentParent ? this.state.currentParent.id : null,
      name: this.state.name,
    }
    if (!payload.name) {
      swal({
        title: '',
        text: 'Mohon lengkapi isian yang diwajibkan.'
      })
      return;
    }
    this.setState({ loading: true }, () => {
      ClassificationService.create(payload)
        .then((result) => {
          this.setState({ loading: false, mode: 'structure' });
          this.load();
        })
        .catch((result) => {
          this.setState({ loading: false });
        })
    });
  }

  update = (id, payload) => {
    if (!id && !payload) {
      payload = {}
      id = this.state.id;
      for (var i in this.state.fields) {
        if (this.state.fields[i] === 'visibility' || this.state.fields[i] === 'level' || this.state.fields[i] === 'hasChild') {
          continue;
        }
        payload[this.state.fields[i]] = this.state[this.state.fields[i]];
      }
    }
    this.setState({ loading: true }, () => {
      ClassificationService.update(id, payload)
        .then((result) => {
          this.setState({ loading: false, mode: 'structure' });
          this.load();
        })
        .catch((result) => {
          this.setState({ loading: false });
        })
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {(!this.state.data || (this.state.data && this.state.data.length === 0)) &&
          <div style={{ float: 'right' }}>
          </div>
          }
          <h4>Klasifikasi Surat</h4>
          <div className="box box-success">
            <div className="box-body" style={{ padding: 0 }}>
              {this.state.mode === 'structure' &&
                <div>
                  {(!this.state.data || (this.state.data && this.state.data.length === 0)) && !this.state.loading &&

                    <div style={{margin:'0 auto', textAlign:'center', width:'100%'}}>
                      Belum ada klasifikasi<br/><br/>
                      <span className="btn btn-default" onClick={() => { this.newItem() }}>
                        <Fa.FaPlus /> Tambah item klasifikasi puncak
                      </span>
                    </div>
                  }
                  {this.state.loading ?
                    <Spinner /> :
                    <ul>
                      {Object.keys(this.state.data).map((item, index) => {
                        return <li
                          key={index}
                          style={{
                            padding: 10,
                            display: this.state.data[item].visibility ? 'block' : 'none',
                            paddingLeft: this.state.data[item].level === 0 ||
                              this.state.data[item].filtered ? 15 :
                                this.state.data[item].level === 1 ? 30 : (
                                  this.state.data[item].level === 2 ? 45 : (
                                    this.state.data[item].level === 3 ? 60 : (
                                      this.state.data[item].level === 4 ? 75 : (
                                        this.state.data[item].level === 5 ? 90 : (
                                          this.state.data[item].level === 6 ? 105 : (
                                            this.state.data[item].level === 7 ? 105 : (
                                              this.state.data[item].level === 8 ? 120 : (
                                                this.state.data[item].level === 9 ? 135 : 150 )))))))),
                            background: !this.state.data[item].current ? 'none' : '#F7F7F7',
                          }}
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => { this.handleClick(index) }}
                          >
                            {!this.state.data[item].hasChild ? '' :
                              !this.state.data[item].collapse ? <Fa.FaCaretDown /> :
                                this.state.data[item].hasChild && <Fa.FaCaretRight />
                            }
                            &nbsp;
                          <b>{this.state.data[item].name}</b>
                          </span>
                          &nbsp;&nbsp;
                        <button
                            className="btn btn-default btn-sm btn-sm-extra" style={{ width: 100 }}
                            onClick={() => { this.newItem(this.state.data[item]) }}
                          >
                            <Fa.FaPlus /> Tambah sub
                        </button>
                          {!this.state.data[item].locked &&
                          <span>
                            {!this.state.data[item].hasChild &&
                              <button
                                className={'btn btn-danger btn-sm btn-sm-extra ' + (this.state.data[item].hasChild && 'disabled')} style={{ float: 'right' }}
                                onClick={() => { this.delete(this.state.data[item]) }}
                              >
                                <Fa.FaTrashO />
                              </button>
                            }
                            <button
                              className={'btn btn-default btn-sm btn-sm-extra'} style={{ float: 'right' }}
                              onClick={() => { this.editItem(this.state.data[item]) }}
                            >
                              <Fa.FaEdit />
                            </button>
                          </span>
                          }
                        </li>
                      })}
                    </ul>
                  }
                </div>
              }
              {(this.state.mode === 'new' || this.state.mode === 'edit') &&
                <div className="row" style={{ margin: 15 }}>
                  <div className="col-md-12">
                    <h3>{this.state.mode === 'new' ? 'Tambah klasifikasi baru' : 'Sunting klasifikasi'}</h3>
                    {this.state.currentParent && this.state.currentParent.name &&
                      <div className="form-group row">
                        <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Induk</label>
                        <div className="col-md-9" >
                          <input
                            className="form-control"
                            name="parentName"
                            placeholder="Induk"
                            value={this.state.currentParent.name}
                            disabled
                          />
                        </div>
                      </div>
                    }
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama klasifikasi</label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="name"
                          placeholder="Nama klasifikasi"
                          value={this.state.name}
                          onChange={(e) => { this.setState({ name: e.target.value }) }}
                        />
                        <small id="nameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    {this.state.loading ?
                      <Spinner /> :
                      <div style={{ float: 'right' }}>
                        <button className="btn btn-default" onClick={this.back}>Batal</button>
                        <button className="btn btn-success" onClick={this.state.mode === 'new' ? this.create : () => { this.update(null, null) }}>Simpan</button>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
