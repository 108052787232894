import PropTypes from 'prop-types';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import selector from './RemoteListSelector.js'
import RemoteUser from './RemoteUser'
import Local from './Local'

class Remotes extends Component {

  render() {
    const { feeds } = this.props
    const size = feeds.length + 1;
    return (
        <div className="feeds-container">
        <Local size={size}/>
        { feeds.map((feed, key) => <RemoteUser size={size} key={key} feed={feed} />) }
        { size <= 1 ? (
          <span className="status-bar">Sedang mencoba terhubung ke ruang rapat...
            </span>
        ) : (<span/>)}
        </div>
    )
  }
}

Remotes.propTypes = {
  feeds: PropTypes.array
}

export default connect(selector)(Remotes)