import React, { Component } from 'react';
import swal from 'sweetalert';
import * as Fa from 'react-icons/lib/fa';
import Config from '../../../config';
import Spinner from '../../../components/Spinner';
import User from '../../../services/User';
import Auth from '../../../services/Auth';
import Misc from '../../../services/Misc';

const UserService = new User();
const AuthService = new Auth();
const MiscService = new Misc();
// eslint-disable-next-line
const emailAddressRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export class UserEditComponent extends Component {
  state = {
    username: '',
    loading: false,
    role: '',
    name: '',
    nip: '',
    pangkat: '',
    emailAddress: '',
    phoneNumber: '',
  }

  componentDidMount = () => {
    window.store.dispatch(window.actions.showPageTitle(true));
    if (AuthService.getRoles().role !== 'ADMIN') {
      window.browserHistory.push('/');
      return;
    }
    this.setState({ loading: true }, () => {
      if (this.props.uuid === 'new') {
        this.setState({ loading: false, role: 'USER' });
        return;
      }
      UserService.get(this.props.uuid)
        .then((result) => {
          this.setState(result);
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    });
  }

  back = () => {
    window.browserHistory.push('/settings/users');
  }

  create = () => {
    if ((!this.state.username || (this.state.username ** this.state.username.length < 1)) ||
      (!this.state.password || (this.state.password ** this.state.password.length < 1))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon lengkapi isian yang diwajibkan.',
      });
      return;
    }
    if (!emailAddressRe.test(this.state.emailAddress)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email tidak valid.',
      });
      return;
    }
    if (this.state.password !== this.state.passwordRepeat) {
      swal({
        title: '',
        type: 'warning',
        text: 'Pastikan konfirmasi sandi sama.',
      });
      return;
    }
    if (this.state.pin !== this.state.pinRepeat) {
      swal({
        title: '',
        type: 'warning',
        text: 'Pastikan konfirmasi PIN sama.',
      });
      return;
    }
    if ((!this.state.name || (this.state.name && this.state.name.length < 1)) ||
    (!this.state.emailAddress || (this.state.emailAddress && this.state.emailAddress.length < 1)) || (!this.state.phoneNumber ||
    (this.state.phoneNumber && this.state.phoneNumber.length < 1))) {
      swal({
        title: '',
        type: 'warning',
        text: 'Mohon dilengkapi isian yang diwajibkan.',
      });
      return
    }
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menambah pengguna ' + this.state.username + ' ?',
    }, (ok) => {
      if (ok) {
        this.setState({ loading: true }, () => {
          let payload = {
            username: this.state.username,
            password: this.state.password,
            pin: this.state.pin,
            name: this.state.name,
            role: this.state.role,
            nip: this.state.nip,
            pangkat: this.state.pangkat,
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.phoneNumber,
          }
          UserService.create(payload)
            .then((result) => {
              this.setState({ loading: false });
              setTimeout(()=>swal({
                title: '',
                text: 'Data pengguna berhasil diperbarui.',
              }), 100)
              this.back();
            })
            .catch((err) => {
              this.setState({ loading: false });
              if (err && err.response && err.response.status && err.response.status === 409) {
                setTimeout(()=>swal({
                  title: '',
                  type: 'warning',
                  text: 'Gagal mendaftarkan pengguna baru: pengguna dengan nama pengguna atau email tersebut sudah ada.',
                }), 100)
              }
            });
        });
      }
    })
  }

  update = () => {
    if (!emailAddressRe.test(this.state.emailAddress)) {
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email tidak valid.',
      });
      return;
    }
    this.setState({ loading: true }, () => {
      let payload = {
        role: this.state.role,
        name: this.state.name,
        nip: this.state.nip,
        pangkat: this.state.pangkat,
        emailAddress: this.state.emailAddress,
        phoneNumber: this.state.phoneNumber,
      }
      UserService.update(this.props.uuid, payload)
        .then((result) => {
          this.setState({ loading: false });
          swal({
            title: '',
            text: 'Data pengguna berhasil diperbarui.',
          });
          this.back();
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  }

  delete = () => {
    swal({
      title: '',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      text: 'Anda yakin ingin menghapus pengguna ini?',
    }, (ok) => {
      if (ok) {
        setTimeout(() => {
          this.setState({ loading: true }, () => {
            UserService.delete(this.props.uuid)
              .then((result) => {
                this.setState({ loading: false });
                swal({
                  title: '',
                  type: 'warning',
                  text: 'Pengguna berhasil dihapus',
                });
                this.back();
              })
              .catch((err) => {
                if (err.response && parseInt(err.response.status, 10) === 409) {
                  swal({
                    title: '',
                    type: 'warning',
                    text: 'Pengguna ini tidak dapat dihapus. Tetapkan pengguna lain pada jabatannya atau hapus struktur jabatannya pada organisasi terlebih dahulu.',
                  });
                }
                this.setState({
                  user: {},
                  loading: false,
                });
              });
          });
        }, 500)
      }
    });
  }

  impersonate = (id) => {
    this.setState({loading: true}, () => {
      UserService.impersonate(id)
      .then((result) => {
        this.setState({loading: false})
        localStorage.setItem('impersonator_origin_token', localStorage.getItem('access_token'))
        localStorage.setItem('access_token', result.impersonateToken)
        localStorage.setItem('impersonator_user', localStorage.getItem('current_user'))
        if (result.body.split(" ")[0].length === 36) {
          localStorage.setItem('impersonator_token_id', result.body.split(" ")[0])
        }
        localStorage.setItem('impersonate_id', result.body.split(" ")[1])
        MiscService.getUser(localStorage.getItem('impersonate_id'))
        .then((currentUser) => {
          this.setState({ loading: false });
          window.store.dispatch(window.actions.loggedIn({ accessToken: localStorage.getItem('access_token'), currentUser: currentUser }));
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
        window.browserHistory.push('/');
      })
      .catch((err) => {
        this.setState({loading: false})
      })
    })
  }

  resetPINUser = () => {
    if (!emailAddressRe.test(this.state.emailAddress) && !this.state.phoneNumber) {
      swal({
        title: '',
        type: 'warning',
        text: 'Alamat email dan nomor telepon tidak valid.',
      });
      return;
    }
    this.setState({ loading: true }, () => {
      AuthService.requestPINReset(this.props.uuid)
      .then((result) => {
        swal({
          title: '',
          type: 'warning',
          text: 'Silahkan periksa email pengguna',
        });
        this.setState({ loading: false})
      })
      .catch((err) => {
        this.setState({ loading: false})
      })
    })
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div>
            <h4>{this.props.uuid === 'new' ? 'Tambah pengguna baru' : 'Sunting pengguna'}</h4>
            <div className="box box-success">
              <div className="box-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row" style={{ textAlign: 'center', margin: 30 }}>
                      <img src={Config.apiHost + '/api/v1/blob/unrestricted/' + this.state.picture} alt="" style={{ background: 'grey', width: 100, height: 100, borderRadius: 50, margin: '0 auto' }} onError={(e) => { e.target.src = "/images/default-avatar.png" }} />
                    </div>
                    <div className="row" style={{ textAlign: 'center', margin: 30 }}>
                      <div style={{ margin: '0 auto' }}>
                        <h4>
                          {this.state.name}<br />
                          <small>{this.state.username}</small>
                        </h4>
                        {this.state.organization && this.state.organization.name && <span><Fa.FaBriefcase /> {this.state.organization && this.state.organization.name}</span>}<br />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama pengguna&nbsp;<span style={{color:'red'}}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="username"
                          placeholder="Nama pengguna"
                          value={this.state.username}
                          onChange={(e) => { this.setState({ username: e.target.value }) }}
                          disabled={this.loading || this.props.uuid !== 'new'}
                          aria-describedby="usernameHelp"
                        />
                        <small id="usernameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nama lengkap&nbsp;<span style={{color:'red'}}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="name"
                          placeholder="Nama lengkap"
                          value={this.state.name}
                          onChange={(e) => { this.setState({ name: e.target.value }) }}
                          disabled={this.loading}
                        />
                        <small id="nameHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Nomor telepon/telepon selular&nbsp;<span style={{color:'red'}}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="phoneNumber"
                          placeholder="Nomor telepon / telepon selular"
                          value={this.state.phoneNumber}
                          onChange={(e) => { this.setState({ phoneNumber: e.target.value }) }}
                          disabled={this.loading}
                        />
                        <small id="phoneNumberHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Alamat Email&nbsp;<span style={{color:'red'}}>*</span></label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="email_address"
                          placeholder="Alamat Email"
                          value={this.state.emailAddress}
                          onChange={(e) => { this.setState({ emailAddress: e.target.value.trim() }) }}
                          disabled={this.loading}
                        />
                        <small id="emailAddressHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">NIP</label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="nip"
                          placeholder="NIP"
                          value={this.state.nip}
                          onChange={(e) => { this.setState({ nip: e.target.value }) }}
                          disabled={this.loading}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Pangkat</label>
                      <div className="col-md-9" >
                        <input
                          className="form-control"
                          name="pangkat"
                          placeholder="Pangkat"
                          value={this.state.pangkat}
                          onChange={(e) => { this.setState({ pangkat: e.target.value }) }}
                          disabled={this.loading}
                        />
                      </div>
                    </div>
                    {this.props.uuid === 'new' &&
                      <div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Sandi&nbsp;<span style={{color:'red'}}>*</span></label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              aria-describedby="passwordHelp"
                              placeholder="Sandi"
                              value={this.state.password}
                              onChange={(e) => { this.setState({ password: e.target.value }) }}
                              disabled={this.loading}
                            />
                            <small id="passwordHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Konfirmasi sandi&nbsp;<span style={{color:'red'}}>*</span></label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              type="password"
                              name="passwordRepeat"
                              aria-describedby="passwordRepeatHelp"
                              placeholder="Konfirmasi sandi"
                              value={this.state.passwordRepeat}
                              onChange={(e) => { this.setState({ passwordRepeat: e.target.value }) }}
                              disabled={this.loading}
                            />
                            <small id="passwordRepeatHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                      </div>
                    }
                    {this.props.uuid === 'new' &&
                      <div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">PIN&nbsp;<span style={{color:'red'}}>*</span></label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              type="pin"
                              name="pin"
                              aria-describedby="pinHelp"
                              placeholder="PIN"
                              value={this.state.pin}
                              onChange={(e) => { this.setState({ pin: e.target.value }) }}
                              disabled={this.loading}
                            />
                            <small id="pinHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Konfirmasi PIN&nbsp;<span style={{color:'red'}}>*</span></label>
                          <div className="col-md-9" >
                            <input
                              className="form-control"
                              type="pin"
                              name="pinRepeat"
                              aria-describedby="pinRepeatHelp"
                              placeholder="Konfirmasi PIN"
                              value={this.state.pinRepeat}
                              onChange={(e) => { this.setState({ pinRepeat: e.target.value }) }}
                              disabled={this.loading}
                            />
                            <small id="pinRepeatHelp" className="form-text text-muted">Harap diisi</small>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="form-group row">
                      <label style={{ textAlign: 'right' }} className="col-md-3 col-sm-3">Role</label>
                      <div className="col-md-9" >
                        <select
                          className="form-control"
                          name="role"
                          value={this.state.role}
                          onChange={(e) => { this.setState({ role: e.target.value }) }}
                          disabled={this.loading || window.store.getState().rootReducers.currentUser.username === this.state.username}
                        >
                          <option value="USER">User</option>
                          <option value="ADMIN">Admin</option>
                          <option value="FINALIZER">Finalizer</option>
                          <option value="FRONTOFFICE">FrontOffice</option>
                        </select>
                        <small id="roleHelp" className="form-text text-muted">Harap diisi</small>
                      </div>
                    </div>
                    {this.state.loading && <Spinner style={{float:'right'}} />}
                    {!this.state.loading &&
                      <div style={{ float: 'right' }}>
                        <span><button className="btn btn-warning" onClick={this.resetPINUser}>Reset PIN</button>&nbsp;&nbsp;</span>
                        {Config.impersonationEnabled === true && localStorage.getItem('impersonator_token_id') === null && window.store.getState().rootReducers.currentUser.username !== this.state.username && this.state.id && 
                          <span><button className="btn btn-warning" onClick={() => this.impersonate(this.props.uuid)}>Menyamar</button>&nbsp;&nbsp;</span>}
                        <button className="btn btn-default" onClick={this.back}>Kembali</button>&nbsp;&nbsp;
                        {window.store.getState().rootReducers.currentUser.username !== this.state.username && this.state.id && <span><button className="btn btn-danger" onClick={this.delete}>Hapus</button>&nbsp;&nbsp;</span>}
                        {this.props.uuid === 'new' ?
                          <span><button className="btn btn-primary" onClick={this.create}>Simpan</button>&nbsp;</span>
                          :
                          <span><button className="btn btn-primary" onClick={this.update}>Simpan</button>&nbsp;</span>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const UserComponent = ({ match }) => (
  <UserEditComponent uuid={match.params.uuid} />
)

export default UserComponent;
