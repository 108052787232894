import PropTypes from 'prop-types';

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Janus } from 'react-janus'
import { connect } from 'react-redux'


import selector from './LocalSelector'
import Mic from './Mic'
import Cam from './Cam'

class Local extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cam: true
    }
  }

  componentDidUpdate(prevProps) {
    const { feed } = this.props
    if(feed && feed.stream) {
      const video = ReactDOM.findDOMNode(this).querySelector('video')
      Janus.attachMediaStream(video, feed.stream)
      video.muted = 'muted'
    }
  }

  updateCam(cam) {
    this.setState({
      cam
    })
  }

  render() {
    const { feed, videoRoomLocal, size } = this.props
    let stream = null
    if(feed && feed.stream) {
      stream = feed.stream
    }
    const style = "rapat-stream-box max" + size
    return (
      <div className={style}>
        <Mic
          stream={stream}
          videoRoomLocal={videoRoomLocal} />
        <div className="video-wrapper">
          { stream ? (
            <video autoPlay="true" muted="muted"></video>
          ) : null }
          
          </div>
        <Cam
          stream={stream}
          videoRoomLocal={videoRoomLocal}
          onToggle={this.updateCam.bind(this)} />
      </div>
    )
  }
}
Local.propTypes = {
  videoRoomLocal: PropTypes.object,
  feed: PropTypes.object
}

export default connect(selector)(Local)